import React from 'react'
import Fade from 'react-reveal/Fade';


import pattern4 from '../assets/images/pattern/thm-pattern-4.png'

const HomeCounterTime = () => {
    return (
        <>
            <section className="">

                <div className="container">

                    <div className="sec-title center text-center">

                        <Fade bottom>
                            <h2>OUR NETWORK</h2>
                        </Fade>
                    </div>

                    <div id="projectFacts" class="sectioncounter">
                        <div class="fullWidth eight columns">
                            <div class="row projectFactsWrap counter-grid ">
                                <div className='counter-cards'>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew  col-counternew" data-number="12">

                                        <p id="number1" class="number">300 +</p>
                                        <span></span>
                                        <p>Fuel Stations</p>
                                    </div>
                                </div>
                                <div className='counter-cards '>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew   col-counternew" data-number="55">
                                        <p id="number2" class="number">10 +</p>
                                        <span></span>
                                        <p>Agri Factories</p>
                                    </div>
                                </div>
                                <div className='counter-cards'>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew   col-counternew" data-number="359" >
                                        <p id="number3" class="number">900 +</p>
                                        <span></span>
                                        <p>Fleet</p>
                                    </div>
                                </div>
                                <div className='counter-cards '>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew  col-counternew" data-number="246" >
                                        <p id="number4" class="number">6000 +</p>
                                        <span> </span>
                                        <p>Employees</p>
                                    </div>
                                </div>
                                <div className='counter-cards '>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew  col-counternew" data-number="246" >
                                        <p id="number4" class="number">17</p>
                                        <span> </span>
                                        <p>No of Countries</p>
                                    </div>
                                </div>
                                <div className='counter-cards '>

                                    <div class="item wow fadeInUpBig animated animated visble-countnew  col-counternew" data-number="246" >
                                        <p id="number4" class="number">1 Million +</p>
                                        <span> </span>
                                        <p>No of Farmers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default HomeCounterTime