import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory45 from '../assets/images/shop/new-pic-18.png'
import Aboutstory46 from '../assets/images/shop/new-pic-16.jpg'

import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const AcerInfrastructure = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>White Horse Commodities FZE, UAE</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li>
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Energy</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">
                                            White Horse Commodities FZE, UAE</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                White Horse Commodities FZE, UAE is the group's backbone towards engineering and construction projects performed within the group. The division is responsible to cater highly sustainable projects across the group. White Horse Commodities FZE, UAE consists of highly skilled Civil Engineers and Architects who manage the Fuel Station, Office Building, Factory commissioning and other commercial projects. The division also caters to third-party commercial projects delivering futuristic designed, safe and aesthetic commercial spaces in Africa.                                            </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory45} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory46} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Why Choose Us</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                The division also caters to third-party commercial projects delivering futuristic designed, safe and aesthetic commercial spaces in Africa.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>

                {/* <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">Presence In </span>
                                        <span class="two-text">  </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="country-grid">
                            <div class="country-presence row d-flex justify-content-center">
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="country-images">
                                        <p>NAMIBIA</p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            <Footer />
        </>
    );
}

export default AcerInfrastructure;
