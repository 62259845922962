import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory from '../assets/images/about/about-agri.jpg'
import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const AboutAgri = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Agri</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Agri</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6">
                                <div className="about-style2__content">




                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Mount Meru Millers Ltd considers itself very fortunate to be a part of the Agro based entities in Africa. We recognize the immense potential of farmers as catalysts for national development. With pride, we are associated with over 1 million farmers across Africa who form the backbone of our Edible Oil Manufacturing units.                                            </p>
                                                <p className='mt-3'>
                                                    At Mount Meru, we prioritize the well-being and prosperity of farmers, forging strong partnerships built on trust and mutual growth. Through our extensive network, we provide essential resources, knowledge, and support to empower farmers in their journey towards success.Farmers are one of the most powerful sources towards the development of every nation.
                                                </p>
                                                <p>
                                                    With currently 8 manufacturing & processing plants across Africa, the incorporation of Mount Meru Millers was done with an ideology to support the entire eco–system by making a positive impact on the lives & the livelihoods of the farmers & the supporters in the Argo chain.                                            </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12">
                                <div className="about-style2__content">




                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text mt-3">

                                                <p>
                                                    The company has also joined with several farmers on various schemes such as Out growers’ scheme, Effective Farming aid, techniques training, good quality yield output training, etc., with an assurance of supporting the farmers from sowing the seeds to purchasing the seeds throughout the year.
                                                </p>
                                                <p>
                                                    The factories have also directly impacted & contributed to the development of the regions by providing more job opportunities, transport avenues & infrastructure development positively.
                                                </p>
                                                <p>
                                                    Mount Meru Millers is currently also actively promoting the Education sector by its very unique “Feed the Child” Program, which caters to healthy and nutritional meals to all the students of Government and Non-Profit established Schools daily. More than 100,000 students across Africa are now a part of “Feed the Child”
                                                    Mount Meru Millers respects Mother Nature & hence, all plants are specifically designed to help an Eco-Friendly environment. We have also implemented Water Harvesting & ETP (Effluent Treatment Plant) in our factories.
                                                </p>
                                                <p>
                                                    The factories have also directly impacted & contributed into the development of the regions by providing more job opportunities, transport avenues & infrastructure development positively


                                                </p>
                                                <p>
                                                    Mount Meru Millers is currently also actively promoting the Education sector by its very unique “Feed the Child” Program which caters to healthy and nutritional meals to all the students of Government and Non-Profit established Schools on a daily basis. More than 100,000 students across Africa are now a part of “Feed the Child”
                                                </p>
                                                <p>
                                                    Mount Meru Millers respects the Mother Nature & hence all of the plants are specifically designed to help an Eco-Friendly environment. We have also implemented Water Harvesting & ETP (Effluent Treatment Plant) in all of our factories.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2 className='header-flextion-h2'><span class="border-left"></span>
                                        <div className='header-flextion '>
                                            <span class="one-text">MMG 2030 Agri </span>
                                            <span class="two-text"> Business Vision</span>
                                        </div>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="station-card-list">

                            <div class="">

                                <div class="single-choose-box mar-b-30">
                                    <div class="inner ">
                                        <div class="icon iconnew"><span><i class="fa fa-globe" aria-hidden="true"></i></span></div>
                                        {/* <div class="counting-box">01</div> */}
                                        <div class="text">
                                            <p>
                                                Presence across 15 major ports in Africa
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-link" aria-hidden="true"></i></span></div>
                                        <div class="text">
                                            <p>
                                                Fully integrated Agri value chain and FMCG brand
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-lightbulb-o"></i></span></div>
                                        <div class="text">
                                            <p>
                                                Fully captive logistics and storage solution
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-group"></i></span></div>
                                        <div class="text">
                                            <p>
                                                Create Positive Impact on farmers & Community around                                     </p>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>


                <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2 className='header-flextion-h2'><span class="border-left"></span>
                                        <div className='header-flextion '>
                                            <span class="one-text">MMG Agri   </span>
                                            <span class="two-text"> Value Chain</span>
                                        </div>

                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="row justify-content-center">

                            <div class="col-xl-4 col-lg-6 col-md-6">

                                <div class="single-choose-box mar-b-30">
                                    <div class="inner ">
                                        <div class="icon iconnew"><span><i class="fa-solid fa-wheat-awn"></i></span></div>

                                        <div class="text">
                                            <h3>Procurement</h3>
                                            <p>
                                                Direct sourcing of seeds from Farmers, Own Logistics for bringing crude from Beira and Mombasa and Own Storage for seeds, oil and meal.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-solid fa-box-open"></i></span></div>
                                        <div class="text">
                                            <h3>Processing</h3>
                                            <p>
                                                Crushing & Solvent Extract, Refining & Fractionation , Soap manufacturing and Packaging.

                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-sharp fa-solid fa-database"></i></span></div>
                                        <div class="text">
                                            <h3>Storage & Hospitality</h3>
                                            <p>
                                                Meru Brands,
                                                Own Depots,
                                                Own Logistics.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>

                    </div>
                </section>

            </div>

            <Footer />
        </>
    );
}

export default AboutAgri;
