import React from 'react'
import Header from './Header';
import Footer from './Footer';
import BlogSidebar from './BlogSidebar';
import Blog55 from '../assets/images/blog/blog-55.jpg';
import Blog56 from '../assets/images/blog/blog-56.jpg';
import Blog57 from '../assets/images/blog/blog-57.jpg';
import EcoFriendlyBanner from './EcoFriendlyBanner';
const MountMeruEdibleOil = () => {
    return (
        <>
            <Header />

            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >

                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>US$20 million Mount Meru Edible Oil plant commissioned</h2>


                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="index-2.html">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="index-2.html">Our Blogs</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">US$20 million Mount Meru Edible Oil plant commissioned</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">
                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="row text-right-rtl">
                            <div class="col-xl-8 col-lg-7">
                                <div class="blog-details-content height-auto-blog">

                                    <div class="single-blog-style1  single-blog-style1--blog-large blog-details wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms" >
                                        <div class="img-holder">
                                            <div class="inner">
                                                <img srcSet={Blog55} alt="Blogdet1" className='imgnews-blog' />
                                            </div>
                                            <div class="categories-date-box">
                                                <div class="categories-box">
                                                    <h6><span class="flaticon-open-archive"></span> Sept 2021 - Press Release</h6>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="text-holder">
                                            <div class="top-text">

                                                <div class="inner-text">
                                                    <p>
                                                        ZIMBABWE’s friendly investment climate continues to attract fresh capital, with the latest addition being the US$20 million Mount Meru edible plant commissioned in Seke recently.</p>


                                                    <p>The commissioning of the plant with a capacity of processing 250 tonnes of soya edible oil daily is a direct result of the Ministry Industry and Commerce’s tenacity in selling the country’s vision.
                                                    </p>
                                                </div>

                                                <div class="inner">
                                                    <img srcSet={Blog56} alt="Blogdet1" />
                                                </div>


                                                <div class="inner mt-5 mb-5">
                                                    <p>The refinery plant and the packaging complex are feeding directly into the objectives of the National Development Strategy 1 which is prioritising production and productivity, with the plant set to initially have more than 100 permanent employees while creating massive downstream and upstream economic linkages to the betterment of the economy.

                                                    </p>
                                                    <p>
                                                        The Ministry of Industry and Commerce has pledged to strengthen the country’s industrialisation drive through creating absolute value chains between the agriculture and manufacturing sector in line with vision 2030. </p>
                                                    <p>“As the Ministry responsible for the re Industrialisation of the country we are determined to make sure that we harness every resource available to spearhead this mission and the commissioning of this Mount Meru plant is a welcome development to everyone conscious about significance of investment,” said Never Katiyo, Director Legal Services Ministry of Industry and Commerce</p>

                                                </div>
                                                <div class="inner">
                                                    <img srcSet={Blog57} alt="Blogdet1" />
                                                </div>


                                                <div class="inner mt-5">

                                                    <p>
                                                        The company is in the process of capacitating local farmers through outgrower schemes to increase raw material availability which will ultimately reduce the country’s import bill on soya bean while enhancing agricultural productivity.</p>

                                                    <p>“Besides this commissioned plant we are now in the process of expanding the already existing plant to include a crushing plant which will reduce the cost of the final product drastically,” said Satyavrat Katti, an Investor.</p>

                                                    <p>With such investments, economic observers are optimistic that Zimbabwe is well on course to achieve its intended economic targets.</p>

                                                    <p>The post US$20 million Mount Meru Edible Oil plant commissioned appeared first on ZBC NEWS (by Davison Vandira).                                                </p>



                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>

                            <BlogSidebar />


                        </div>

                    </div>
                </section>
            </div>

            <Footer />

        </>
    )
}

export default MountMeruEdibleOil