
import Header from "./Header";
import Footer from "./Footer";

import ReactPlayer from 'react-player'
import HomeSlider from "./HomeSlider";
import HomeService from "./HomeService"
import HomeAbout from "./HomeAbout";
import CsrNew from "./CsrNew";
import HomeCounterTime from "./HomeCounterTime";
// import HomeTeam from "./HomeTeam";
import HomeTestimonials from "./HomeTestimonials";
import Impact from "./Impact";
import HomeBlog from "./HomeBlog";
import CoreValues from "./CoreValues";
import HomeStation from "./HomeStation";
import FeedTheChild from "./FeedTheChild";
import pattern4 from '../assets/images/pattern/thm-pattern-4.png'
import Fade from 'react-reveal/Fade';

const Home = () => {
    return (
        <>

            <Header />

            <HomeSlider />
            <div className="section-parent">
                {/* <HomeService /> */}
                <CsrNew />
                <HomeAbout />
                {/* <section className="team-style1-area video-section">
                    <div className="pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                    <div className="container">
                        <div className="sec-title">
                            <Fade bottom>
                                <div className="sub-title">
                                </div>
                                <h2>Our Glorious journey</h2>
                            </Fade>
                        </div>

                        <div id="projectFacts" class="sectioncounter">
                            <div class="">
                                <div class="row  youtube-videos ">
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                            className='react-player'
                                            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                                            width="100%"
                                            playing={true}
                                            loop={true}
                                            controls={false}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}

                <CoreValues />
                {/* <HomeStation/> */}
                {/* <Impact /> */}
                <HomeCounterTime />
                <HomeTestimonials />
                <HomeBlog />

            </div>
            <Footer />
        </>
    );
}

export default Home;
