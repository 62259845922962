import React from 'react'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import FindDescription from './FindDescription';

const bgforms = {
    backgroundImage: "url(images/background/bg-5.png);"
}
const CareerJobDescription = () => {
    const [isJobTypeActive, setIsJobTypeActive] = useState(false);
    const [isJobCategoryActive, setIsJobCategoryActive] = useState(false);
    const [isCountryRegionActive, setIsCountryRegionActive] = useState(false);
    const [isStateProvinceActive, setIsStateProvinceActive] = useState(false);
    const [isTeamActive, setIsTeamActive] = useState(false);
    const [isRoleTypeActive, setIsRoleTypeActive] = useState(false);
    const [isCategoryActive, setIsCategoryActive] = useState(false);

    const toggleJobTypeFilter = () => {
        setIsJobTypeActive((prev) => !prev);
    };

    const toggleJobCategoryFilter = () => {
        setIsJobCategoryActive((prev) => !prev);
    };

    const toggleCountryRegionFilter = () => {
        setIsCountryRegionActive((prev) => !prev);
    };

    const toggleStateProvinceFilter = () => {
        setIsStateProvinceActive((prev) => !prev);
    };

    const toggleTeamFilter = () => {
        setIsTeamActive((prev) => !prev);
    };

    const toggleRoleTypeFilter = () => {
        setIsRoleTypeActive((prev) => !prev);
    };

    const toggleCategoryFilter = () => {
        setIsCategoryActive((prev) => !prev);
    };
    return (
        <>
  <div class="section-full overviewservleft bg-grays mobile-page-padding p-t80 p-b50 bg-repeat overflow-hide padding100" style={bgforms}>

<div class="container">
    <div class="row">
        <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-sm-12">
            <div className="filterby-section">
                <div className="filter-by">
                    <p>Filter by</p>
                </div>
            </div>
            <div className="filterbycard">

                <div className={isJobTypeActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleJobTypeFilter}>
                    <p>Job Type</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">
                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Full Time
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description">
                        <div className="job-count-text">
                            <p className="job-time">Seasonal
                                <span className='job-count'>(3)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                </div>
            </div>
            <div className="filterbycard">
                <div className={isJobCategoryActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleJobCategoryFilter}>
                    <p>JOB CATEGORY</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">

                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Sales, Advertising, & Account Management
                                <span className='job-count'>(13)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Amazon Design
                                <span className='job-count'>(2)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Amazon Design
                                <span className='job-count'>(2)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Marketing
                                <span className='job-count'>(2)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description border-jobs">
                        <div className="job-count-text">
                            <p className="job-time">Business Intelligence
                                <span className='job-count'>(1)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                    <div className="jobtype-description">
                        <div className="job-count-text">
                            <p className="job-time">Project/Program/Product Management--Non-Tech<span className='job-count'>(1)</span></p>

                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>
                </div>
            </div>
            <div className="filterbycard">
                <div className={isCountryRegionActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleCountryRegionFilter}>
                    <p>Country/Region</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">
                    <div className="jobtype-description jobtype-description-relative">
                        <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                        <span>
                            <input type="search" class="form-control" name="searchtext" id="" placeholder="Country/Region" />
                        </span>
                    </div>
                    <div className="jobtype-description mt-3">

                        <div className="job-count-text">
                            <p className="job-time">India
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>

                </div>
            </div>
            <div className="filterbycard">
                <div className={isStateProvinceActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleStateProvinceFilter}>
                    <p>State/Province</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">
                    <div className="jobtype-description jobtype-description-relative">
                        <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                        <span>
                            <input type="search" class="form-control" name="searchtext" id="" placeholder="State/Province" />
                        </span>
                    </div>
                    <div className="jobtype-description mt-3">

                        <div className="job-count-text">
                            <p className="job-time">Karnataka
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>

                </div>
            </div>
            <div className="filterbycard">
                <div className={isTeamActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleTeamFilter}>
                    <p>Team</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">

                    <div className="jobtype-description mt-3">

                        <div className="job-count-text">
                            <p className="job-time">Amazon Retail
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>

                </div>
            </div>
            <div className="filterbycard">
                <div className={isRoleTypeActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleRoleTypeFilter}>
                    <p>ROLE TYPE</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">

                    <div className="jobtype-description mt-3">

                        <div className="job-count-text">
                            <p className="job-time">Individual Contributor
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>

                </div>
            </div>
            <div className="filterbycard">
                <div className={isCategoryActive ? 'open-job-filter job-type' : 'job-type'} onClick={toggleCategoryFilter}>
                    <p>CATEGORY</p>
                    <span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="main-jobdescrip">

                    <div className="jobtype-description mt-3">

                        <div className="job-count-text">
                            <p className="job-time">Corporate
                                <span className='job-count'>(19)</span></p>
                        </div>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>

                </div>
            </div>
        </div>
        <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-sm-12">
            <div className="sortby-div">
                <label for="cars">Sort by:</label>
                <select name="cars" id="cars" className='form-control'>
                    <option value="Most Relevant">Most Relevant</option>
                    <option value="Most Recent">Most Recent</option>

                </select>
                
            </div>

            <NavLink to="/FindDescription">
                <div className="mainfindjobs">
                    <div className="card-findjobs">
                        <div className="h3-cards">
                            <h4>Sr.Associate Site Merchandiser, Amazon Private Brands</h4>
                            <p class="location-and-id">IND, KA, Bengaluru | Job ID: 2375729</p>
                        </div>
                        <div class="info-details-card">
                            <p class="posting-date">Posted May 23, 2023</p>
                            <p class="time-elapsed">(Updated 2 days ago)</p>
                        </div>

                    </div>
                    <div className="details-jobs">
                        <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want</p>
                    </div>
                </div>
            </NavLink>
            <NavLink to="/FindDescription">

                <div className="mainfindjobs">
                    <div className="card-findjobs">
                        <div className="h3-cards">
                            <h4>Sales Associate -  Indore</h4>
                            <p class="location-and-id">IND, KA, Bengaluru | Job ID: 2375729</p>
                        </div>
                        <div class="info-details-card">
                            <p class="posting-date">Posted May 23, 2023</p>
                            <p class="time-elapsed">(Updated 2 days ago)</p>
                        </div>

                    </div>
                    <div className="details-jobs">
                        <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want</p>
                    </div>
                </div>
            </NavLink>
            <NavLink to="/FindDescription">

                <div className="mainfindjobs">
                    <div className="card-findjobs">
                        <div className="h3-cards">
                            <h4>Sales Associate -  Chennai</h4>
                            <p class="location-and-id">IND, KA, Bengaluru | Job ID: 2375729</p>
                        </div>
                        <div class="info-details-card">
                            <p class="posting-date">Posted May 23, 2023</p>
                            <p class="time-elapsed">(Updated 2 days ago)</p>
                        </div>

                    </div>
                    <div className="details-jobs">
                        <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want</p>
                    </div>
                </div>
            </NavLink>
            <NavLink to="/FindDescription">

                <div className="mainfindjobs">
                    <div className="card-findjobs">
                        <div className="h3-cards">
                            <h4>Sales Associate -  Guwahati</h4>
                            <p class="location-and-id">IND, KA, Bengaluru | Job ID: 2375729</p>
                        </div>
                        <div class="info-details-card">
                            <p class="posting-date">Posted May 23, 2023</p>
                            <p class="time-elapsed">(Updated 2 days ago)</p>
                        </div>

                    </div>
                    <div className="details-jobs">
                        <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want</p>
                    </div>
                </div>
            </NavLink>
            <NavLink to="/FindDescription">
                <div className="mainfindjobs">
                    <div className="card-findjobs">
                        <div className="h3-cards">
                            <h4>Sales Associate -  Orissa</h4>
                            <p class="location-and-id">IND, KA, Bengaluru | Job ID: 2375729</p>
                        </div>
                        <div class="info-details-card">
                            <p class="posting-date">Posted May 23, 2023</p>
                            <p class="time-elapsed">(Updated 2 days ago)</p>
                        </div>

                    </div>
                    <div className="details-jobs">
                        <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want</p>
                    </div>
                </div>
            </NavLink>

        </div>
    </div>
</div>
</div>    
    </>
    )
}

export default CareerJobDescription