import Logo from '../assets/images/merublack.png'
import { logout } from './redux/LoginRedux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

function toggleSidebar() {
    document.body.classList.toggle('toggle-sidebar');
}

function AdminHeader() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const user = localStorage.getItem('user');
        setUser(user);
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('user');
    };

    if (user === null) {
        navigate('/AdminLogin');
    }

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                    <i class="fa-solid fa-bars toggle-sidebar-btn" onClick={toggleSidebar}></i>
                    <a href="#" className="logo d-flex align-items-center">
                        <img src={Logo} alt="" />
                    </a>
                </div>
                {
                    user !== null ? <button className="btn btn-primary" onClick={handleLogout}>Logout</button> : null
                }
            </header>
        </>
    )
}

export default AdminHeader;