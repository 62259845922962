import React from 'react'
import Fade from 'react-reveal/Fade';

const WhatWeOfferDetails = () => {
    return (
        <>

            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <Fade bottom>
                                <h2><span class="border-left"></span>
                                    <span class="one-text">What we  </span>
                                    <span class="two-text"> offer</span>
                                    <span class="border-right"></span>
                                </h2>
                            </Fade>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Career development and learning
                                                </h3>
                                                {/* <h3>
                                                    Can you add your bit to our knowledge..
                                                </h3> */}
                                            </div>
                                            <p>
                                                At Mount Meru Group, we have defined the pillars of our business. The first being you all..! We have cultivated an environment that involves a genuine intent to foster the long-term learning & development of our employees.
                                            </p>
                                            <p>

                                                You can explore wealth of knowledge through our initiative 'Rise with Meru' and continue your knowledge quest through  gamut of programs that would aid you to grow your career across geographies.
                                                </p>
                                            <p>
                                                Our programs include up-scalling the employees to suit different functionalities and support dynamic synchronization of processes and systems.
                                                </p>
                                            <p>
                                                Our employees enjoy support, career development and opportunities to grow. We strive to make sure that they get everything they need to realize their full potential and grow with us globally.



                                            </p>


                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>

                     
                    </div>
                </div>
            </section>


        </>
    )
}

export default WhatWeOfferDetails