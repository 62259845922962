import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory145 from '../assets/images/shop/miller.png'
import greenWorld from '../assets/images/about/earth-green.jpeg'
import Aboutstory146 from '../assets/images/shop/new-pic-13.jpg';
import Aboutstory147 from '../assets/images/shop/new-pic-9.jpg';
import Aboutstory148 from '../assets/images/shop/new-pic-18.png';
import Aboutstory149 from '../assets/images/shop/terminal3.png';
import Mount1 from '../assets/images/mount1.png';
import Mount2 from '../assets/images/mount2.png';
import Aboutstory22 from '../assets/images/about/about-agri.jpg';
import Aboutstory23 from '../assets/images/about/energy-aboutus.jpeg';
import Mount3 from '../assets/images/mount3.png';
import Aboutenergy1 from '../assets/images/shop/truck-6.png'

import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";
import Modal from 'react-bootstrap/Modal';
import envsteward from '../assets/images/esg-img/env-steward.jpg';
import comengage from '../assets/images/esg-img/com-engage.png';
import govethic from '../assets/images/esg-img/gov-ethics.jpg';
import innores from '../assets/images/esg-img/inno-res.jpg';
import suppluchainmgmt from '../assets/images/esg-img/supply-chain-mgmt.jpg';
import contimprov from '../assets/images/esg-img/conti-improv.png';

const EsgMenu = () => {
    const [envstew, setenvstew] = useState(false);
    const [socialres, setsocialres] = useState(false);
    const [globalwarm, setglobalwarm] = useState(false);
    const [supplychain, setsupplychain] = useState(false);
    const [comeng, setcomeng] = useState(false);
    const [gov, setgov] = useState(false);
    const [inno, setinno] = useState(false);
    const [conti, setconti] = useState(false);

    return (
        <>
            <Header />

            <section className="breadcrumb-area style2 esg-sec">
                <div className="breadcrumb-area-bg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>ESG</h2>
                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Esg</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent esg-sec">
                <section className="about-style2-area">
                    <div className="container ">
                        <div className="row ">

                            <div className="col-xl-12 ">
                                <div className="about-style2__content esg-top-txt">




                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text new-textsheading">
                                                <p>
                                                    Meru recognizes the critical importance of Environmental, Social, and Governance (ESG) principles in achieving long-term success and sustainability.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>
                {/* <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row  order-flexing">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">

                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Environmental Stewardship</span>
                                                    <span className="two-text"></span>
                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Meru places great emphasis on environmental sustainability. The company implements strategies to reduce its ecological footprint by optimizing waste management, minimizing energy consumption, and promoting the use of renewable resources.
                                                </p>
                                                <p className='mt-2'>
                                                    Meru engages into eco-friendly sources of energy by generating Solar powered energy at its fuel stations and manufacturing facilities. The group plans to actively engage into more environment friendly innovations within the organization and to its collaborators.
                                                </p>
                                                <p className='mt-2'>
                                                    By actively embracing eco-friendly practices, Meru demonstrates its commitment to mitigating the negative impact on the environment.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={envsteward} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>



                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner objectfit-img">
                                                <img srcSet={Mount1} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text">Social Responsibility </span>
                                                    <span className="two-text"></span>
                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    The company understands the significance of social responsibility and prioritizes the well-being and safety of its employees. Meru ensures fair labour practices, upholds employee rights, and fosters a diverse and inclusive work environment. By promoting a culture of respect and equality, the company nurtures a motivated workforce that drives innovation and success.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text">Our Commitment to Address Global Warming</span>
                                                    <span className="two-text"></span>
                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    At our company, we recognize that preventing global warming goes beyond reducing our own emissions; it also involves fostering sustainable practices throughout our supply chain. We work closely with our suppliers to ensure they adhere to stringent environmental standards and promote sustainable manufacturing processes. By sourcing materials responsibly and favoring suppliers who prioritize eco-friendly practices, we contribute to the larger goal of mitigating global warming. Furthermore, we prioritize the development and promotion of environmentally friendly products and services. By offering sustainable alternatives to traditional products, we empower our customers to make informed choices that align with their own environmental values. We also strive to educate and engage our employees in sustainability initiatives, encouraging them to adopt eco-conscious practices in their daily lives. Through these collective efforts, we believe that businesses have a vital role to play in addressing global warming and creating a more sustainable future for all.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={greenWorld} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={suppluchainmgmt} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">

                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Supply Chain Management</span>

                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Meru recognizes that responsible supply chain management is essential to ESG implementation. The company collaborates with partners who adhere to ethical standards, including environmental sustainability, fair trade, and human rights compliance. By selecting suppliers who share its values, Meru contributes to a responsible and sustainable value chain.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Community Engagement</span>

                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Meru actively engages with the communities in which it operates. The company recognizes the importance of supporting local stakeholders and participates in community development initiatives. Through investments in education under the name of “Avinash Scholarship Fund” and Children Education and healthcare programs under “Feed the Child initiative “, the company strives to uplift the communities it serves and promote sustainable livelihoods.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={comengage} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>






                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">


                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={govethic} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Governance and Ethics </span>

                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Strong corporate governance and ethical practices are at the core of Meru's operations. The company maintains transparency, accountability, and integrity in its decision-making processes. By upholding high ethical standards and adhering to recognized governance practices, Meru instils trust and confidence in its stakeholders.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Innovation and Research  </span>

                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    The company recognizes that innovation is vital for sustainable growth. Meru invests in research and development to develop environmentally friendly products and services. By embracing innovation, the company strives to meet evolving market demands while minimizing environmental impact and contributing to a more sustainable future.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={innores} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>



                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container  massage-card-row">
                        <div className="row ">


                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory149} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div className="sec-title text-center">
                                        <div className="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span className="border-left"></span>
                                                    <span className="one-text text-left">Continuous Improvement</span>
                                                    <span className="two-text">   </span>
                                                    <span className="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Meru views ESG implementation as an ongoing journey. The company continuously assesses its ESG performance, sets ambitious goals, and works towards enhancing its practices. By fostering a culture of continuous improvement, Meru remains adaptable and responsive to emerging environmental and social challenges.
                                                </p>
                                                <p className='mt-2'>

                                                    Meru exemplifies a strong commitment to implementing ESG principles in its businesses. Through environmental stewardship, social responsibility, strong governance, and continuous improvement.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </section> */}
                <section className="about-style2-area esg-section-new">
                    <div className="container">
                        <div className='esg-contain'>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={envsteward} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Environmental Stewardship</h4>
                                            <p>Meru places great emphasis on environmental sustainability.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setenvstew(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={Mount1} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Social Responsibility</h4>
                                            <p> The company understands the significance of social responsibility and prioritizes the well-being and safety of its employees.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setsocialres(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={greenWorld} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Our Commitment to Address Global Warming</h4>
                                            <p>At our company, we recognize that preventing global warming goes beyond reducing our own emissions; it also involves fostering sustainable practices throughout our supply chain.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setglobalwarm(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={suppluchainmgmt} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Supply Chain Management</h4>
                                            <p>Meru recognizes that responsible supply chain management is essential to ESG implementation.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setsupplychain(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={comengage} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Community Engagement</h4>
                                            <p>Meru actively engages with the communities in which it operates.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setcomeng(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={govethic} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Governance and Ethics</h4>
                                            <p> Strong corporate governance and ethical practices are at the core of Meru's operations.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setgov(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={innores} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Innovation and Research</h4>
                                            <p>The company recognizes that innovation is vital for sustainable growth.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setinno(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='esg-block'>
                                <div className="inner-box">
                                    <figure className="image">
                                        <img src={contimprov} alt="" />
                                    </figure>
                                    <div className="lower-content">
                                        <div className="text">
                                            <h4>Continuous Improvement</h4>
                                            <p>Meru views ESG implementation as an ongoing journey.</p>
                                        </div>
                                        <div className="link-box">
                                            <span onClick={() => setconti(true)}>Read More</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

            <Modal
                className="planModal esgModal"
                show={envstew}
                onHide={() => setenvstew(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setenvstew(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Environmental Stewardship</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                    Meru places great emphasis on environmental sustainability. The company implements strategies to reduce its ecological footprint by optimising waste management, minimising energy consumption, and promoting renewable resources.
                                    </p>
                                    <p className='mt-2'>
                                    Meru engages in eco-friendly sources of energy by generating Solar powered energy at its fuel stations and manufacturing facilities. The group plans to actively engage in more environment-friendly innovations within the organisation and with its collaborators.
                                    </p>
                                    <p className='mt-2'>
                                    By actively embracing eco-friendly practices, Meru demonstrates its commitment to mitigating the negative impact on the environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="planModal esgModal"
                show={socialres}
                onHide={() => setsocialres(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setsocialres(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Social Responsibility</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                    The company understands the significance of social responsibility and prioritises the well-being and safety of its employees. Meru ensures fair labour practices, upholds employee rights, and fosters a diverse and inclusive work environment. The company nurtures a motivated workforce that drives innovation and success by promoting respect and equality.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="planModal esgModal"
                show={globalwarm}
                onHide={() => setglobalwarm(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setglobalwarm(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Our Commitment to Address Global Warming</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                        At our company, we recognize that preventing global warming goes beyond reducing our own emissions; it also involves fostering sustainable practices throughout our supply chain. We work closely with our suppliers to ensure they adhere to stringent environmental standards and promote sustainable manufacturing processes. By sourcing materials responsibly and favoring suppliers who prioritize eco-friendly practices, we contribute to the larger goal of mitigating global warming. Furthermore, we prioritize the development and promotion of environmentally friendly products and services. By offering sustainable alternatives to traditional products, we empower our customers to make informed choices that align with their own environmental values. We also strive to educate and engage our employees in sustainability initiatives, encouraging them to adopt eco-conscious practices in their daily lives. Through these collective efforts, we believe that businesses have a vital role to play in addressing global warming and creating a more sustainable future for all.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
              className="planModal esgModal"
                show={supplychain}
                onHide={() => setsupplychain(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setsupplychain(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Supply Chain Management</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                        Meru recognizes that responsible supply chain management is essential to ESG implementation. The company collaborates with partners who adhere to ethical standards, including environmental sustainability, fair trade, and human rights compliance. By selecting suppliers who share its values, Meru contributes to a responsible and sustainable value chain.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
               className="planModal esgModal"
                show={gov}
                onHide={() => setgov(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setgov(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Governance and Ethics</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                        Strong corporate governance and ethical practices are at the core of Meru's operations. The company maintains transparency, accountability, and integrity in its decision-making processes. By upholding high ethical standards and adhering to recognized governance practices, Meru instils trust and confidence in its stakeholders.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
               className="planModal esgModal"
                show={inno}
                onHide={() => setinno(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setinno(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Innovation and Research</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                        The company recognizes that innovation is vital for sustainable growth. Meru invests in research and development to develop environmentally friendly products and services. By embracing innovation, the company strives to meet evolving market demands while minimizing environmental impact and contributing to a more sustainable future.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="planModal esgModal"
                show={conti}
                onHide={() => setconti(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setconti(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Continuous Improvement</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                        Meru views ESG implementation as an ongoing journey. The company continuously assesses its ESG performance, sets ambitious goals, and works towards enhancing its practices. By fostering a culture of continuous improvement, Meru remains adaptable and responsive to emerging environmental and social challenges.
                                    </p>
                                    <p className='mt-2'>
                                        Meru exemplifies a strong commitment to implementing ESG principles in its businesses. Through environmental stewardship, social responsibility, strong governance, and continuous improvement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
              className="planModal esgModal"
                show={comeng}
                onHide={() => setcomeng(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={() => setcomeng(false)}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Community Engagement</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-justify contact-style1_form_again">
                                <div className="contact-form">
                                    <p>
                                    Meru actively engages with the communities in which it operates. The company recognizes the importance of supporting local stakeholders and participates in community development initiatives. Through investments in education under the name of “Avinash Scholarship Fund” and Children's Education and Healthcare programs under the “Feed the Child initiative”, the company strives to uplift the communities it serves and promote sustainable livelihoods.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}

export default EsgMenu