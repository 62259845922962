import React from 'react'
import Blogimg4 from '../assets/images/blog/blog-7.jpg';
import Blogimg5 from '../assets/images/blog/blog-8.jpg';
import Blogimg17 from '../assets/images/blog/blog158.jpg';
import { NavLink } from 'react-router-dom'

const HomeBlog = () => {
    return (
        <>
            <section className="blog-style1-area blog-home-section">
                <div className="container">
                    <div className="blog-top-title">
                        <div className="sec-title">

                            <h2>Latest From Blog Posts</h2>
                        </div>
                        <div className="blog-style1__button">
                            <a className="btn-one" href='/OurBlog'>
                                <span className="txt">All Posts</span>
                            </a>
                        </div>
                    </div>

                    <div className="station-card-list">
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg17} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_crickettournament-dubai-synergy-activity-7074266580238442498-jCkT?utm_source=share&utm_medium=member_desktop
' target='_blank'> Meru - Cricket match in Dubai!
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We are excited to share the glimpses of our recent cricket match in Dubai!....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_crickettournament-dubai-synergy-activity-7074266580238442498-jCkT?utm_source=share&utm_medium=member_desktop
' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg4} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_environment-sustainable-kenya-activity-7071414489157410816-oFfE?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru environment sustainable Kenya activity</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We are delighted to share the exciting news that Meru has.....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_environment-sustainable-kenya-activity-7071414489157410816-oFfE?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg5} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_blooddonation-blooddonationcamp-africa-activity-7071066345135648768-iB-u?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru Blood donation camp</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Our Employees Participated Wholeheartedly, Making.....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_blooddonation-blooddonationcamp-africa-activity-7071066345135648768-iB-u?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeBlog;
