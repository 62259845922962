import React, { useState } from 'react';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';
import BannerImg1 from '../assets/images/brand/banner-1.jpg'
import BannerImg2 from '../assets/images/brand/banner-2.jpg'
import BannerVideo1 from '../assets/images/brand/sustain.mp4'
import BannerVideo2 from '../assets/images/brand/energy.mp4'
import BannerVideo3 from '../assets/images/brand/sunflower.mp4'
import BannerVideo4 from '../assets/images/brand/corporate.mp4'

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className='next' onClick={onClick}>
            <span><i class="fa-solid fa-chevron-right"></i></span>
        </button>
    );
};

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className='prev' onClick={onClick}>
            <span><i class="fa-solid fa-chevron-left"></i></span>
        </button>
    );
};

const HomeSlider = () => {

    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const [isPlaying3, setIsPlaying3] = useState(false);
    const [isPlaying4, setIsPlaying4] = useState(false);

    const handleVideoEnded = (setIsPlaying) => () => {
        setIsPlaying(false);
    };

    const settings = {
        fade: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 8000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    autoplay: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    autoplay: true,

                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    autoplay: true,
                },
            },
        ],
    };

    return (
        <>
            <section className='banner-slider'>
                <Slider {...settings} className='banner-cards'>
                    <div className="banner-slide first-banner">
                        <div className="banner-slide-contain">
                            <div className="banner-slide-text">
                                <div className="big-title">
                                    <h2>“A choice for the future.”</h2>
                                </div>
                                <div className="text">
                                    <p>The future of our planet and our species depends on sustainability. We need to act now to protect our planet and ensure a sustainable future for all.</p>
                                </div>
                                <div className="btns-box">
                                    <NavLink className="btn-one" to='/EsgMenu'>
                                        <span className="txt">Read More</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="banner-slide-img">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source src={BannerVideo1} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            {/* <div className="banner-slide-img banner-slide-img-mobile">
                                {!isPlaying1 ? (
                                    <button onClick={() => setIsPlaying1(true)}><div className='play'></div></button>
                                ) : null}
                                <ReactPlayer
                                    url={BannerVideo1}
                                    width="100%"
                                    height='auto'
                                    playing={isPlaying1}
                                    loop={true}
                                    controls={false}
                                    onEnded={handleVideoEnded(setIsPlaying1)}
                                    fallback={null}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="banner-slide second-banner">
                        <div className="banner-slide-contain">
                            <div className="banner-slide-text">
                                <div className="big-title">
                                    <h2>“We drive you to your dreams.”</h2>
                                </div>
                                <div className="text">
                                    <p>With the growing network of our fuel stations, Meru Energy promises you to add a smile every time you visit.</p>
                                </div>
                                <div className="btns-box">
                                    <NavLink className="btn-one" to='/EnergyProduct'>
                                        <span className="txt">Read More</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="banner-slide-img">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source src={BannerVideo2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            {/* <div className="banner-slide-img banner-slide-img-mobile">
                                {!isPlaying2 ? (
                                    <button onClick={() => setIsPlaying2(true)}><div className='play'></div></button>
                                ) : null}
                                <ReactPlayer
                                    url={BannerVideo2}
                                    width="100%"
                                    height='auto'
                                    playing={isPlaying2}
                                    loop={true}
                                    controls={false}
                                    onEnded={handleVideoEnded(setIsPlaying2)}
                                    fallback={null}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="banner-slide third-banner">
                        <div className="banner-slide-contain">
                            <div className="banner-slide-text">
                                <div className="big-title">
                                    <h2>“Seeds to supper”</h2>
                                </div>
                                <div className="text">
                                    <p>From seed selection and sustainable farming practices to harvesting and delivering fresh produce to your table, our refined edible oils help you remain healthy and happy.</p>
                                </div>
                                <div className="btns-box">
                                    <NavLink className="btn-one" to='/AgriProduct'>
                                        <span className="txt">Read More</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="banner-slide-img">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source src={BannerVideo3} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            {/* <div className="banner-slide-img banner-slide-img-mobile">
                                {!isPlaying3 ? (
                                    <button onClick={() => setIsPlaying3(true)}><div className='play'></div></button>
                                ) : null}
                                <ReactPlayer
                                    url={BannerVideo3}
                                    width="100%"
                                    height='auto'
                                    playing={isPlaying3}
                                    loop={true}
                                    controls={false}
                                    onEnded={handleVideoEnded(setIsPlaying3)}
                                    fallback={null}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="banner-slide fourth-banner">
                        <div className="banner-slide-contain">
                            <div className="banner-slide-text">
                                <div className="big-title">
                                    <h2>“Bridging the distance, bringing us closer.”</h2>
                                </div>
                                <div className="text">
                                    <p>Through our innovative Supply chain solutions, we break down barriers and footer meaningful connections.</p>
                                </div>
                                <div className="btns-box">
                                    <NavLink className="btn-one" to='/Logistics'>
                                        <span className="txt">Read More</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="banner-slide-img">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <source src={BannerVideo4} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            {/* <div className="banner-slide-img banner-slide-img-mobile">
                                {!isPlaying4 ? (
                                    <button onClick={() => setIsPlaying4(true)}><div className='play'></div></button>
                                ) : null}
                                <ReactPlayer
                                    url={BannerVideo4}
                                    width="100%"
                                    height='auto'
                                    playing={isPlaying4}
                                    loop={true}
                                    controls={false}
                                    onEnded={handleVideoEnded(setIsPlaying4)}
                                    fallback={null}
                                />
                            </div> */}
                        </div>
                    </div>
                </Slider>
            </section>
            {/* <section className="main-slider style1">
                <div className="slider-box">
                    <Slider className='banner-carousel owl-themes' {...settings}>
                        <div className="slide">
                            <div className="overlays-slider">

                            </div>
                            <div>
                                <img src={BannerImg1} alt="" />
                                <div className="auto-container">
                                    <div className="content">
                                        <div className="big-title">
                                            <h2>Welcome to<br /> Mount Meru Group</h2>
                                        </div>
                                        <div className="text">
                                            <p>Mount Meru Group is a business conglomerate in Africa, <br />headquartered in Dubai, (UAE) with operations in Downstream <br />Petroleum Business, Manufacturing of Edible Oils, LPG, Lubricants,<br /> Storage and Logistics, Infrastructure (Real Estate).</p>
                                        </div>
                                        <div className="btns-box">
                                            <a className="btn-one" href="/AboutEnergy">
                                                <span className="txt">Read More</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slide">
                            <div className="overlays-slider">

                            </div>
                            <div>
                                <img src={BannerImg2} alt="" />
                                <div className="auto-container">
                                    <div className="content">
                                        <div className="big-title">
                                            <h2>Welcome to<br /> Mount Meru Petroleum</h2>
                                        </div>
                                        <div className="text">
                                            <p>Mount Meru Group stands at the forefront of innovation, driving <br /> transformation and leaving a lasting impact. As a pioneering business <br />conglomerate headquartered in Dubai, (UAE), we revolutionize industries <br />across Africa.                                            </p>
                                        </div>
                                        <div className="btns-box">
                                            <a className="btn-one" href="AboutAgri">
                                                <span className="txt">Read More</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Slider>
                </div>
            </section> */}
        </>
    );
}

export default HomeSlider;
