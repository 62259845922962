import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Fade from 'react-reveal/Fade';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";
import CoreValues from './CoreValues';


const Overview = () => {
    return (
        <>
            <Header />
            <OverviewBanner />
            <div className="section-parent">
                <OverviewAbout />
                <OverviewChooseUs />
            </div>

            <Footer />
        </>
    );
}

export default Overview;
