import React from 'react'
import HowToApplyDetail from './HowToApplyDetail';
const HowToApply = () => {
  return (
    <>

    <HowToApplyDetail />

    </>
  )
}

export default HowToApply