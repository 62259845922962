import React from 'react'
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import ReactPlayer from 'react-player/lazy'


import aboutImage from '../assets/images/MeruWhite-02-3.png'

const HomeAbout = () => {
    return (
        <>
            <section className="about-style1-area">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6">
                            <div className="about-style1__content">
                                <Fade bottom>
                                    <div className="sec-title">

                                        <h2>
                                            Our Journey
                                        </h2>
                                    </div>
                                </Fade>
                                <div className="inner-content">
                                    <Fade bottom>

                                        <div className="text-box">
                                            <p>
                                                Mr Tarsem Chand and Mrs Kanta Devi Mittal, founders of Mount Meru group, started journey in 1979, driven by a relentless commitment to innovation and creating a lasting impact. As a distinguished business conglomerate headquartered in Dubai (UAE), we have established a formidable presence across Africa.
                                            </p>
                                            <p>
                                                Our operations span the Downstream Petroleum Business, Manufacturing of Edible Oils, LPG, Lubricants, Storage and Logistics, and Infrastructure (Real Estate).
                                            </p>
                                        </div>
                                        <div className="btns-box">
                                            <Link className="btn-one" to="/Overview">
                                                <span className="txt">Read More</span>
                                            </Link>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <Fade bottom>

                                <div className='player-wrapper video-lg'>
                                    {/* <ReactPlayer
                                        className='react-player youtube-player pristine'
                                        url='https://www.youtube.com/watch?v=RSg_vSP8r8g'
                                        width="100%"
                                        playing={true}
                                        loop={true}
                                        controls={false}

                                    /> */}
                                    <ReactPlayer url='https://www.youtube.com/watch?v=RSg_vSP8r8g'
                                        width="100%"
                                        playing={true}
                                        loop={true}
                                        controls={true}
                                        light={aboutImage}
                                        fallback={null}
                                    />
                                </div>
                            </Fade>
                        </div>


                    </div>
                </div>
            </section>

        </>
    );
}

export default HomeAbout;
