import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/about/meru-logo.png'
import logoWhite from '../assets/images/MeruWhite-02-3.png'

const Header = () => {

  const [
    menuItems, setMenuItems] = useState([
      {
        title: 'Home',
        link: '/Home',
      },
      {
        title: 'About Us',
        link: '#',
        children: [
          { title: 'Overview', link: '/Overview' },
          { title: 'History', link: '/History' },
          { title: 'Leadership', link: '/Leadership' }
        ]
      },
      {
        title: 'Business',
        link: '#',
        children: [
          {
            title: 'Energy',
            link: '/AboutEnergy',
            children: [
              {
                title: 'Mount Meru Petroleum', link: '/MountMeruPetrolium', children: [
                  { title: 'Botswana', link: '/BotswanaDescription' },
                  { title: 'DR Congo', link: '/DrDescription' },
                  { title: 'Gabon', link: '/GabonDescription' },
                  { title: 'Kenya', link: '/KenyaDescription' },
                  { title: "Côte d'Ivoire", link: '/CoteDescription' },

                  { title: 'Malawi', link: '/MalawiDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                  { title: 'Rwanda', link: '/RwandaDescription' },
                  { title: 'Swaziland', link: '/SwazilandDescription' },
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                  { title: 'Uganda', link: '/UgandaDescription' },
                  { title: 'Zambia', link: '/ZambiaDescription' },
                  { title: 'Zimbabwe', link: '/ZimbabweDescription' },
                ]
              },
              {
                title: 'Acer Petroleum Limited', link: '/AcerPetroleumLimited', children: [
                  { title: 'Botswana', link: '/BotswanaDescription' },
                  { title: 'Gabon', link: '/GabonDescription' },
                  { title: 'Kenya', link: '/KenyaDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                  { title: 'Namibia', link: '/MozambiqueDescription' },
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                ]
              },
              {
                title: 'LPG', link: '/MountMeruGas', children: [
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                  { title: 'Zambia', link: '/ZambiaDescription' },
                  { title: 'Uganda', link: '/UgandaDescription' },
                  { title: 'Rwanda', link: '/RwandaDescription' },
                ]
              },
              {
                title: 'Force1 Lubricants', link: '/MountMeruLubricant', children: [
                  { title: 'Zambia', link: '/ZambiaDescription' },
                  { title: 'Malawi', link: '/MalawiDescription' },
                  { title: 'Uganda', link: '/UgandaDescription' },
                  { title: 'Rwanda', link: '/RwandaDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                ]
              },
              {
                title: 'NFR', link: '/Nfr', children: [
                  { title: 'Kenya', link: '/KenyaDescription' },
                  { title: 'Malawi', link: '/MozambiqueDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                  { title: 'Rwanda', link: '/RwandaDescription' },
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                  { title: 'Zambia', link: '/ZambiaDescription' },





                ]
              },
              {
                title: 'Energy Products',
                link: '/EnergyProduct'
              },
            ]
          },
          {
            title: 'Agri',
            link: '/AboutAgri',
            children: [
              {
                title: 'Mount Meru Millers', link: '/MountMerumillier', children: [
                  { title: 'Malawi', link: '/MalawiDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                  { title: 'Uganda', link: '/UgandaDescription' },
                  { title: 'Zambia', link: '/ZambiaDescription' },
                  { title: 'Zimbabwe', link: '/ZimbabweDescription' },
                ]
              },
              {
                title: 'Mount Meru Soyco', link: '/Mountmerusoyco', children: [
                  { title: 'Rwanda', link: '/RwandaDescription' },
                ]
              },
              {
                title: 'Agri Products',
                link: '/AgriProduct'
              },
            ]
          },
          {
            title: 'Supply chain, Logistics and Hospitality ',
            link: '/Logistics',
            children: [
              {
                title: 'Mount Meru Logistics', link: '/Mountmerulogistic', children: [
                  { title: 'Botswana', link: '/BotswanaDescription' },
                  { title: 'Gabon', link: '/GabonDescription' },
                  { title: "Côte d'Ivoire", link: '/CoteDescription' },
                  { title: 'Mozambique', link: '/MozambiqueDescription' },
                  { title: 'Namibia', link: '/NamibiaDescription' },
                  { title: 'Rwanda', link: '/RwandaDescription' },
                  { title: 'Tanzania', link: '/TanzaniaDescription' },
                  { title: 'Zambia', link: '/ZambiaDescription' },

                ]
              },
              {
                title: 'Supply Chain and Logistics Services',
                link: '/SupplyChain'
              },
            ]

          },
          {
            title: 'Engineering, Procurement, and Construction',
            link: '/Epc',
            children: [
              { title: 'White Horse Commodities FZE, UAE', link: '/AcerInfrastructure' }

            ]

          },
          {
            title: 'Mining',
            link: '/Mining',
         
          },
          {
            title: 'SolarEnergy',
            link: '/SolarEnergy',
         
          },
     
        ]
      },


      {
        title: 'ESG',
        link: '/EsgMenu',
      },
      {
        title: 'CSR',
        link: '/Csr',
      },


      {
        title: 'Careers',
        link: '#',
        children: [
          { title: 'Life at Meru', link: '/Lifeofmeru' },
          { title: 'Job Openings', link: '/FindJob' }
        ]
      },
      {
        title: 'Newsroom',
        link: '#',
        children: [
          { title: 'Our Blogs', link: '/OurBlog' }
        ]
      },
      {
        title: 'Contact',
        link: '/ContactUs',
      }
    ]);

  const handleToggle = (item) => {
    item.expanded = !item.expanded;
    setMenuItems([...menuItems]);
  };

  const renderMenuItems = (items) => {
    return (
      <ul className="navigation clearfix">
        {items.map((item, index) => (
          <li key={index} className={`dropdown ${item.expanded ? 'current' : ''}`}>
            <NavLink to={item.link} onClick={handleCloseBtn}>{item.title}</NavLink>
            {item.children && (
              <>
                <div className="dropdown-btn" onClick={() => handleToggle(item)}>
                  <span className={`fa fa-angle-${item.expanded ? 'down' : 'right'}`}></span>
                </div>
                {item.expanded && renderMenuItems(item.children)}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const handleMobileNavToggle = () => {
    document.body.classList.add('mobile-menu-visible');
  };

  const handleCloseBtn = () => {
    document.body.classList.remove('mobile-menu-visible');
  };

  return (
    <header className="main-header header-style-one">
      <div className="header-style-one__inner">
        <div className="main-logo-box">
          <NavLink to="/Home" className='mobile-logo'><img src={logoWhite} alt="Logo" title="Logo" /></NavLink>
          <NavLink to="/Home" className='desktop-logo'><img src={logoWhite} alt="Logo" title="Logo" /></NavLink>
        </div>
        {/* Start Header Top */}
        <div className="header-style-one__header-content">
          <div className="header-top">
            <div className="auto-container">
              <div className="outer-box">
                {/* <div className="header-top__left">
                  <div className="welcome-text">
                    <p>Welcome to Mount Meru Group</p>
                  </div>
                </div> */}
                <div className="header-top__right">
                  <div className="header-contact-info text-right-rtl">
                    <ul>
                      <li>
                        <div className="icon">
                          <span className="flaticon-email-1" />
                        </div>
                        <div className="text">
                          <h6><a href="mailto: info@mountmerugroup.com"> info@mountmerugroup.com</a></h6>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="flaticon-phone-call-3" />
                        </div>
                        <div className="text">
                          <h6><a href="tel:123456789">+971 48747731</a></h6>
                        </div>
                      </li>

                    </ul>
                  </div>
                  <div className="header-social-link">
                    <div className="inner-title">
                      <h4>Follow On:</h4>
                    </div>
                    <div className="social-link">
                      <ul className="clearfix">

                        <li>
                          <a href="https://www.linkedin.com/company/mount-meru-group/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="header-right header-search">

                    <div class="serach-button-style2">
                      <input type="search" class="form-control" name="search-input" value="" placeholder="Search..." required="" />
                      <button type="button" fdprocessedid="w7c57"><i class="fa fa-search"></i></button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* End Header Top */}
          {/* Start Header */}
          <div className="header">
            <div className="auto-container">
              <div className="outer-box">
                <div className="header-left">
                  <div className="nav-outer style1 clearfix">
                    {/* Mobile Navigation Toggler */}
                    <div className="mobile-nav-toggler" onClick={handleMobileNavToggle}>
                      <div className="inner">
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </div>
                    </div>
                    {/* Main Menu */}
                    <nav className="main-menu style1 navbar-expand-md navbar-light">
                      <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul className="navigation clearfix">
                          <li><NavLink to="/Home">Home</NavLink>

                          </li>

                          <li className="dropdown"><NavLink to="#">About Us</NavLink>
                            <ul>
                              <li>
                                <NavLink to="/Overview">Overview</NavLink>

                              </li>
                              <li>
                                <NavLink to="/History">History</NavLink>
                              </li>
                              <li>
                                <NavLink to="/Leadership">Leadership</NavLink>
                              </li>
                            </ul>
                          </li>


                          <li className="dropdown"><NavLink to="#">Business</NavLink>
                            <ul>
                              <li className="dropdown"><NavLink to="/AboutEnergy">Energy</NavLink>
                                <ul>
                                  <li className="dropdown"><NavLink to="/MountMeruPetrolium">Mount Meru Petroleum</NavLink>
                                    <ul>
                                      <li><NavLink to="/BotswanaDescription">Botswana</NavLink></li>
                                      <li><NavLink to="/DrDescription">DR Congo</NavLink></li>
                                      <li><NavLink to="/GabonDescription">Gabon</NavLink></li>
                                      <li><NavLink to="/KenyaDescription">Kenya</NavLink></li>
                                      <li><NavLink to="/CoteDescription">Côte d'Ivoire</NavLink></li>

                                      <li><NavLink to="/MalawiDescription">Malawi</NavLink></li>
                                      <li><NavLink to="/MozambiqueDescription">Mozambique</NavLink></li>
                                      <li><NavLink to="/RwandaDescription">Rwanda</NavLink></li>
                                      <li><NavLink to="/SwazilandDescription">Swaziland</NavLink></li>
                                      <li><NavLink to="/TanzaniaDescription">Tanzania</NavLink></li>
                                      <li><NavLink to="/UgandaDescription">Uganda</NavLink></li>
                                      <li><NavLink to="/ZambiaDescription">Zambia</NavLink></li>
                                      <li><NavLink to="/ZimbabweDescription">Zimbabwe</NavLink></li>
                                    </ul>
                                  </li>
                                  <li className="dropdown"><NavLink to="/AcerPetroleumLimited">Acer Petroleum Limited</NavLink>
                                    <ul>
                                      <li><NavLink to="/BotswanaDescription">Botswana</NavLink></li>
                                      <li><NavLink to="/GabonDescription">Gabon</NavLink></li>
                                      <li><NavLink to="/KenyaDescription">Kenya</NavLink></li>
                                      <li><NavLink to="MozambiqueDescription">Mozambique</NavLink></li>
                                      <li><NavLink to="/NamibiaDescription">Namibia</NavLink></li>
                                      <li><NavLink to="/TanzaniaDescription">Tanzania</NavLink></li>
                                    </ul>
                                  </li>
                                  <li className="dropdown"><NavLink to="/MountMeruGas">LPG</NavLink>
                                    <ul>
                                      <li><NavLink to="/TanzaniaDescription">Tanzania</NavLink></li>
                                      <li><NavLink to="/ZambiaDescription">Zambia</NavLink></li>
                                      <li><NavLink to="/UgandaDescription">Uganda</NavLink></li>
                                      <li><NavLink to="RwandaDescription">Rwanda</NavLink></li>
                                    </ul>
                                  </li>
                                  <li className="dropdown"><NavLink to="/MountMeruLubricant">Force1 Lubricants</NavLink>
                                    <ul>
                                      <li><NavLink to="/ZambiaDescription">Zambia</NavLink></li>
                                      <li><NavLink to="/MalawiDescription">Malawi</NavLink></li>
                                      <li><NavLink to="/UgandaDescription">Uganda</NavLink></li>
                                      <li><NavLink to="/RwandaDescription">Rwanda</NavLink></li>
                                      <li><NavLink to="/MozambiqueDescription">Mozambique</NavLink></li>
                                    </ul>
                                  </li>
                                  <li className="dropdown"><NavLink to="/Nfr">NFR</NavLink>
                                    <ul>

                                      <li><NavLink to="/KenyaDescription">Kenya</NavLink></li>
                                      <li><NavLink to="/MalawiDescription">Malawi</NavLink></li>
                                      <li><NavLink to="/MozambiqueDescription">Mozambique</NavLink></li>
                                      <li><NavLink to="RwandaDescription">Rwanda</NavLink></li>
                                      <li><NavLink to="TanzaniaDescription">Tanzania</NavLink></li>
                                      <li><NavLink to="ZambiaDescription">Zambia</NavLink></li>
                                    </ul>
                                  </li>
                                  <li><NavLink to="/EnergyProduct">Energy Products</NavLink></li>
                                  <li><NavLink to="/Mining">Mining</NavLink></li>
                                  <li><NavLink to="/SolarEnergy">Solar Energy</NavLink></li>
                                </ul>
                              </li>

                              <li className="dropdown"><NavLink to="/AboutAgri">Agri</NavLink>
                                <ul>
                                  <li className='dropdown'><NavLink to="/MountMerumillier">Mount Meru Millers</NavLink>
                                    <ul>
                                      <li><NavLink to="/MalawiDescription">Malawi</NavLink></li>
                                      <li><NavLink to="/MozambiqueDescription">Mozambique</NavLink></li>
                                      <li><NavLink to="TanzaniaDescription">Tanzania</NavLink></li>
                                      <li><NavLink to="/UgandaDescription">Uganda</NavLink></li>
                                      <li><NavLink to="ZambiaDescription">Zambia</NavLink></li>
                                      <li><NavLink to="ZimbabweDescription">Zimbabwe</NavLink></li>
                                    </ul>
                                  </li>
                                  <li className='dropdown'><NavLink to="/Mountmerusoyco">Mount Meru Soyco</NavLink>
                                    <ul>

                                      <li><NavLink to="RwandaDescription">Rwanda</NavLink></li>

                                    </ul>
                                  </li>
                                  <li><NavLink to="/AgriProduct">Agri Products</NavLink></li>
                                </ul>
                              </li>

                              <li className="dropdown"><NavLink to="/Logistics">Supply chain, Logistics <br /> and Hospitality</NavLink>
                                <ul>
                                  <li className="dropdown"><NavLink to="/Mountmerulogistic">Mount Meru Logistics</NavLink>
                                    <ul>
                                      <li><NavLink to="/BotswanaDescription">Botswana</NavLink></li>
                                      <li><NavLink to="/GabonDescription">Gabon</NavLink></li>
                                      <li><NavLink to="/CoteDescription">Côte d'Ivoire</NavLink></li>
                                      <li><NavLink to="/MozambiqueDescription">Mozambique</NavLink></li>
                                      <li><NavLink to="/NamibiaDescription">Namibia</NavLink></li>
                                      <li><NavLink to="/RwandaDescription">Rwanda</NavLink></li>
                                      <li><NavLink to="/TanzaniaDescription">Tanzania</NavLink></li>
                                      <li><NavLink to="ZambiaDescription">Zambia</NavLink></li>
                                    </ul>
                                  </li>
                                  <li><NavLink to="/SupplyChain">Supply Chain and Logistics Services </NavLink></li>
                                </ul>
                              </li>




                              <li className="dropdown"><NavLink to="/Epc">Engineering, <br /> Procurement, and Construction</NavLink>
                                <ul>
                                  <li><NavLink to="/AcerInfrastructure">White Horse Commodities FZE, UAE</NavLink></li>
                                </ul>
                              </li>




                              {/* <li className="dropdown"><NavLink to="#">Projects</NavLink>
                                <ul>
                                  <li><NavLink to="#">Business Unit 1</NavLink></li>
                                  <li><NavLink to="#">Business Unit 2</NavLink></li>
                                </ul>
                              </li> */}




                              {/* <li className="dropdown"><NavLink to="#">Map View</NavLink>
                                <ul>
                                  <li><NavLink to="#">Map Locator</NavLink></li>
                                </ul>
                              </li> */}
                            </ul>
                          </li>






                          <li><NavLink to="/EsgMenu">ESG</NavLink>

                          </li>
                          <li><NavLink to="/Csr">CSR</NavLink>

                          </li>

                          <li className="dropdown"><NavLink to="#">Careers</NavLink>
                            <ul>
                              <li><NavLink to="/Lifeofmeru">Life at Meru</NavLink></li>
                              <li><NavLink to="/FindJob">Job Openings</NavLink></li>
                            </ul>
                          </li>



                          <li className="dropdown"><NavLink to="#">Newsroom</NavLink>
                            <ul>
                              <li>
                                <NavLink to="/OurBlog">Our Blogs</NavLink>

                              </li>

                            </ul>
                          </li>

                          <li><NavLink to="/ContactUs">Contact</NavLink></li>
                        </ul>
                      </div>
                    </nav>
                    {/* Main Menu End */}
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* End header */}
        </div>
      </div>
      {/* Sticky Header */}
      <div className="sticky-header">
        <div className="container">
          <div className="clearfix">
            {/* Logo */}
            <div className="logo float-left">
              <a href="#" className="img-responsive"><img src="assets/images/resources/sticky-logo.png" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
      {/* End Sticky Header */}
      <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={handleCloseBtn}>
          <span className="icon fa fa-times-circle"></span>
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="/">
              <img
                src={logoWhite}
                alt=""
                title=""
              />
            </a>
          </div>
          <div className="menu-outer">
            <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
              {renderMenuItems(menuItems)}
            </div>
          </div>
          {/* Social Links */}
          <div className="social-links">
            <ul className="clearfix">


              <li>
                <a href="https://www.linkedin.com/company/mount-meru-group/">
                  <span className="fa fa-linkedin"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;

// header end 