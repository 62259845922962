import React from 'react'

const CareerSearch = () => {
  return (
    <>
       <div className="searchcard-section">
                <div className="container">
                    <div className="row height100 searchcareer-form">
                        <form action="" method="get">
                            <div className="flexing-submit">
                                <div className="searchflex">
                                    <div className="search-card-first">
                                        <input type="text" className='form-control' name="searchtext" id="" placeholder='Search for job by title or keyword' />
                                    </div>


                                    <div className="search-card-first">
                                        <input type="search" className='form-control' name="searchtext" id="" placeholder='Location' />
                                    </div>
                                </div>
                                <button type="submit" className='form-control'><i class="fa fa-search"></i></button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
    </>
  )
}

export default CareerSearch