import React from 'react'
import Fade from 'react-reveal/Fade';

const Timeline = () => {
    return (
        <>
           

                <section className="about-style2-area">

                    <div id="timeline-container">
                        <div class="container">
                            <div class="sec-title text-center">
                                <div class="sec-titlenew sub-title">
                                    <Fade bottom>
                                        <h2><span class="border-left"></span>
                                            <span class="one-text"> Roadmap</span>
                                            <span class="two-text"> </span>
                                            <span class="border-right"></span>
                                        </h2>
                                    </Fade>
                                </div>
                            </div>

                        </div>
                        <div class="inner-container">

                            <ul class="timeline">
                                <li class="timeline-item" data-date="1979">Start of Meru in Tanzania.</li>
                                <li class="timeline-item" data-date="1994">First Edible Oil Factory in Arusha, Tanzania.</li>
                                <li class="timeline-item" data-date="1999">Opening of First Meru Station.</li>
                                <li class="timeline-item" data-date="2004">Start of Acer Petroleum, Tanzania.</li>
                                <li class="timeline-item" data-date="2007">Expansion into Zambia and Rwanda.</li>
                                <li class="timeline-item" data-date="2013">Expansion into Malawi.</li>
                                <li class="timeline-item" data-date="2017">100th Station Inaugurated. Lubricant Plant in Zambia. Expansion in Mozambique and Kenya.</li>
                                <li class="timeline-item" data-date="2019">Partnership with M-Pharma. Expansion in West Africa.</li>
                                <li class="timeline-item" data-date="2020">200th Station Opened. Soap Production Started in Malawi and Rwanda. Jet Fuel Depot in Malawi. Expansion in Gabon.</li>
                                <li class="timeline-item" data-date="2021">Crossing the milestone of 250+ stations. 3 New Edible Oil Refineries including Greenfield Refinery in Zimbabwe.</li>
                                <li class="timeline-item" data-date="2023">

                                    <p>
                                        Crossed 300 stations.
                                    </p>
                                    <p>
                                        Commenced Operations of edible oil Terminal in Mozambique.
                                    </p>
                                    <p>
                                        Crossed 900+ fleet – logistics.
                                    </p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </section>
       
        </>
    )
}

export default Timeline