import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory from '../assets/images/about/energy-aboutus.jpeg'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const AboutEnergy = () => {
    return (
        <>
            <Header />

            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Energy</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Energy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">
                <section className="shop-page-one">
                    <div className="shop-page-one_inner">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="findus-btn">
                                    <div className="btns-box">
                                        <Link className="btn-one" to="/MeruStation">
                                            <span className="txt">Find Your Meru Station</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="about-style2-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6">
                                <div className="about-style2__content">




                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    In the dynamic sector of energy, Meru takes pride in its role as a catalyst for positive change. We believe in the transformative power of sustainable energy solutions to drive economic growth and improve lives.                                            </p>
                                                <p className='mt-3'>
                                                    With our unwavering commitment to renewable energy, we are actively involved in harnessing the potential of solar, wind, and other clean energy sources. Through our innovative projects, we empower communities by providing access to reliable and affordable energy solutions.

                                                </p>
                                                <p>
                                                    We believe in improving on a continuous basis by implementing latest technology & design innovation throughout our fuel stations.

                                                    At Mount Meru, we operate 300+ 'Meru' branded fuel stations and manage a robust downstream supply chain, ensuring efficiency and seamless distribution of energy resources. Our integrated approach enables us to meet the evolving energy demands of our customers while minimizing environmental impact.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2 img-box2-no-anim">

                                            <div className="inner">
                                                <img srcSet={Aboutstory} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-xl-12">
                                <div className="about-style2__content">




                                    <div className="inner-content mt-4">
                                        <Fade bottom>
                                            <div className="text">

                                                <p>
                                                    Currently, we deliver high-quality Petrol, Diesel & Kerosene through this retail chain. We recently also started delivering Fuel & Lubricants to the Bunkering, Aviation, and Mining industries.
                                                </p>
                                                <p>
                                                    Mount Meru Lubricants currently has one of the best plants in Africa (Zambia) with state-of-the-art technology & a blending unit. We currently operate & blend Automotive Synthetic lubricants and, in the last 4 years, have made quite a reputed brand name for our product (Force Lubricants). Mount Meru Petroleum plans to establish and operate more than 2000 fuel stations across Africa by 2030.</p>
                                                <p>
                                                    As a responsible energy player, we constantly explore new technologies and practices to enhance energy efficiency and reduce carbon footprint. We are dedicated to fostering a sustainable energy future that supports economic development, environmental conservation, and the well-being of communities we serve.

                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2 className='header-flextion-h2'><span class="border-left"></span>
                                        <div className='header-flextion '>

                                            <span class="one-text">MMG 2030 Energy </span>
                                            <span class="two-text"> Business Vision</span>
                                        </div>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="station-card-list mb-40px-energy">

                            <div class="">

                                <div class="single-choose-box mar-b-30">
                                    <div class="inner ">
                                        <div class="icon iconnew"><span><i class="fa fa-globe" aria-hidden="true"></i></span></div>
                                        {/* <div class="counting-box">01</div> */}
                                        <div class="text">
                                            <p>
                                                Pan Africa Energy Brand
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-link"></i></span></div>
                                        <div class="text">
                                            <p>
                                                Fully integrated midstream & downstream supply chain</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-truck"></i></span></div>
                                        <div class="text">
                                            <p>Logistics solution with 3,000 trucks and fuel storage depots</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-file-text-o"></i></span></div>
                                        <div class="text">
                                            <p>NFR portfolio of Food, Service Centres and Convenience Mart</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa fa-group"></i></span></div>
                                        <div class="text">
                                            <p>Operate 2000+ stations with best in class infrastructure and delightful customer experience</p>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>


                <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2 className='header-flextion-h2'><span class="border-left"></span>
                                        <div className='header-flextion '>
                                            <span class="one-text">MMG Energy  </span>
                                            <span class="two-text"> Value Chain</span>
                                        </div>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="station-card-list">

                            <div class="">

                                <div class="single-choose-box mar-b-30">
                                    <div class="inner ">
                                        <div class="icon iconnew"><span><i class="fa-sharp fa-solid fa-thumbs-up"></i></span></div>

                                        <div class="text">
                                            <h3>Midstream</h3>
                                            <p>
                                                Midstream Operation & Supplies to Government Linked Tendor.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-solid fa-business-time"></i></span></div>
                                        <div class="text">
                                            <h3>Purchase</h3>
                                            <p>
                                                Procurement of energy products via BPS, OTS, Beira, and Government agencies.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-solid fa-database"></i></span></div>
                                        <div class="text">
                                            <h3>Storage & Hospitality</h3>
                                            <p>
                                                Storage & hospitality across our footprint presence.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-sharp fa-solid fa-car-side"></i></span></div>
                                        <div class="text">
                                            <h3>Logistics</h3>
                                            <p>Transportation Meru branded logistics fleet.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="single-choose-box mar-b-30">
                                    <div class="inner">
                                        <div class="icon iconnew"><span><i class="fa-solid fa-gas-pump"></i></span></div>
                                        <div class="text">
                                            <h3>Distribution</h3>
                                            <p>
                                                Sale of fuel through Meru petrol stations and bulk sales.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>
            </div>


            <Footer />
        </>
    );
}

export default AboutEnergy;
