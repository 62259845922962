import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { createBlog, getBlog, getAllBlogs, updateBlog, deleteBlog } from './redux/BlogRedux';
import { getToken } from "./redux/Token";
import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import { Form, Button, Modal } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

function Blogs(props) {
    useEffect(() => {
        props.getAllBlogs();
        props.getToken();
    }, []);

    const [modalShow, setModalShow] = useState(false);

    const createBlog = (data) => {
        props.createBlog(data);
    }

    function MyVerticallyCenteredModal(props) {
        const [title, setTitle] = useState("");
        const [description, setDescription] = useState("");
        const [image, setImage] = useState("");
        const [status, setStatus] = useState("");

        const handleTitleChange = useCallback((event) => {
            setTitle(event.target.value);
        }, []);

        const handleDescriptionChange = useCallback((event, editor) => {
            setDescription(editor.getData());
        }, []);

        const handleImageChange = useCallback((event) => {
            setImage(event.target.files[0]);
        }, []);

        const handleStatusChange = useCallback((event) => {
            setStatus(event.target.value);
        }, []);

        const handleSubmit = useCallback((event) => {
            event.preventDefault();
            if (!title || !description || !image || !status) {
                toast.error("Please fill all the required fields.");
                return;
            }
            const formData = new FormData();
            formData.append("title", title);
            formData.append("description", description);
            formData.append("image", image);
            formData.append("status", status === "show" ? 1 : 0);
            createBlog(formData);
            setModalShow(false)
        }, [title, description, image, status, props]);

        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Add Blogs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Title<span className='text-red'>*</span></Form.Label>
                            <Form.Control type="text" name="title" placeholder="Enter title" value={title} onChange={handleTitleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description<span className='text-red'>*</span></Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{ placeholder: "Enter description" }}
                                data={description}
                                onChange={handleDescriptionChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="Image">
                            <Form.Label>Image<span className='text-red'>*</span></Form.Label>
                            <Form.Control type="file" name="image" onChange={handleImageChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label>Status<span className='text-red'>*</span></Form.Label>
                            <Form.Control as="select" name="status" value={status} onChange={handleStatusChange}>
                                <option value="">Select status</option>
                                <option value="show">Show</option>
                                <option value="hide">Hide</option>
                            </Form.Control>
                        </Form.Group>

                        <Modal.Footer>
                            <Button type="submit">Submit</Button>
                            <Button onClick={() => setModalShow(false)}>Cancel</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Blogs</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/Dashboard'><a href="#">Dashboard</a></Link></li>
                            <li className="breadcrumb-item active">Blogs</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-end py-3">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow(true)}>Add Blogs</button>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Image</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Raheem Lehner</td>
                                                <td>Dynamic Division Officer</td>
                                                <td>img</td>
                                                <td>Show</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-secondary btn-sm">Edit</button>
                                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <MyVerticallyCenteredModal
                show={modalShow}
            />

            <AdminFooter />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        blogs: state.blogs,
        token: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createBlog: (blog, token) => dispatch(createBlog(blog, token)),
        getBlog: (id, token) => dispatch(getBlog(id, token)),
        getAllBlogs: (token) => dispatch(getAllBlogs(token)),
        updateBlog: (id, blog, token) => dispatch(updateBlog(id, blog, token)),
        deleteBlog: (id, token) => dispatch(deleteBlog(id, token)),
        getToken: () => dispatch(getToken())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
