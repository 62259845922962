import React from 'react'
import Fade from 'react-reveal/Fade';
import nfr from '../assets/images/csr-img/nfr.jpg'
import nfr1 from '../assets/images/NFR-1.jpg'
import nfr2 from '../assets/images/NFR-2.jpg'
import nfr3 from '../assets/images/NFR-3.jpg'
import { Slide } from 'react-reveal';
import Slider from 'react-slick';


import Header from './Header';
import Footer from './Footer';
const Nfr = () => {
    const nfrSlider = {
        fade: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        fade: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    autoplay: true
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                },
            },
        ],
    };
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>NFR</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">NFR</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">

                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">


                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">
                                            <Slider {...nfrSlider}>
                                                <div className="inner">
                                                    <img srcSet={nfr1} alt="Aboutstory" />
                                                </div>
                                                <div className="inner">
                                                    <img srcSet={nfr2} alt="Aboutnew" />
                                                </div>
                                                <div className="inner">
                                                    <img srcSet={nfr3} alt="Aboutnew" />
                                                </div>



                                            </Slider>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6">
                                <div className="about-style2__content">

                                    <Fade bottom>
                                        <div className="sec-title child-contents">

                                            <h2>
                                                TAKE A BREAK
                                            </h2>
                                        </div>
                                    </Fade>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>Everyone deserves a break and we know, just what you need!</p>
                                                <p>
                                                    At MERU, fueling your vehicle is not a boring task,
                                                    With our well stocked convenience shopping marts, you can always treat yourself.
                                                </p>
                                                <p>
                                                    Our NFR division works actively to give you the first-class service you deserve.
                                                    To make your journey a One-stop destination, Under this division we equip our properties with Mini Marts, Café, Tire Repair and service Centers, Pharmacy, ATMs and other  utility services.
                                                </p>
                                                <p>
                                                    NFR plays an important role to help serve our customers with a sweet tinge of “Meru Customer Delight”
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>
            </div >

            <Footer />
        </>
    )
}

export default Nfr