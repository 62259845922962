import React from 'react'
import Fade from 'react-reveal/Fade';
import Header from './Header';
import Footer from './Footer';
import country9 from "../assets/images/icon/country-images/malawai.png";

const contactBg = {
    backgroungImage: 'url(assets/images/pattern/thm-pattern-3.png)'
}
const MalawiDescription = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Malawi
                                    </h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                
                                       
                                        <li class="active">Malawi
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="map-images">
                                <img src={country9} alt="" srcset="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="contact-info-area">
                    <div className="container">
                        <div className="sec-title center text-center country-headingss">



                            <div class="section-header">
                                <h3>  Zikomo
</h3>
                            </div>


                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <h3 className='count-main-name'>
                                    About  Malawi


                                </h3>
                                <div className="order-summary-box list-countries country-desc">
                                    <Fade bottom>
                                        <ul>
                                            <li>
                                                <h4>"Capital"</h4>
                                                <div className="value">
                                                    <div className="left">Lilongwe
</div>
                                                </div>
                                            </li>
                                            <li>
                                                <h4>Area </h4>
                                                <div className="value">
                                                    <div className="left">
                                                    Area: 118,484 square kilometers (45,747 square miles)





                                                    </div>
                                                </div>
                                            </li>


                                            <li>
                                                <h4>Language</h4>
                                                <div className="value">
                                                    <div className="left">English and Chichewa

                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <h4>currency </h4>
                                                <div className="value">
                                                    <div className="left">Malawian Kwacha (MWK)

                                                    </div>
                                                </div>
                                            </li>


                                        </ul>
                                    </Fade>



                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <h3 className='count-main-name'>
                                    Our presence in Malawi 

                                </h3>
                                <div className="order-summary-box list-countries country-desc">
                                    <Fade bottom>
                                        <ul>
                                            <li>

                                                <div className="value">
                                                    <div className="left">
                                                    We started our journey in Malawi in 2013 with a fuel station/ factory. 
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <h4>No of Fuel Station : </h4>
                                                <div className="value">
                                                    <div className="left">67

                                                    </div>
                                                </div>
                                            </li>


                                            <li>
                                                <h4>No of Factories</h4>
                                                <div className="value">
                                                    <div className="left">
                                                        1
                                                    </div>
                                                </div>
                                            </li>



                                        </ul>
                                    </Fade>



                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            </div>


            <Footer />

        </>
    )
}

export default MalawiDescription