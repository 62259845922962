import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Lub2 from '../assets/images/lub2.png';
import Header from './Header';
import Footer from './Footer';

import truck1 from '../assets/images/shop/truck1.jpg'
import truck2 from '../assets/images/shop/truck2.jpg'
import truck3 from '../assets/images/shop/truck3.jpg'
import storage1 from '../assets/images/shop/storage1.jpg'
import storage2 from '../assets/images/shop/storage2.jpg'
import storage3 from '../assets/images/shop/storage3.jpg'
import terminal1 from '../assets/images/shop/terminal1.png'
import terminal2 from '../assets/images/shop/terminal2.png'
import terminal3 from '../assets/images/shop/terminal3.png'
import ProductModel from './ProductModel';

<ProductModel />
function EnergyProduct() {
    const [productName, setProductName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [values, setValues] = useState([25, 75]);

    const handleChange = (e) => {
        setValues(e.target.value.split(','));
    };

    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >
                    {/* <img srcSet={Imgban} alt='Imgban' /> */}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Supply chain and logistics Services</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="">Our Products</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Supply chain and logistics Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">
                <section className="shop-page-one">
                    <div className="container ">
                        <div className="row">

                            <div className="col-12 col-sm-12 col-xl-12 col-lg-12">
                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Trucks</span>

                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="station-card-list">
                                    {/*Start Single Shop Item*/}

                                    <div className="images-fix">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={truck1} alt="" />

                                                </div>

                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Trucks'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="images-fix">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={truck2} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Trucks'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="images-fix">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={truck3} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Trucks'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="shop-page-one supplychain">
                    <div className="shop-page-one_inner product-container">
                        <div className="container">
                            <div className="row  justify-content-center">
                                <div className="col-12 col-sm-12 col-xl-12 col-lg-12">
                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Storage </span>

                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                <div className="station-card-list">
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={storage1} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Storage'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={storage2} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Storage'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={storage3} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Storage'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shop-page-one supplychain">
                    <div className="shop-page-one_inner product-container">
                        <div className="container">
                            <div className="row  justify-content-center">
                                <div className="col-12 col-sm-12 col-xl-12 col-lg-12">
                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Terminals </span>

                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                <div className="station-card-list">
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={terminal1} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Terminals'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={terminal2} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Terminals'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={terminal3} alt="" />

                                                </div>
                                                <div className='title-holder'>
                                                    <Button class="btn-modals" variant="primary" onClick={() => { setModalShow(true); setProductName('Terminals'); }}>
                                                        Contact Us
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            <ProductModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                productName={productName}
            />
        </>
    );
};

export default EnergyProduct;