import React from 'react'
import BlogSidebar from './BlogSidebar';
import Blogdet1 from '../assets/images/blog/blog-3.jpeg';

const OurdescDetails = () => {
    return (

        <>
            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="row text-right-rtl">
                        <div class="col-xl-8 col-lg-7">
                            <div class="blog-details-content height-auto-blog">

                                <div class="single-blog-style1  single-blog-style1--blog-large blog-details wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms" >
                                    <div class="img-holder">
                                        <div class="inner">
                                            <img srcSet={Blogdet1} alt="Blogdet1" className='imgnews-blog' />
                                        </div>
      
                                    </div>
                                    
                                </div>

                            </div>

                        </div>

                        <BlogSidebar />


                    </div>

                </div>
            </section>
        </>

    )
}

export default OurdescDetails