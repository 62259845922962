import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import Aboutstory from '../assets/images/about-story.jpeg'
import Aboutenergy1 from '../assets/images/about/energy-aboutus.jpeg'
import aboutImage from '../assets/images/brand/feedthechild.png'

const CsrNew = () => {
    return (
        <>
            <section>


                <div className="banner-slide-contain feed-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6">
                                <div className="banner-slide-img">
                                    <Fade bottom>
                                        <img src={aboutImage} alt="" />
                                    </Fade>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style1__content">
                                    <Fade bottom>
                                        <div className="sec-title child-contents">

                                            <h2 className="">
                                                Feed The Child
                                            </h2>
                                        </div>
                                    </Fade>

                                    <div className="inner-content">
                                        <Fade bottom>

                                            <div className="text-box">
                                                <p>
                                                    Help us fight the battle against Hunger.                                            </p>
                                                <p>
                                                    At Meru, we are committed to making a positive impact on the lives of children. Through our dedicated 'Feed the Child' initiative, we strive to combat childhood hunger by providing nutritious meals to underprivileged children. Join us in our mission to nourish and empower the next generation.
                                                </p>
                                            </div>
                                            <div className="btns-box">
                                                <Link className="btn-one" to="/Csr">
                                                    <span className="txt">Read More</span>
                                                </Link>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>

            </section>


        </>
    );
}

export default CsrNew;
