import React from 'react'
import Header from './Header';
import Footer from './Footer';
import BlogSidebar from './BlogSidebar';
import Blogdet1 from '../assets/images/blog/blog-3.6f9525447acf0bf62467.jpg';
import Blogd2 from '../assets/images/blog/blog-4.aec6d1ee313686295b92.jpg';
import Blogde3 from '../assets/images/blog/blog-5.e7b94da63ddd6ce69201.jpeg';
import EcoFriendlyBanner from './EcoFriendlyBanner';
const EcofriendlyBlogDetails = () => {
    return (
        <>
            <Header />

            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >

                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>MOUNT MERU GROUP TURNING ECO-FRIENDLY!</h2>


                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="index-2.html">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="index-2.html">Our Blogs</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">MOUNT MERU GROUP TURNING ECO-FRIENDLY!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="row text-right-rtl">
                            <div class="col-xl-8 col-lg-7">
                                <div class="blog-details-content height-auto-blog">

                                    <div class="single-blog-style1  single-blog-style1--blog-large blog-details wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms" >
                                        <div class="img-holder">
                                            <div class="inner">
                                                <img srcSet={Blogdet1} alt="Blogdet1" className='imgnews-blog' />
                                            </div>
                                            <div class="categories-date-box">
                                                <div class="categories-box">
                                                    <h6><span class="flaticon-open-archive"></span> May 2021 - Rwanda</h6>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="text-holder">
                                            <div class="top-text">

                                                <div class="inner-text">
                                                    <p> Mount Meru Group generating electricity from solar panels produces no harmful emissions. Relying on solar power means less toxic emissions from fossil fuels into our air.
                                                    </p> <p>
                                                        With the continuously increasing demand for electric power, the significantly high price of oil, and the growing concern for the environment, many businesses are resorting to alternative sources of energy. Among the renewable energy sources, solar energy is a sustainable choice and one that can be used in various applications. Utilizing electricity from solar power will result in reduced consumption of fuels, thus reducing greenhouse gas emissions and pollution.
                                                    </p>
                                                </div>

                                                <div class="inner">
                                                    <img srcSet={Blogd2} alt="Blogdet1" />
                                                </div>


                                                <div class="inner mt-5">
                                                    <p>Hence with the above objective in mind to help the environment. Mount Meru Group has set up a 212 kWp Rooftop Solar Plant in Kayonza district, Rwanda. This will positively result in an emission offset of 5,636 tons of avoided CO2 over 20 years. This is almost equivalent to the effect if we plant 1500 trees.

                                                    </p>
                                                    <p>
                                                        Also, Mount Meru Group plans to incorporate and turn to Green energy in all its operations in coming few years.  </p>


                                                </div>
                                                <div class="inner mt-5">
                                                    <img srcSet={Blogde3} alt="Blogdet1" />
                                                </div>


                                                <div class="inner mt-5">
                                                    <p>
                                                        The company promises to donate the savings made from Solar energy to the betterment of the country and its society by contributing towards Health care, education, and the creation of more job opportunities. Mount Meru Group feels great to be recognized as a business empire that is not only focused on its bottom line but also creating a greener, cleaner Earth for communities around you and for future generations.
                                                    </p>
                                                    <p>
                                                        Solar energy is here to stay. We encourage such opportunities, for businesses to take advantage of industrial-scale solar energy generation.
                                                    </p>



                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>

                            <BlogSidebar />


                        </div>

                    </div>
                </section>
            </div>

            <Footer />

        </>
    )
}

export default EcofriendlyBlogDetails