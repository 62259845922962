import React from 'react'
import Header from './Header';
import Footer from './Footer';
import LeadershipBaneer from './LeadershipBaneer';
import LeadershipMessage from './LeadershipMessage';
import Leaderphotos from './Leaderphotos';

const Leadership = () => {
    return (
        <>
            <Header />
            <LeadershipBaneer />
            <div className="section-parent">
                <LeadershipMessage />
                <Leaderphotos />
            </div>
            <Footer />

        </>
    )
}

export default Leadership