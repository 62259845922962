import React from 'react'
import Header from './Header';
import Footer from './Footer';
import HistoryBanner from './HistoryBanner';
import Timeline from './Timeline';
// import HistoryTimeline from './HistoryTimeline';
import HistoryGlance from './HistoryGlance';
const History = () => {
    return (
        <>
            <Header />
            <HistoryBanner />
            <div className="section-parent">
                <Timeline />

                <HistoryGlance />
            </div>
            <Footer />
        </>

    )
}

export default History