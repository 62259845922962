import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Distance from '../assets/images/distance.png';
import Distance2 from '../assets/images/location.png';
import Distance3 from '../assets/images/clock.png';
import Distance4 from '../assets/images/customer.png';
import ReactPaginate from 'react-js-pagination';

const MeruStationCard = ({ country, city }) => {
    const [stationData, setStationData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 6;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://meru.growthmetaverse.in/public/api/locationsbycountry/${country}`);
                const filteredData = response.data.filter(station => station.country === country);
                setStationData(filteredData);

                const totalItems = filteredData.length;
                const totalPages = Math.ceil(totalItems / itemsPerPage);

                if (activePage > totalPages) {
                    setActivePage(totalPages);
                }
            } catch (error) {
                console.error('Error fetching station data:', error);
            }
        };

        fetchData();
    }, [country]);

    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = stationData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <section className='Meru-station'>
                <div className='container'>
                    <div className="sec-title text-center">
                        <div className="sec-titlenew sub-title">
                            <h2 className='header-flextion-h2'><span className="border-left"></span>
                                <div className='header-flextion '>
                                    {
                                        country ? (
                                            <>
                                                <span className="one-text"> Mount Meru Stations </span>
                                                <span className="two-text">In {country}</span>
                                            </>
                                        ) : <span className="one-text">Please Select Country</span>
                                    }
                                </div>
                                <span className="border-right"></span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='station-card-grid'>
                        {currentItems.map((data) => {
                            const { id, station_name, ro_address, district, province, google_link } = data;
                            return (
                                <div className='' key={id}>
                                    <div className='card-station-meru'>
                                        <p> Mount Meru</p>
                                        <ul className='inner-list-data'>
                                            <li>
                                                <span>
                                                    <img srcSet={Distance4} alt='Distance' />
                                                </span>
                                                <span>
                                                    {station_name}
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <img srcSet={Distance2} alt='Distance' />
                                                </span>
                                                <span>
                                                    {ro_address}
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <img srcSet={Distance3} alt='Distance' />
                                                </span>
                                                <span>Open 24 Hours</span>
                                            </li>
                                        </ul>
                                        {/* {google_link && (
                                            <ul>
                                                <li className='map-button'>
                                                    <a target="_blank" rel="noopener noreferrer" href={google_link}>Map</a>
                                                </li>
                                            </ul>
                                        )} */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            {stationData.length > itemsPerPage && (
                <div className="pagination-container">
                    <ReactPaginate
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={stationData.length}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => setActivePage(pageNumber)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination"
                    />
                </div>
            )}
        </>
    );
};

export default MeruStationCard;