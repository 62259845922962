import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Swal from "sweetalert2";

import Header from "./Header";
import Footer from "./Footer";

import country1 from "../assets/images/icon/botswana.png";
import country2 from "../assets/images/icon/malawi.png";
import country4 from "../assets/images/icon/mozambique.jpg";
import country5 from "../assets/images/icon/namibia.jpg";
import country6 from "../assets/images/icon/rwanda.png";
import country7 from "../assets/images/icon/tanzania.png";
import country8 from "../assets/images/icon/uganda.png";
import country9 from "../assets/images/icon/zambia.png";
import country10 from "../assets/images/icon/zimbabwe.png";
import country11 from "../assets/images/icon/kenya.jpg";
import country12 from "../assets/images/icon/dubai.png";
import country13 from "../assets/images/icon/india.png";
import country14 from "../assets/images/icon/drv.png";
import country15 from "../assets/images/icon/ivory.png";
import country16 from "../assets/images/icon/gabon.png";
import country17 from "../assets/images/icon/southafrica.png";

const contactBg = {
  backgroungImage: "url(assets/images/pattern/thm-pattern-3.png)",
};

const energyData = [
  {
    country: 'BOTSWANA',
    company: 'Acer Petroleum Botswana (Pty) Ltd',
    address: 'Plot No – 14442, Unit D, PO Box – AD 23 ADC, G-West Industrial, Gaborone, Botswana.',
    email: 'info@mountmerugroup.com',
    image: country1,
  },
  {
    country: "COTE D'IVOIRE",
    company: 'Mount Meru Petroleum',
    address: 'Marcory Zone 4C, 18 BP 675 Abidjan 18, Cote D\'Ivoire.',
    email: 'info@mountmerugroup.com',
    image: country15,
  },
  {
    country: 'DEMOCRATIC REPUBLIC OF CONGO',
    company: 'Mount Meru Petroleum Congo Sarl',
    address: 'Kimbangu Avenue Plot No 26 next to DGM office, Lubumbashi, Democratic Republic of the Congo.',
    phone: '+243 977 118 888',
    email: 'info@mountmerugroup.com',
    image: country14,
  },
  {
    country: 'GABON',
    company: 'Mount Meru Logistics Gabon',
    address: 'P.O. Box 5689, Centre Ville Gabon.',
    phone: '+241 062 81 51 51',
    email: 'info@mountmerugroup.com',
    image: country16,
  },
  {
    country: 'KENYA',
    company: 'Acer Petroleum Ltd Kenya',
    address: 'Unit 6, Semco Industrial Park, Mombasa Road, P.O Box 78443-00507, Nairobi Kenya.',
    email: 'info@mountmerugroup.com',
    image: country11,
  },
  {
    country: 'MALAWI',
    company: 'Mount Meru Petroleum Ltd Malawi',
    address: 'Plot No. 3/041 Mthunthama Drive, P.O Box 2259, Lilongwe, Malawi.',
    phone: '+265 993 367 685',
    email: 'info@mountmerugroup.com',
    image: country2,
  },
  {
    country: 'MOZAMBIQUE',
    company: 'Mount Meru Petroleum Lda Mozambique',
    address: 'Mozambique Rua Augustino Castilho, 3A, 3⁰ Andar, Citade Da Beira, Mozambique.',
    email: 'info@mountmerugroup.com',
    image: country4,
  },
  {
    country: 'NAMIBIA',
    company: 'Acer Logistics Namibia Pty Ltd',
    address: 'P.O.Box 41496, Ausspannplatz, 1st Floor Old Power Station Unit 25, Southern Industry, Windhoek, Namibia.',
    email: 'info@mountmerugroup.com',
    image: country5,
  },
  {
    country: 'RWANDA',
    company: 'Mount Meru Petroleum Rwanda Ltd',
    address: 'First Floor, Mount Meru Kicukiro Station, P.O Box 3996, Kigali, Rwanda.',
    phone: '+250 783 235629',
    email: 'info@mountmerugroup.com',
    image: country6,
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Petroleum Ltd Arusha',
    address: 'Plot No. 77 - Block EE, Ngarenaro, P.O Box 7094 - Arusha, Tanzania.',
    phone: '+255 715 111 666',
    email: 'info@mountmerugroup.com',
    image: country7,
  },
  {
    country: 'UGANDA',
    company: 'Mount Meru Petroleum Uganda Ltd',
    address: 'DTB Centre, Plot 17/19 (12th Floor), Kampala Road, P.O Box 35491, Kampala, Uganda.',
    email: 'info@mountmerugroup.com',
    image: country8,
  },
  {
    country: 'ZAMBIA',
    company: 'Mount Meru Petroleum Ltd Zambia',
    address: 'Plot no.255 Near Meru Roma Filling station Lukanga Road, Lusaka, Zambia.',
    phone: '+260 960 283 229',
    email: 'info@mountmerugroup.com',
    image: country9,
  },
  {
    country: 'ZIMBABWE',
    company: 'Mount Meru Petroleum Zimbabwe (Pvt) Ltd',
    address: '20 Palmer Road Milton Park Harare, Zimbabwe.',
    phone: '+263242711015',
    email: 'info@mountmerugroup.com',
    image: country10,
  },
  {
    country: 'SOUTH AFRICA',
    company: 'Mount Meru South Africa',
    address: '43 FIRST ROAD, HYDE PARK, SANDTON GAUTENG, 2196',
    email: 'info@mountmerugroup.com',
    image: country17,
  },
];

const agriData = [
  {
    country: 'MALAWI',
    company: 'Mount Meru Millers Ltd Malawi',
    address: 'Plot 7, Mgubo Village, Dowa District, Central region, Lilongwe-Malawi.',
    email: 'info@mountmerugroup.com',
    image: country2
  },
  {
    country: 'RWANDA',
    company: 'Mount Meru Soyco Ltd',
    address: 'PPO Box 6515, Kigali, Rwanda.',
    email: 'info@mountmerugroup.com',
    image: country6
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Arusha Ltd',
    address: 'P.O. Box 7094, Njiro Arusha, Tanzania EA.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Ltd - Bunda',
    address: 'P.O. Box 299, Bunda, Tanzania.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Ltd - Kahama',
    address: 'P.O. BOX 833, Kahama-TANZANIA.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Ltd - Lgunga',
    address: 'P.O Box 234, Lgunga, Tabora.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Ltd - SHINYANGA',
    address: 'P.O. BOX 1221, SHINYANGA-TANZANIA.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'TANZANIA',
    company: 'Mount Meru Millers Ltd - Singida',
    address: 'Post Box No. 203, Plot No. 19-32, Block M, Njuki Area, Singida TZ.',
    email: 'info@mountmerugroup.com',
    image: country7
  },
  {
    country: 'UGANDA',
    company: 'Mount Meru Millers Ltd Uganda',
    address: '7th Floor, AHA TOWERS, lourdel road ,Nakasero, Kampala, Uganda.',
    email: 'info@mountmerugroup.com',
    image: country8
  },
  {
    country: 'ZAMBIA',
    company: 'Mount Meru Millers (Z) Ltd',
    address: 'Plot No. 441a, of Subdivision No.57/A, Lukanga Road, Roma P.O.Box 34360, Lusaka, Zambia.',
    email: 'info@mountmerugroup.com',
    image: country9
  },
  {
    country: 'ZIMBABWE',
    company: 'Mount Meru Millers Zimbabwe (Pvt) Ltd',
    address: '20 Palmer Road Milton Park Harare, Zimbabwe.',
    phone: '+263242711015',
    email: 'info@mountmerugroup.com',
    image: country10
  },
  {
    country: 'KENYA',
    company: 'Organic Proteins Limited',
    address: 'P.O Box - 78443 - 00507, off Mombasa road, Kiungani road, Nairobi, Kenya',
    email: 'info@mountmerugroup.com',
    image: country11
  },
  {
    country: 'MOZAMBIQUE',
    company: 'Mount Meru Millers Mozambique, Limitada',
    address: 'Avenida União Africana, nº 200, Luna Shopping Centre, 1º Andar, Cidade da Matola.',
    email: 'info@mountmerugroup.com',
    image: country4,
  },
];

const corpData = [
  {
    country: 'INDIA',
    company: 'Mount Meru India Pvt. Ltd.',
    address: '903, 9th Floor, D Wing, Times Square Phase - D, IT Park, Marol, Andheri -East, Mumbai - 400059, India.',
    phone: '022-40141286',
    email: 'info@mountmerugroup.com',
    image: country13
  },
  {
    country: 'UAE',
    company: 'Group Head Quarters',
    address: 'Office # 608 & 609, Jumeirah Business Centre 1, Cluster - G, JLT, Dubai, UAE.',
    phone: '+971 48747731',
    email: 'info@mountmerugroup.com',
    image: country12
  },
  {
    country: 'UAE',
    company: 'White Horse Commodities FZE',
    address: 'Office # 608 & 609, Jumeirah Business Centre 1, Cluster - G, JLT, Dubai, UAE.',
    address1: 'Trade Business Centre',
    phone: '+971 48747731',
    email: 'info@mountmerugroup.com',
    image: country12
  },
];

const ContactUs = () => {
  const [activeSection, setActiveSection] = useState("energy");
  const [searchQuery, setSearchQuery] = useState("");

  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    subject: "",
    email: "",
    messagebody: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.fullName || !formData.email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in the required fields",
      });
      return;
    }

    const data = new FormData();
    data.append("fullName", formData.fullName);
    data.append("mobileNumber", formData.mobileNumber);
    data.append("subject", formData.subject);
    data.append("email", formData.email);
    data.append("messagebody", formData.messagebody);

    axios
      .post("https://meru.growthmetaverse.in/public/api/contactus", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Message sent successfully",
        });
        setFormData({
          fullName: "",
          mobileNumber: "",
          subject: "",
          email: "",
          messagebody: "",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred. Please try again later.",
        });
      });
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Header />
      <section className="breadcrumb-area style2">
        <div className="breadcrumb-area-bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content">
                <div
                  className="title breadcumbheight blog-single aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                >
                  <h2>Contact us</h2>
                </div>
                <div
                  className="breadcrumb-menu aos-init"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                >
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </li>

                    <li className="active">Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-parent">
        <section className="contact-info-area">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-12 col-sm-12 col-xl-9 col-lg-8">
                <div className="d-flex justify-content-around align-items-center contact-btn contact-mob">
                  <button
                    className={activeSection === "energy" ? "active" : ""}
                    onClick={() => handleButtonClick("energy")}
                  >
                    Energy Division
                  </button>
                  <button
                    className={activeSection === "agri" ? "active" : ""}
                    onClick={() => handleButtonClick("agri")}
                  >
                    Agri Division
                  </button>
                  <button
                    className={activeSection === "corporate" ? "active" : ""}
                    onClick={() => handleButtonClick("corporate")}
                  >
                    Corporate Office
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-xl-3 col-lg-4 ">
                <div className="thm-sidebar-box">
                  <div className="single-sidebar-box">
                    <div className="sidebar-search-box style2">
                      <form className="search-form" action="#">
                        <input
                          placeholder="Search"
                          type="text"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <button type="submit">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {activeSection === "energy" && (
              <div className="station-cards mt-0">
                <div class="sec-title text-center contact-titles">
                  <div class="sec-titlenew sub-titleabout sub-title ">
                    <Fade bottom>
                      <h2 className="">
                        <span class="border-left"> </span>
                        <span class="one-text"> Energy Division </span>
                        <span class="two-text"> </span>

                        <span class="border-right"></span>
                      </h2>
                    </Fade>
                  </div>
                </div>
                <div className="station-card-list station-card-list">
                  {
                    energyData
                      .filter((card) =>
                        (card.country ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.email ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.company ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.phone ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.address ?? "").toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((card, index) => (
                        <div className="contact-red-card" key={index}>
                          <Fade bottom>
                            <div className="card-contact">
                              <div className="contact-box-main">
                                <div className="flag-icons">
                                  <img decoding="async" src={card.image} alt="" />
                                </div>
                                <div className="content-box">
                                  <h4>
                                    {card.country} <br /> {card.company}
                                  </h4>
                                  <p>{card.address}</p>
                                  <a href={`tel:${card.phone}`}>
                                    <span>{card.phone}</span>
                                  </a>
                                  <a href={`mailto:${card.email}`}>
                                    <span>{card.email}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      ))
                  }
                </div>
              </div>
            )}

            {activeSection === "agri" && (
              <div className="station-cards mt-0">
                <div class="sec-title text-center contact-titles">
                  <div class="sec-titlenew sub-titleabout sub-title">
                    <Fade bottom>
                      <h2>
                        <span class="border-left"> </span>
                        <span class="one-text"> Agri Division </span>
                        <span class="two-text"> </span>

                        <span class="border-right"></span>
                      </h2>
                    </Fade>
                  </div>
                </div>
                <div className="station-card-list station-card-list">
                  {
                    agriData
                      .filter((card) =>
                        (card.country ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.email ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.company ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.phone ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.address ?? "").toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((card, index) => (
                        <div className="contact-red-card" key={index}>
                          <Fade bottom>
                            <div className="card-contact">
                              <div className="contact-box-main">
                                <div className="flag-icons">
                                  <img decoding="async" src={card.image} alt="" />
                                </div>
                                <div className="content-box">
                                  <h4>
                                    {card.country} <br /> {card.company}
                                  </h4>
                                  <p>{card.address}</p>
                                  <a href={`tel:${card.phone}`}>
                                    <span>{card.phone}</span>
                                  </a>
                                  <a href={`mailto:${card.email}`}>
                                    <span>{card.email}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      ))
                  }
                </div>
              </div>
            )}

            {activeSection === "corporate" && (
              <div className="station-cards mt-0">
                <div class="sec-title text-center contact-titles">
                  <div class="sec-titlenew sub-titleabout sub-title">
                    <Fade bottom>
                      <h2>
                        <span class="border-left"> </span>
                        <span class="one-text"> Corporate Office </span>
                        <span class="two-text"> </span>

                        <span class="border-right"></span>
                      </h2>
                    </Fade>
                  </div>
                </div>
                <div className="station-card-list station-card-list">
                  {
                    corpData
                      .filter((card) =>
                        (card.country ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.email ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.company ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.phone ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.address ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (card.address1 ?? "").toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((card, index) => (
                        <div className="contact-red-card" key={index}>
                          <Fade bottom>
                            <div className="card-contact">
                              <div className="contact-box-main">
                                <div className="flag-icons">
                                  <img decoding="async" src={card.image} alt="" />
                                </div>
                                <div className="content-box">
                                  <h4>
                                    {card.country} <br /> {card.company}
                                  </h4>
                                  <p>{card.address1}<br />{card.address}</p>
                                  <a href={`tel:${card.phone}`}>
                                    <span>{card.phone}</span>
                                  </a>
                                  <a href={`mailto:${card.email}`}>
                                    <span>{card.email}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      ))
                  }
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="main-contact-form-area">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="contact-style1_form">
                  <div className="top-title">
                    <h2>Drop Your Message</h2>
                    <p>
                      Please feel free to get in touch using the form below.
                      We'd love to hear from you.
                    </p>
                  </div>
                  <div className="contact-form">
                    <form
                      id="contact-form"
                      name="contact_form"
                      className="default-form2"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <div className="input-box">
                            <input
                              type="text"
                              name="fullName"
                              value={formData.fullName}
                              placeholder="Full Name :"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                          <div className="input-box">
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              placeholder="Email :"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <div className="input-box">
                            <input
                              type="text"
                              name="mobileNumber"
                              value={formData.mobileNumber}
                              placeholder="Phone :"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                          <div className="input-box">
                            <input
                              type="text"
                              name="subject"
                              placeholder="Subject :"
                              value={formData.subject}
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 text-center">
                          <div className="input-box two">
                            <textarea
                              name="messagebody"
                              value={formData.messagebody}
                              placeholder="Enter your message..."
                              required
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="button-box">
                            <input
                              id="form_botcheck"
                              name="form_botcheck"
                              className="form-control"
                              type="hidden"
                              value=""
                            />
                            <button
                              className="btn-one"
                              type="submit"
                              data-loading-text="Please wait..."
                            >
                              <span className="txt">Send Message</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
