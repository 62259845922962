import React from 'react'
import Header from './Header';
import Footer from './Footer';
import EcoFriendlyBanner from './EcoFriendlyBanner';
import EncouragingGoodHealthDetails from './EncouragingGoodHealthDetails'
const EncouragingGoodHealth = () => {
    return (
        <>
            <Header />
            <EcoFriendlyBanner />
            <div className="section-parent">
                <EncouragingGoodHealthDetails />
            </div>
            <Footer />

        </>
    )
}

export default EncouragingGoodHealth