import React from 'react'
import Fade from 'react-reveal/Fade';
import Aboutstory45 from '../assets/images/shop/miller2.png'
import Aboutstory46 from '../assets/images/shop/new-pic-17.png';
import Lub1 from '../assets/images/lubri1.png';
import Lub2 from '../assets/images/lub2.png';
import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";
const MountMeruLubricant = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Force1 Lubricants</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Energy</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Force1 Lubricants</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    East Africa's leading lubricant brand, with a strong presence in Zambia, Tanzania, Kenya, and more. Our state-of-the-art manufacturing plant in Lusaka, Zambia, ensures top-quality products. With over 350 retail fuel stations, we provide convenient access to our lubricants. Committed to high performance and value for money, we contribute to the growth of local economies and foster regional partnerships.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Lub1} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Lub2} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Why Choose Us</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Our lubricant distribution network is easily accessible and convenient, ensuring you can get our products whenever and wherever you need them. We offer cost-effective delivery to bulk users with absolute transparency. Our strict quality control measures guarantee superior products with meticulously formulated and tested base oils and additives. Trusted by thousands of retail customers and institutional buyers, we deliver on our high-performance promise. Our extensive network ensures that high-quality lubricants are always within your reach.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>
            </div>



            <Footer />
        </>
    )
}

export default MountMeruLubricant