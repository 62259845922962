import React from 'react'
import Fade from 'react-reveal/Fade';
import Fuel from '../assets/images/fuel-station.png';
import Factories from '../assets/images/smart-factory.png';
import Fleet from '../assets/images/fleet-management.png';
import Emp from '../assets/images/employees.png';

import pattern4 from '../assets/images/pattern/thm-pattern-4.png'

const HomeCounter = () => {
    return (
        <>
            <section className="team-style1-area">
                <div className="pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                <div className="container">
                    <div className="sec-title">
                        <Fade bottom>
                            <div className="sub-title">
                                <h6><span className="border-left"></span>Team Members<span className="border-right"></span></h6>
                            </div>
                            <h2>Meet Our Expert Team</h2>
                        </Fade>
                    </div>

                    <div id="projectFacts" class="sectioncounter">
                        <div class="fullWidth eight columns">
                            <div class="row projectFactsWrap ">
                                <div className='col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3 col-xxl-3 col-counter'>
                                    <div class="item wow fadeInUpBig animated animated visble-count" data-number="12">
                                        <img srcSet={Fuel} alt='Fuel' />
                                        <p id="number1" class="number">300 +</p>
                                        <span></span>
                                        <p>fuel stations</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3 col-xxl-3  col-counter'>

                                    <div class="item wow fadeInUpBig animated animated visble-count" data-number="55">
                                        <img srcSet={Factories} alt='Factories' />
                                        <p id="number2" class="number">10 +</p>
                                        <span></span>
                                        <p>Agri Factories</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3 col-xxl-3  col-counter'>

                                    <div class="item wow fadeInUpBig animated animated visble-count" data-number="359" >
                                    <img srcSet={Fleet} alt='Factories' />
                                        <p id="number3" class="number">900 +</p>
                                        <span></span>
                                        <p>fleet</p>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3 col-xxl-3  col-counter'>

                                    <div class="item wow fadeInUpBig animated animated visble-count" data-number="246" >
                                    <img srcSet={Emp} alt='Factories' />
                                        <p id="number4" class="number">6000 +</p>
                                        <span> </span>
                                        <p>employees</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default HomeCounter