import React from 'react'
import Header from './Header';
import Footer from './Footer';
import PostGraduatesBanner from './PostGraduatesBanner';
import PostGraduatesDetails from './PostGraduatesDetails';
const PostGraduates = () => {
    return (
        <>
            <Header />
            <PostGraduatesBanner />
            <PostGraduatesDetails />
            <Footer />
        </>
    )
}

export default PostGraduates