import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import aboutImage from '../assets/images/about/about-img.jpeg'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import worldCountries from '../assets/world-countries.json';

const africaGeoUrl = worldCountries;

const CoreValues = () => {

    const locations = [
        { name: 'Botswana', coordinates: [22, -20], stations: 1 },
        { name: 'Democratic Republic of the Congo', coordinates: [21.7587, 1], stations: 13 },
        { name: "Côte D'Ivoire", coordinates: [-5.5471, 7.5400], stations: 8 },
        { name: 'Kenya', coordinates: [40, -1], stations: 0 },
        { name: 'Malawi', coordinates: [34.3015, -14], stations: 67 },
        { name: 'Mozambique', coordinates: [34, -22.5], stations: 20 },
        { name: 'Namibia', coordinates: [15, -23], stations: 11 },
        { name: 'Rwanda', coordinates: [29.8739, -4], stations: 23 },
        { name: 'Tanzania', coordinates: [34.8888, -6.3690], stations: 62 },
        { name: 'Uganda', coordinates: [34, 3], stations: 23 },
        { name: 'Zambia', coordinates: [25, -12], stations: 77 },
        { name: 'Zimbabwe', coordinates: [31, -19.0154], stations: 3 },
        { name: 'Gabon ', coordinates: [11, -3], stations: 3 },
        { name: 'Swaziland', coordinates: [32, -26.0154], stations: 3 },
        { name: 'India', coordinates: [73, 19], stations: 1 },
        { name: 'UAE', coordinates: [55, 25], stations: 1 },
        { name: 'South Africa', coordinates: [25, -30], stations: 1 },
    ];

    const renderTooltip = (props, stations) => (
        <Tooltip id="button-tooltip" {...props}>
            Total Stations: {stations}
        </Tooltip>
    );

    return (
        <>

            <section className="about-style1-area value-box">
                <div class="container features-box">
                    <div class="row d-none">

                        <div class="col-xl-6 col-lg-6 col-md-6  wow fadeInUp" data-wow-delay="100ms"
                            data-wow-duration="1500ms">
                            <Fade bottom>
                                <div class="single-features-style1 first-child text-center">
                                    <div class="single-features-style1__bg"
                                    ></div>
                                    <div class="counting-box"></div>
                                    <div class="inner">
                                        <div class="static-content">
                                            <div class="icon">
                                                <span class="flaticon-protection"></span>
                                            </div>
                                            <h3>Our Core values</h3>
                                        </div>
                                        <div class="overlay-content">
                                            <h3>Our Core values</h3>
                                            <p>Our core values are Humility, Integrity, Hard Work, Vegetarianism, Compassion, Family Culture, and Sharing & Giving.</p>

                                        </div>
                                    </div>
                                    <div class="button-box">

                                    </div>
                                </div>
                            </Fade>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6  wow fadeInUp" data-wow-delay="100ms"
                            data-wow-duration="1500ms">
                            <Fade bottom>
                                <div class="single-features-style1 first-child text-center">
                                    <div class="single-features-style1__bg"
                                    ></div>
                                    <div class="counting-box"></div>
                                    <div class="inner">
                                        <div class="static-content">
                                            <div class="icon">
                                                <span class="flaticon-protection"></span>
                                            </div>
                                            <h3>Our Mission</h3>
                                        </div>
                                        <div class="overlay-content">
                                            <h3>Our Mission</h3>
                                            <p>
                                                Our Mission is to be one of the most respectable and successful corporate groups on the African Continent.


                                            </p>
                                        </div>
                                    </div>
                                    <div class="button-box">

                                    </div>
                                </div>
                            </Fade>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6  wow fadeInUp" data-wow-delay="100ms"
                            data-wow-duration="1500ms">
                            <Fade bottom>
                                <div class="single-features-style1 first-child text-center">
                                    <div class="single-features-style1__bg"
                                    ></div>
                                    <div class="counting-box"></div>
                                    <div class="inner">
                                        <div class="static-content">
                                            <div class="icon">
                                                <span class="flaticon-protection"></span>
                                            </div>
                                            <h3>Our Purpose</h3>
                                        </div>
                                        <div class="overlay-content">
                                            <h3>Our Purpose</h3>
                                            <p>
                                                To make a positive impact sustainably in the lives of 100 million people by 2030 and to grow & create a sustainable business without losing entrepreneurship culture.

                                            </p>
                                        </div>
                                    </div>
                                    <div class="button-box">
                                    </div>
                                </div>
                            </Fade>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6  wow fadeInUp" data-wow-delay="100ms"
                            data-wow-duration="1500ms">
                            <Fade bottom>
                                <div class="single-features-style1 first-child text-center">
                                    <div class="single-features-style1__bg"
                                    ></div>
                                    <div class="counting-box"></div>
                                    <div class="inner">
                                        <div class="static-content">
                                            <div class="icon">
                                                <span class="flaticon-protection"></span>
                                            </div>
                                            <h3>Our Vision</h3>
                                        </div>
                                        <div class="overlay-content">
                                            <h3>Our Vision</h3>
                                            <p>
                                                Our vision is to be a leader in our business by delivering quality products and services through continuous innovation and excellence without compromising our core values of trust and integrity.                                        </p>

                                        </div>
                                    </div>
                                    <div class="button-box">

                                    </div>
                                </div>
                            </Fade>
                        </div>



                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row vision-card">
                                <div className="col-lg-6 locations-name" style={{ backgroundColor: 'rgb(187,30,16)' }}>
                                    <ComposableMap projection="geoMercator" projectionConfig={{ scale: 300, center: [54, 11] }}>
                                        <Geographies geography={africaGeoUrl}>
                                            {({ geographies }) =>
                                                geographies.map((geo) => (
                                                    <Geography key={geo.rsmKey} geography={geo} fill="#222222" stroke="#555555" />
                                                ))
                                            }
                                        </Geographies>
                                        {locations.map((location, index) => (
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(props) => renderTooltip(props, location.stations)}
                                            >
                                                <Marker key={index} coordinates={location.coordinates}>
                                                    <g fill="none" stroke="#FF5533" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" transform="translate(-6, -12)">
                                                        <circle cx="6" cy="5" r="1.5" />
                                                        <path d="M6 10.85C8.65 8.5 10 6.5 10 5a4 4 0 1 0-8 0c0 1.5 1.35 3.35 4 5.85z" />
                                                    </g>
                                                    <text
                                                        textAnchor="middle"
                                                        y="7"
                                                        style={{
                                                            fontFamily: 'system-ui',
                                                            fill: '#FFF',
                                                            fontWeight: 'bold',
                                                            fontSize: '8px',
                                                        }}
                                                    >
                                                        {location.name}
                                                    </text>
                                                </Marker>
                                            </OverlayTrigger>
                                        ))}
                                    </ComposableMap>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  our-vission-sect">
                                            <Fade bottom>
                                                <div className="abouts-card">
                                                    <div className="about-icons">

                                                        <i aria-hidden="true" class="fas fa-chevron-circle-right"></i>
                                                    </div>
                                                    <div className="about-contents">
                                                        <div className="about-heads">
                                                            <h3>Our Core values</h3>
                                                            <p>Our core values are Humility, Integrity, Hard Work, Vegetarianism, Compassion, Family Culture, and Sharing & Giving.</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  our-vission-sect">
                                            <Fade bottom>
                                                <div className="abouts-card">
                                                    <div className="about-icons">

                                                        <i aria-hidden="true" class="fas fa-chevron-circle-right"></i>
                                                    </div>
                                                    <div className="about-contents">
                                                        <div className="about-heads">
                                                            <h3>Our Mission</h3>
                                                            <p>
                                                                Our Mission is to be one of the most respectable and successful corporate groups on the African Continent.


                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  our-vission-sect">
                                            <Fade bottom>
                                                <div className="abouts-card">
                                                    <div className="about-icons">

                                                        <i aria-hidden="true" class="fas fa-chevron-circle-right"></i>
                                                    </div>
                                                    <div className="about-contents">
                                                        <div className="about-heads">
                                                            <h3>Our Purpose</h3>
                                                            <p>
                                                                To make a positive impact sustainably in the lives of 100 million people by 2030 and to grow & create a sustainable business without losing entrepreneurship culture.

                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Fade>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  our-vission-sect">
                                            <Fade bottom>
                                                <div className="abouts-card">
                                                    <div className="about-icons">

                                                        <i aria-hidden="true" class="fas fa-chevron-circle-right"></i>
                                                    </div>
                                                    <div className="about-contents">
                                                        <div className="about-heads">
                                                            <h3>Our Vision</h3>
                                                            <p>
                                                                Our vision is to be a leader in our business by delivering quality products and services through continuous innovation and excellence without compromising our core values of trust and integrity.
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </>
    );
}

export default CoreValues;
