import React from 'react'
import Header from './Header';
import Footer from './Footer';
import HowWeWorkBanner from './HowWeWorkBanner';
import HowWeWorkDetails from './HowWeWorkDetails';


const HowWeWork = () => {
    return (
        <>
            <Header />
            <HowWeWorkBanner />
            <HowWeWorkDetails />
            <Footer />
        </>
    )
}

export default HowWeWork