function AdminFooter() {
    return (
        <>
            <footer id="footer" className="footer">
                <div className="copyright">
                    &copy; Copyright <strong><span>MeruAdmin</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Designed by <a href="https://growthmetaverse.in/" target="_blank">GrowthMetaverse</a>
                </div>
            </footer>
        </>
    )
}

export default AdminFooter;