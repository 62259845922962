import React from 'react'
import Fade from 'react-reveal/Fade';

const InternsDetail = () => {
    return (
        <>
      
                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        {/* <span class="one-text">Interns</span> */}
                                        <span class="two-text"> Interns</span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Imagine all possibilities
                                                    </h3>
                                                </div>
                                                <p>
                                                    At Mount Meru Group,  we strive to learn & grow together. This is the right platform for you to put your learning into actual practice and start getting practical experience toward a rewarding career.
                                                </p>
                                                <p>

                                                    It is an opportunity for you to work with the versatile platform of India & globe.
                                                </p>
                                                <p>
                                                    We hire Pan India and if you are looking for a career that gives an opportunity to not just build you; but build a new world with yourself, it's time for you to team with us.
                                                </p>


                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

         
        </>
    )
}

export default InternsDetail