import React from 'react';
import { Link } from "react-router-dom";
import { useState } from "react";
import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import { Form, Button, Modal } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CaseStudy() {
    const [modalShow, setModalShow] = useState(false);

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add CaseStudy
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Enter title"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                placeholder: 'Enter description'
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                        >
                            <option value="">Select status</option>
                            <option value="show">Show</option>
                            <option value="hide">Hide</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button>Submit</Button>
                    <Button onClick={() => setModalShow(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>CaseStudy</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/Dashboard'><a href="#">Dashboard</a></Link></li>
                            <li className="breadcrumb-item active">CaseStudy</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-end py-3">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow(true)}>Add CaseStudy</button>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Raheem Lehner</td>
                                                <td>Dynamic Division Officer</td>
                                                <td>Show</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-secondary btn-sm">Edit</button>
                                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>

            <MyVerticallyCenteredModal
                show={modalShow}
            />

            <AdminFooter />
        </>
    )
}

export default CaseStudy;
