import React from 'react'
import Blogimg from '../assets/images/blog/blog1.jpeg'
import Blogimg1 from '../assets/images/blog/blog2.jpeg';
import Blogimg2 from '../assets/images/blog/blog3.jpeg';
import Blogimg3 from '../assets/images/blog/blog4.jpg';
import Blogimg4 from '../assets/images/blog/blog-7.jpg';
import Blogimg5 from '../assets/images/blog/blog-8.jpg';
import Blogimg6 from '../assets/images/blog/blogd2.jpg';
import Blogimg7 from '../assets/images/blog/blog-10.jpg';
import Blogimg8 from '../assets/images/blog/blog-11.jpg';
import Blogimg9 from '../assets/images/blog/blog150.jpg';
import Blogimg10 from '../assets/images/blog/blog151.jpg';
import Blogimg11 from '../assets/images/blog/blog152.jpg';
import Blogimg12 from '../assets/images/blog/blog153.jpg';
import Blogimg13 from '../assets/images/blog/blog154.jpg';
import Blogimg14 from '../assets/images/blog/blog155.jpg';
import Blogimg15 from '../assets/images/blog/blog156.jpg';
import Blogimg16 from '../assets/images/blog/blog157.jpg';
import Blogimg17 from '../assets/images/blog/blog158.jpg';
import Whatsapp from '../assets/images/whatsapp.png';
import Insta from '../assets/images/instagram.png';
import Twitter from '../assets/images/twitter.png'




import { NavLink } from 'react-router-dom'

const OurBlogCard = () => {

    return (
        <>
            <section className="blog-page blog-page-one">
                <div className="container">
                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title blog-headings">
                            <h2>
                                <span class="border-left">
                                </span>
                                <span class="one-text">
                                    Our Blogs
                                </span>
                                <span class="two-text">

                                </span>
                                <span class="border-right">

                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="station-card-grid-blog">
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg17} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_crickettournament-dubai-synergy-activity-7074266580238442498-jCkT?utm_source=share&utm_medium=member_desktop
' target='_blank'> Meru - Cricket match in Dubai!
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We are excited to share the glimpses of our recent cricket match in Dubai!....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_crickettournament-dubai-synergy-activity-7074266580238442498-jCkT?utm_source=share&utm_medium=member_desktop
' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg4} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_environment-sustainable-kenya-activity-7071414489157410816-oFfE?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru environment sustainable Kenya activity</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We are delighted to share the exciting news that Meru has.....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_environment-sustainable-kenya-activity-7071414489157410816-oFfE?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg5} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_blooddonation-blooddonationcamp-africa-activity-7071066345135648768-iB-u?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru Blood donation camp</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Our Employees Participated Wholeheartedly, Making.....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_blooddonation-blooddonationcamp-africa-activity-7071066345135648768-iB-u?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg15} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>May 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <a href='
https://www.linkedin.com/posts/mount-meru-group_football-event-people-activity-7053610904625496064-6Zj6?utm_source=share&utm_medium=member_desktop
' target='_blank'> Football match held between Mount Meru Malawi team and Gospel In Action team
                                        </a>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Here is the exciting story of a football match having action....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <a href='
https://www.linkedin.com/posts/mount-meru-group_football-event-people-activity-7053610904625496064-6Zj6?utm_source=share&utm_medium=member_desktop
' className="btn-two" target='_blank'>More Details</a>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg16} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>May 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_teamsbuilddreams-earthday2023-inspiration-activity-7056195530476986368-Pf37?utm_source=share&utm_medium=member_desktop
' target='_blank'> Meru - "World Earth Day 2023"
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            On the occasion World Earth Day, our India office ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_teamsbuilddreams-earthday2023-inspiration-activity-7056195530476986368-Pf37?utm_source=share&utm_medium=member_desktop
' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg9} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>May 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_togetherwecan-plantatree-sustainablefuture-activity-7055416861101875200-Rb7U?utm_source=share&utm_medium=member_desktop' target='_blank'> Meru - "World Earth Day"
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Our team at Meru marked this significant occasio ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_togetherwecan-plantatree-sustainablefuture-activity-7055416861101875200-Rb7U?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg13} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Feb 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_football-team-achievements-activity-7031864038510727169-bOzd?utm_source=share&utm_medium=member_desktop' target='_blank'> Team Mount Meru Football Club
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Congratulations to Team Mount Meru Football Club on their recent 3-1 victory ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_football-team-achievements-activity-7031864038510727169-bOzd?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg12} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jan 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_africa-schools-education-activity-7019159023522308096-Poex?utm_source=share&utm_medium=member_desktop' target='_blank'> Addition of 2 schools with Meru
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We feed 100,000 children in Africa every day  ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_africa-schools-education-activity-7019159023522308096-Poex?utm_source=share&utm_medium=member_desktop' target='_blank' className="btn-two" >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg14} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jan 2023</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_runforcause-runforinclusion-mountmeru-activity-7020269390977769472-TFBU?utm_source=share&utm_medium=member_desktop' target='_blank'> Mumbai Marathon 2023
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Mumbai Marathon 2023....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_runforcause-runforinclusion-mountmeru-activity-7020269390977769472-TFBU?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg10} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Dec 2022</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_feedthechild-health-schools-activity-6994159923123138560-C1Ri?utm_source=share&utm_medium=member_desktop' target='_blank'> Meru - "Feed the Child Program"
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We at Meru run "Feed the Child" program promoting  ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_feedthechild-health-schools-activity-6994159923123138560-C1Ri?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg11} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Nov 2022</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_salestarget-achievement-achievers-activity-6985094167404134400-WTl8?utm_source=share&utm_medium=member_desktop' target='_blank'> Team Rwanda on attaining high sales performance
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We sincerely appreciate your efforts and commitment ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_salestarget-achievement-achievers-activity-6985094167404134400-WTl8?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg6} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Oct 2022</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_csr-csractivity-africa-activity-6981113605593141248-mqw9?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru “Feed the Child” initiative</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Our “Feed the Child” Initiative Is A Very Special & Non-Profit Program To.....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_csr-csractivity-africa-activity-6981113605593141248-mqw9?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg8} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Oct 2022</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_risewithmeru-learningplatform-learninganddevelopment-activity-6972414311231045632-o454/?utm_source=share&utm_medium=member_desktop' target='_blank'> Meru - "Rise with Meru"
                                        </NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            We are glad and delighted to launch a formal ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_risewithmeru-learningplatform-learninganddevelopment-activity-6972414311231045632-o454/?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg3} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Sept 2021</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='/MountMeruEdibleOil' target='_blank'>US$20 Million Mount Meru Edible Oil Plant Commissioned</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            Prevents our being able do what we like best every pleasure avoided...
                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='/MountMeruEdibleOil' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg7} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2022</h6>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='https://www.linkedin.com/posts/mount-meru-group_trekking-garbettplateau-matheran-activity-6942011039102181376-Z6CN?utm_source=share&utm_medium=member_desktop' target='_blank'>Meru Hiking Adventures</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            From Discovering The Teamwork Beyond Work To Finding ....                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='https://www.linkedin.com/posts/mount-meru-group_trekking-garbettplateau-matheran-activity-6942011039102181376-Z6CN?utm_source=share&utm_medium=member_desktop' className="btn-two" target='_blank' >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Jun 2021</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <NavLink to='/EncouragingGoodHealth' target='_blank'>Encouraging Good Health Among Youth</NavLink>

                                    </h3>
                                    <div className="text">
                                        <p>
                                            The statistics claim, around 3 million children die due to undernutrition...
                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>

                                        <div className="left">
                                            <NavLink to='/EncouragingGoodHealth' className="btn-two" target='_blank' >More Details</NavLink>

                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                            <div className='share-list-app'>
                                                <NavLink to="/#">
                                                    <img srcSet={Whatsapp} alt="Whatsapp" />
                                                </NavLink>
                                                <NavLink to="/#">
                                                    <img srcSet={Insta} alt="Insta" />
                                                </NavLink>
                                                <NavLink to="/#">
                                                    <img srcSet={Twitter} alt="Twitter" />
                                                </NavLink>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                       

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg1} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>May 2021</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <a href='/EcofriendlyBlogsDetails'>
                                            Meru Turning Eco-Friendly!
                                        </a>
                                    </h3>
                                    <div className="text">
                                        <p>
                                            Meru generating electricity from ...
                                        </p>
                                    </div>

                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='/EcofriendlyBlogsDetails' target='_blank' className="btn-two" >More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="single-blog-style1 wow fadeInUp animated fadeups" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <div className="inner">
                                        <img srcSet={Blogimg2} alt="Blogimg" />
                                    </div>
                                    <div className="categories-date-box">
                                        <div className="categories-box">
                                            <h6><span className="flaticon-open-archive"></span>Mar 2021</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <a href="./DeepCondolense">
                                            Our Deepest Condolences On The Passing Away Of Dr.John Magufuli                                       </a>
                                    </h3>
                                    <div className="text">
                                        <p>
                                            ZIMBABWE’s friendly investment climate...
                                        </p>
                                    </div>
                                    <div className="bottom-box">
                                        <div
                                            className="pattern-bg"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/images/pattern/thm-pattern-7.png)",
                                            }}
                                        ></div>
                                        <div className="left">
                                            <NavLink to='/DeepCondolense' className="btn-two" target='_blank'>More Details</NavLink>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li><a href="#"><span className="fa fa-share-alt share"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default OurBlogCard