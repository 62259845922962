import React from 'react'
import IndustryProfessionalBanner from './IndustryProfessionalBanner';
import IndustryProfessionalDetails from './IndustryProfessionalDetails';
import Header from './Header';
import Footer from './Footer';
const IndustryProfessional = () => {
    return (
        <>
            <Header />
            <IndustryProfessionalBanner />
            <IndustryProfessionalDetails />
            <Footer />
        </>
    )
}

export default IndustryProfessional