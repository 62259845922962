import React from 'react'
import Header from './Header';
import Footer from './Footer';
import OilplantBanner from './OilplantBanner';
import Oilplantdes from './Oilplantdes'
const OilPlants = () => {
  return (
    <>
    <Header />
    <OilplantBanner />
    <Oilplantdes/>
       <Footer />

</>
  )
}

export default OilPlants