import React from 'react'
import Fade from 'react-reveal/Fade';
const IndustryProfessionalDetails = () => {
    return (
        <>
            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <Fade bottom>
                                <h2><span class="border-left"></span>
                                    <span class="one-text"> Industry</span>
                                    <span class="two-text"> Professional</span>
                                    <span class="border-right"></span>
                                </h2>
                            </Fade>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Build a new legacy with conviction
                                                </h3>
                                            </div>
                                            <p>
                                            Over last four decades we have built our presence in different industry segments globally and continuously strive to expand our footprints in different geographies.                                            </p>
                                            <p>

                                            So, if you are amongst one who aspires to be a change that will leave an imprint in the industry, come and join the team of 6000+ employees to energegize lives of millions around us.
                                                                                </p>
   
                                          
                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default IndustryProfessionalDetails