import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import Header from './Header';
import Footer from './Footer';

import Imgban from "../assets/images/breadcrumb/breadcrumb-1.jpg";
import shopImage1 from '../assets/images/shop/shop-1.jpg';
import sidebarImage1 from '../assets/images/sidebar/sidebar-product-thumb-1.jpg';
import sidebarImage2 from '../assets/images/sidebar/sidebar-product-thumb-2.jpg';
import sidebarImage3 from '../assets/images/sidebar/sidebar-product-thumb-3.jpg';

function Product() {
    const [values, setValues] = useState([25, 75]);

    const handleChange = (e) => {
        setValues(e.target.value.split(','));
    };

    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >
                    {/* <img srcSet={Imgban} alt='Imgban' /> */}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Products</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Products</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="shop-page-one">
                <div className="shop-page-one_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 order-box-2">
                                <div className="thm-sidebar-box">
                                    <div className="single-sidebar-box">
                                        <div className="sidebar-search-box style2">
                                            <form className="search-form" action="#">
                                                <input placeholder="Search" type="text" />
                                                <button type="submit">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="single-sidebar-box">
                                        <div className="title">
                                            <h3>Categories</h3>
                                        </div>
                                        <ul className="sidebar-categories-box-style2">
                                            <li>
                                                <a href="#">Energy Product</a>
                                            </li>
                                            <li>
                                                <a href="#">Agri Product</a>
                                            </li>

                                        </ul>
                                    </div>


                                </div>
                            </div>

                            <div className="col-xl-9 col-lg-8 order-box-1">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="shop-page-top-info_inner">
                                            <div className="left-box">
                                                <p>Showing 1–9 of 16 results</p>
                                            </div>
                                            <div className="right-box">
                                                <div className="text">
                                                    <p>Sort by:</p>
                                                </div>
                                                <div className="select-box">
                                                    <select className="wide nice-select">
                                                        <option data-display="popularity">Popularity</option>
                                                        <option value="1">120</option>
                                                        <option value="2">150</option>
                                                        <option value="3">200</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/*Start Single Shop Item*/}
                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                        <div className="single-shop-item">
                                            <div className="single-shop-item_inner">
                                                <div className="img-holder">
                                                    <img src={shopImage1} alt="" />
                                                    <div className="overlay">
                                                        <ul>
                                                            <li>
                                                                <NavLink to='/ProductDetails'><i className="fa fa-shopping-basket" aria-hidden="true"></i></NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to='/Product'><i className="fa fa-heart" aria-hidden="true"></i></NavLink>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <h3>
                                                        <NavLink to='/ProductDetails'>Single Roof Gray</NavLink>
                                                    </h3>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                                        </ul>
                                                    </div>
                                                    <h6>$23.99</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End Single Shop Item*/}
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <ul className="styled-pagination text-center clearfix">
                                            <li className="arrow prev">
                                                <a href="#"><span className="fa fa-angle-left left"></span></a>
                                            </li>
                                            <li className="active"><a href="#">01</a></li>
                                            <li><a href="#">02</a></li>
                                            <li className="arrow next">
                                                <a href="#"><span className="fa fa-angle-right right"></span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Product;