import React from 'react'
import Fade from 'react-reveal/Fade';
import Leaderimg from '../assets/images/blog/author.jpg'
import core1 from '../assets/images/team/team-1.jpeg'
import core2 from '../assets/images/team/team-2.jpeg'
import core3 from '../assets/images/team/team-3.jpeg'
import core4 from '../assets/images/team/team-5.png'
import core5 from '../assets/images/team/team-6.png'



const Leaderphotos = () => {
    return (
        <>
            <section class="blog-pagenew blog-page-one">
                <div class="container">

                    <div class="row text-right-rtl">
                        <div class="col-xl-12 col-lg-12">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew blog-details-pagenew-first">

                                        <div class="inner-box d-flex">
                                            <div class="img-box-leader">

                                                <p></p>
                                                <p>Arvind Mittal</p>
                                                <p>
                                                    President (Agri/Strategy/Finance)
                                                </p>
                                            </div>
                                            <div class="textleader">

                                                <p>Arvind Mittal is a Managing Director of Meru. Over the last 20 years, he has overseen an Africa wide growth strategy that has built the business from a single Miller to 12 countries operations. Arvind is an innovator & risk taker. He has a proven track record of project execution in a time-bound manner. Born in India and grew up in Tanzania, he is married with 3 sons and is currently based in Dubai. His University education is from Sheffield (U.K) in Accounting. He is a graduate of the Harvard Business School.</p>

                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </Fade>

                        </div>

                        <div class="col-xl-12 col-lg-12">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew">
                                        <div class="inner-box d-flex">
                                            <div class="img-box-leader">
                                                <p></p>
                                                <p>Atul Mittal
                                                </p>
                                                <p>
                                                    President (Energy/Logistics/Projects)
                                                </p>
                                            </div>
                                            <div class="textleader">

                                                <p>Born in Tanzania, Atul is an engineer from the University of Manchester. He has been working since 2000 with corporate experience at the top echelon of the organisational structure, Handling the overall Group Strategy and setting up new territories. He firmly believes in core values, is a connoisseur of strategy thinking, a hard taskmaster and a great mentor. Over the years, he has built the Energy Sector in importing, storing, trading, and transporting Motor Vehicle Fuel & Liquid Petroleum Gas. In addition, has also grown the Agribusiness vertical exponentially, with several manufacturing plants in soft oils, logistics, and construction across Africa. Atul likes to add value and upscale his skills regularly, he is currently also engaged with Harvard Business School.</p>

                                            </div>
                                        </div>
                                    </div>










                                </div>
                            </Fade>
                        </div>


                    </div>
                    <div class="row text-right-rtl">
                        <div class="sec-title text-center mt-5">
                            <div class="sec-titlenew sub-titleabout sub-title">
                                <Fade bottom>
                                    <h2>
                                        <span class="border-left"></span>

                                        <span class="one-text">Core Team  </span>
                                        <span class="two-text"></span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew">
                                        <div class="inner-box d-flex">
                                            <div class="img-box-leader">
                                                <img srcSet={core1} alt="Leaderimg" />
                                                <p>Sarvesh Jain</p>
                                                <p>Board of Director and Cluster Vice President - Energy</p>

                                            </div>
                                            <div class="textleader">

                                                <p>Sarvesh is a certified Electronic and Communication Engineer. He has over 25 years of experience into managing profit centres and has been successfully running Acer Petroleum Ltd, a petroleum trading company based in Tanzania. He is currently building petrol stations in Tanzania with the latest designs and automated services, which would be valuable additions to the group. Sarvesh has been very instrumental in setting up the storage facilities for our energy businesses in Tanzania for an efficient trading model.</p>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Fade>


                        </div>

                        <div class="col-xl-12 col-lg-12 d-none">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew">
                                        <div class="inner-box d-flex">
                                            <div class="img-box-leader">
                                                <img srcSet={core2} alt="Leaderimg" />
                                                <p>Satyavrat Katti
                                                </p>
                                                <p>Group Vice President</p>

                                            </div>
                                            <div class="textleader">
                                                <p>

                                                    Satya, as we call him is a qualified professional from ICFAI, India with over 20 years of International experience into Business Finance, Analytics and Strategy. He manages the internal and external Financial affairs of the group globally, based at the head office. Satya has worked with reputable International banks and financial institutions in the past. He is also responsible for analysing new investments, projects and risks into all the businesses that Mount Meru is currently associated with.</p>

                                            </div>
                                        </div>
                                    </div>










                                </div>
                            </Fade>

                        </div>

                        <div class="col-xl-12 col-lg-12 d-none">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew mb-0">
                                        <div class="inner-box d-flex">
                                            <div class="img-box-leader">
                                                <img srcSet={core3} alt="Leaderimg" />
                                                <p>Geetraj Singh
                                                </p>
                                                <p>Group Vice President</p>

                                            </div>
                                            <div class="textleader">
                                                <p>


                                                    Dr. Geetraj Singh holds a PhD. in Business & Mind Psychology from University of Oxford. He is a certified Business psychologist & a certified Master Coach Trainer for MBTI (Myers Briggs Type Indicator), which is a study of psychological behaviour.

                                                </p>
                                                <p>

                                                    As a part of the Core Team, Dr. Geetraj assists into scaling up the current systems, automation of processes and digitizing the organization. He is now responsible for implementing Technology driven solutions within MMG & leads the shared services across the group. Geetraj has worked with financial institutions, pharmaceuticals, retail chain and various business sectors in his past.                                            </p>
                                                <p>


                                                </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Fade>

                        </div>


                        {/* <div class="col-xl-12 col-lg-12">
                            <Fade bottom>
                                <div class="blog-details-content">



                                    <div class="blog-details-pagenew">
                                        <div class="inner-box d-flex align-items-center">
                                            <div class="img-box-leader">
                                                <img srcSet={core3} alt="Leaderimg" />
                                                <p>Miss Anagha
                                                </p>
                                                <p>Group Head HR</p>

                                            </div>
                                            <div class="textleader">
                                                <p>

                                                    Miss Anagha has an extensive experience in HR and training. She has, in the past, worked with consumer goods, trading and manufacturing businesses, making her a dynamic people’s person.

                                                </p>



                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Fade>

                        </div> */}
                    </div>
                </div>
            </section>


        </>
    )
}

export default Leaderphotos