import React from 'react'
import Fade from 'react-reveal/Fade';
import Header from './Header';
import Footer from './Footer';
import country9 from "../assets/images/icon/zambia.png";
const contactBg = {
    backgroungImage: 'url(assets/images/pattern/thm-pattern-3.png)'
}
const StationNewData = () => {
    return (
        <>
            <Header />
            <section className="breadcrumb-area style2">
                <div className="breadcrumb-area-bg" >

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Zambia</h2>


                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Countries</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Zambia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">
                <section className="contact-info-area">
                    <div className="container">
                        <div className="row">
                            <div className="sta-cards">
                                <div className="country-specific-img">
                                    <div className="speci-name">
                                        <h4>
                                            Zambia
                                        </h4>
                                    </div>
                                    <img src={country9} alt="" srcset="" />
                                </div>
                                <div className="dist-names">
                                    <h5>
                                        Mwaiseni Mukwaiz
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12  col-xl-6 col-lg-6">
                                <Fade bottom>
                                    <h3 className='count-main-name'>
                                        About Zombia
                                    </h3>
                                    <div className="single-data">

                                        <div className="count-data">
                                            <p>
                                                <b>Capital :</b>Lusaka
                                            </p>

                                            <p>
                                                <b>Area :</b> 752,614 square kilometers (290,586 squares miles)
                                            </p>
                                            <p>
                                                <b>Language :</b> There are over 73 dialects spoken in zambia,but
                                                the official language is english
                                            </p>
                                            <p>
                                                <b>currency :</b> The Zambian currency is the Kwacha (ZMK)

                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div className="col-12 col-sm-12  col-xl-6 col-lg-6">
                                <Fade bottom>
                                    <h3 className='count-main-name'>
                                        Our presence in Zambia
                                    </h3>
                                    <div className="single-data">

                                        <div className="count-data">
                                            <p>
                                                We started our journey in Zambia in Year with the fuel station/factory
                                            </p>

                                            <p>
                                                <b>No of Fuel Station :</b>
                                            </p>
                                            <p>
                                                <b>No of Factories :</b>
                                            </p>
                                            <p>
                                                <b>Local Contact Information :</b>

                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>


                    </div>

                </section>
            </div>


            <Footer />

        </>
    )
}

export default StationNewData;
