import React from 'react';
import Slider from 'react-slick';

import pattern4 from '../assets/images/pattern/thm-pattern-4.png'
import pattern5 from '../assets/images/pattern/thm-pattern-5.png'
import testimonial5 from '../assets/images/testimonial/testimonial-5.jpg'
import testimonial1 from '../assets/images/testimonial/testimonial-1.png'
import testimonial2 from '../assets/images/testimonial/testimonial-2.jpg'
import testimonial3 from '../assets/images/testimonial/testimonial-3.jpg'
import testimonial4 from '../assets/images/testimonial/testimonial-4.jpg'

import clientreview from '../assets/images/testimonial/Ruth.jpeg'


const HomeTestimonials = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,

        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 20000,
        lazyLoad: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 993,
                settings: {
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <>
            <section className="testimonials-style1-area">
                <div className="container">
                    <div className="sec-title center text-center">
                        <div className="sub-title">
                        </div>
                        <h2>Our People</h2>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="testimonials-style1__content wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <Slider className='theme_carousel testimonials-carousel_1 owl-dot-style1 owl-theme' {...settings}>
                                    {/* Start Single Testimonials Style1 */}
                                    <div className="single-testimonials-style1">
                                        <div className="img-holder">
                                            <div className="top-pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                                            <div className="bottom-pattern-bg" style={{ backgroundImage: `url(${pattern5})` }}></div>
                                            <div className="img-box">
                                                <img src={testimonial1} alt="" />
                                            </div>
                                            <div className="review-box">
                                                <ul>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="flaticon-quote-3"></span>
                                                </div>
                                                <div className="name">
                                                    <h3>Shadreck Banda </h3>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <p>"I joined to Meru family under Mount Meru Millers, Zambia. My joining date is a special day as it coincides with my birthday. Mount Meru has become part of my life as there is participatory management and its strong belief in family and integrity"</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Testimonials Style1 */}

                                    {/* Start Single Testimonials Style1 */}
                                    <div className="single-testimonials-style1">
                                        <div className="img-holder">
                                            <div className="top-pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                                            <div className="bottom-pattern-bg" style={{ backgroundImage: `url(${pattern5})` }}></div>
                                            <div className="img-box">
                                                <img src={clientreview} alt="" />
                                            </div>
                                            <div className="review-box">
                                                <ul>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="flaticon-quote-3"></span>
                                                </div>
                                                <div className="name">
                                                    <h3>Ruth Ntakakuya</h3>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <p>
                                                    Emanating from the year 2013, I have built a rich experience profile
                                                    under Mount Meru Petroleum Zambia Limited where I started as a retail
                                                    assistant and gained customer service experience and then grew to
                                                    establish my potential in the banking and treasury department which I
                                                    joined in 2019.                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Testimonials Style1 */}

                                    {/* Start Single Testimonials Style1 */}
                                    <div className="single-testimonials-style1">
                                        <div className="img-holder">
                                            <div className="top-pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                                            <div className="bottom-pattern-bg" style={{ backgroundImage: `url(${pattern5})` }}></div>
                                            <div className="img-box">
                                                <img src={testimonial3} alt="" />
                                            </div>
                                            <div className="review-box">
                                                <ul>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="flaticon-quote-3"></span>
                                                </div>
                                                <div className="name">
                                                    <h3>Imma Were</h3>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <p>"The variety of responsibilities keeps me engaged and continuously learning, ensuring that no two days are the same. The dynamic nature of our work keeps me on my toes and encourages me to constantly adapt and grow" </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Testimonials Style1 */}

                                    {/* Start Single Testimonials Style1 */}
                                    <div className="single-testimonials-style1">
                                        <div className="img-holder">
                                            <div className="top-pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                                            <div className="bottom-pattern-bg" style={{ backgroundImage: `url(${pattern5})` }}></div>
                                            <div className="img-box">
                                                <img src={testimonial4} alt="" />
                                            </div>
                                            <div className="review-box">
                                                <ul>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="flaticon-quote-3"></span>
                                                </div>
                                                <div className="name">
                                                    <h3>Kevin</h3>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <p>
                                                    "I am grateful for the opportunities,experiences, and invaluable connections I have gained during my time in the IT Department at Mount Meru. It is an honour to be a part of this exceptional team contributing to the company's technological advancements and overall success"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Testimonials Style1 */}

                                    {/* Start Single Testimonials Style1 */}
                                    <div className="single-testimonials-style1">
                                        <div className="img-holder">
                                            <div className="top-pattern-bg" style={{ backgroundImage: `url(${pattern4})` }}></div>
                                            <div className="bottom-pattern-bg" style={{ backgroundImage: `url(${pattern5})` }}></div>
                                            <div className="img-box">
                                                <img src={testimonial5} alt="" />
                                            </div>
                                            <div className="review-box">
                                                <ul>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                    <li><i className="fa fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="flaticon-quote-3"></span>
                                                </div>
                                                <div className="name">
                                                    <h3>Cyren Placido </h3>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <p>"I joined in Mount Meru last 2018, One aspect that truly stood out for me was the organization's strong commitment to family values and the high level of respect extended to everyone, Additionally, the support provided to staff for their family needs is truly commendable.
                                                    This aspect of company culture made me feel valued and supported, both professionally and personally. It's a testament to Mount Meru's exceptional workplace environment."</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Testimonials Style1 */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeTestimonials;
