import React from 'react'
import Fade from 'react-reveal/Fade';
const HistoryGlance = () => {
    return (
        <>
            <section class="choose-style2-area about-page pd120-0-90">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <h2 className='header-flextion-h2'><span class="border-left"></span>
                                <div className='header-flextion '>
                                    <span class="one-text"> Meru at Glance</span>
                                    
                                </div>
                                <span class="border-right"></span>
                            </h2>
                        </div>
                    </div>

                    <div class="station-card-list">

                        <div class="">

                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner ">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-globe"></i></span></div>
                                    {/* <div class="counting-box">01</div> */}
                                    <div class="text">
                                        <p>Established in 1979, MMG is a leading player in downstream energy & edible oil value chain across Central, Eastern & Southern Africa.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-business-time"></i></span></div>
                                    <div class="text">
                                        <p>Operates 3 business verticals viz. Energy, Agri and Logistics.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-building"></i></span></div>
                                    <div class="text">
                                        <p>Headquartered in UAE, present in 15+ African countries & Business Support Units in India.</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-gas-pump"></i></span></div>
                                    <div class="text">
                                        <p>Energy vertical operates <strong>300+</strong> ‘Meru’ branded fuel stations and downstream supply chains.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-oil-well"></i></span></div>
                                    <div class="text">
                                        <p>Agri Vertical operates in over 10 edible oil manufacturing and sells the best-refined products under many well-known brands.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-truck-field"></i></span></div>
                                    <div class="text">
                                        <p>900 Meru branded fleet & integrated supply chain bring efficiency to Energy and Agri business.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-sharp fa-solid fa-bowl-food"></i></span></div>
                                    <div class="text">
                                        <p>Meru is blessed to provide more than 100,000 nutritious meals daily for children under the “Feed the Child” Project.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 ">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-solid fa-ranking-star"></i></span></div>
                                    <div class="text">
                                        <p>Among the top 10 players in all major countries in Agri and Energy divisions.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="single-choose-box mar-b-30 ">
                                <div class="inner">
                                    <div class="icon iconnew"><span><i class="fa-sharp fa-solid fa-users"></i></span></div>
                                    <div class="text">
                                        <p>A Family of 6000+ employees with strong culture and lateral career growth.  </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-xl-4 col-lg-6 col-md-6">
                            <div class="single-choose-box mar-b-30 mb-5">
                                <div class="inner">
                                   <div class="icon iconnew"><span class="flaticon-diploma"></span></div>
                                    <div class="text">
                                        <p>Except to obtain some advantage it but who has any right to find.</p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>

                </div>
            </section>
        </>
    )
}

export default HistoryGlance