import React from 'react'
import Header from './Header';
import Footer from './Footer';
import WhatWeOfferBanner from './WhatWeOfferBanner';
import WhatWeOfferDetails from './WhatWeOfferDetails';
const WhatWeOffer = () => {
    return (
        <>
            <Header />
            <WhatWeOfferBanner />
            <WhatWeOfferDetails />
            <Footer />
        </>
    )
}

export default WhatWeOffer