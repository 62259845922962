import React from 'react'
import BlogSidebar from './BlogSidebar';
import Blogdet1 from '../assets/images/blog/blogdet1.jpg';
import Blogdet2 from '../assets/images/blog/blogd2.jpg';

const OurdescDetails = () => {
    return (

        <>
            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="row text-right-rtl">
                        <div class="col-xl-8 col-lg-7">
                            <div class="blog-details-content height-auto-blog">

                                <div class="single-blog-style1  single-blog-style1--blog-large blog-details wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms" >
                                    <div class="img-holder">
                                        <div class="inner">
                                            <img srcSet={Blogdet1} alt="Blogdet1" className='imgnews-blog' />
                                        </div>
                                        <div class="categories-date-box">
                                            <div class="categories-box">
                                                <h6><span class="flaticon-open-archive"></span> June 2021 - Uncategorized</h6>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="text-holder">
                                        <div class="top-text">

                                            <div class="inner-text">
                                                <p> The statistics claim, around 3 million children die due to undernutrition every year.Proper nutrition to children in their early years, helps them to remain healthy and develop to their full potential.
                                                </p>
                                                 
                                            </div>

                                            <div class="inner">
                                                <img srcSet={Blogdet2} alt="Blogdet1" />
                                            </div>


                                            <div class="inner mt-5">
                                                <p>Feed the Child” was introduced by Mount Meru Group with a clear vision to fight against hunger & undernutrition in Children.

                                                </p>
                                                <p>
                                                Education being a very important factor for growth and development of every country, the group hence decided to identify public and government schools closer to Mount Meru establishments where they first started, Feed the Child program.</p>
                                             
                                             <p>
                                             Mount Meru Group believes that good nutritional food is a right of every individual and with the same philosophy, it started cooking & distributing free meals to schools who needed aid. This not only encouraged good health for the children but also improved attendance and participation of more students enrolling to these schools, which indirectly promotes education.
                                             </p>

                                             <p>
                                             The employees of Mount Meru Group happily volunteer for this noble cause till date and have helped us to fight against hunger and undernutrition to a great extent.
                                             </p>

                                             <p>
                                             The program is fully funded by Mount Meru Group of companies & runs successfully in more than 7 African countries feeding more than 105,000 children every day. The group has the vision to feed 1 million children every day in the coming 10 years.


                                             </p>

                                            </div>
                                            
                                        </div>


                                    </div>
                                </div>

                            </div>

                        </div>

                        <BlogSidebar />


                    </div>

                </div>
            </section>
        </>

    )
}

export default OurdescDetails