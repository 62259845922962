import React from 'react'
import Fade from 'react-reveal/Fade';
import nfr from '../assets/images/csr-img/nfr.jpg'
import Aboutstory8 from '../assets/images/meru-solar-new.jpeg'


import Header from './Header';
import Footer from './Footer';
const SolarEnergy = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Solar Energy</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Solar Energy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">

                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">


                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory8} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6">
                                <div className="about-style2__content">

                                    <Fade bottom>
                                        <div className="sec-title child-contents pb-2">

                                            <h2>
                                                Solar Energy
                                            </h2>
                                        </div>
                                    </Fade>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    At Mount Meru Group, we recognize the undeniable potential of Africa, a
                                                    continent that brims with untapped energy and relentless progress. We
                                                    are strategically positioned to harness the abundant sunlight that
                                                    bathes our land, converting it into sustainable, renewable power for key
                                                    industries that drive our economy forward.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>


                                    <div className="sub-solar-headings mt-4">
                                        <h4>
                                            Solar-Powered Petrol Stations
                                        </h4>
                                        <p>
                                            As global consciousness shifts towards environmental conservation, we've
                                            embarked on a journey to reshape the way petrol stations operate across
                                            Africa. By integrating advanced solar energy systems, we empower these
                                            hubs of transportation with the ability to function efficiently, reduce
                                            carbon footprints, and secure long-term energy cost savings. Our
                                            initiatives not only promote environmental stewardship but also offer a
                                            tangible value proposition for petrol station operators.
                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <div className="sub-solar-headings mt-4">
                                    <h4>
                                        Energizing Agriculture
                                    </h4>
                                    <p>
                                        Agriculture is the lifeblood of Africa, and we are deeply committed to
                                        supporting this vital sector. Our state-of-the-art solar installations
                                        cater to agri-factories, ensuring they have a consistent and reliable
                                        power source. By tapping into clean solar energy, these factories can
                                        optimize their operations, increase profitability, and contribute to a
                                        greener and more sustainable agricultural landscape.
                                    </p>
                                </div>

                                <div className="sub-solar-headings mt-4">
                                    <h4>
                                        A Commitment to Excellence
                                    </h4>
                                    <p>
                                        In our pursuit of growth and increased profitability, our focus is
                                        unyielding – deliver exceptional solar solutions that cater to
                                        Africa's unique needs. Our team is passionate, result-oriented, and
                                        driven by a work ethic that is second to none. We employ meticulous
                                        financial planning, agile crisis management, and robust supply chain
                                        mechanisms to ensure that our projects are executed with precision. Our
                                        forte lies in blending the art of people management with the science of
                                        solar energy, always maintaining an approach that is polite yet firm,
                                        ensuring we get things done.
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>
                </section>
            </div >

            <Footer />
        </>
    )
}

export default SolarEnergy;