import React from 'react'
import Header from './Header';
import Footer from './Footer';
import CareerJobBanner from './CareerJobBanner';
import CareerSearch from './CareerSearch';
import CareerJobDescription from './CareerJobDescription';
const Careerjob = () => {
    return (
        <>
            <Header />
        <CareerJobBanner />
        <CareerSearch />
       <CareerJobDescription />
            <Footer />
        </>
    )
}

export default Careerjob