import React from 'react'
import Blogd2 from '../assets/images/MeruWhite-02-3.c357bcbf3276d1228e21.png';
import Pdf1 from '../assets/images/direct-download.png';

const EmailerContact = () => {
  return (
   <>
   
   <div className='apply-form-table' style={{ padding: "90px" }}>


<table style={{  border: "1px solid rgb(28 28 39 / 20%)", borderCollapse: "collapse", width: "600", margin: '0 auto' }}>
    <tr>
        <th colspan="2" style={{  border: "1px solid rgb(28 28 39 / 20%)", borderCollapse: "collapse", background: "#1c1c27" }}>
            <p style={{ margin: '0px', height: '100px', borderRight: '70px solid transparent', borderTop: '100px solid var(--thm-base)', position: 'relative', width: '35%' }}>
                <img srcSet={Blogd2} style={{ height: '45px', position: 'absolute', bottom: '24px', left: '22px' }} />
            </p>
        </th>

    </tr>
    <tr>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 32px', borderCollapse: "collapse", fontWeight: 'bold', color: 'black', width: '30%' }}> Full Name :</td>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 44px', borderCollapse: "collapse", width: '70%', color: 'black' }}>Khushboo Bharati</td>
    </tr>
    <tr>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 32px', borderCollapse: "collapse", fontWeight: 'bold', color: 'black', width: '30%' }}> Mobile Name :</td>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 44px', borderCollapse: "collapse", width: '70%', color: 'black' }} >8806234772</td>
    </tr>
    <tr>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 32px', borderCollapse: "collapse", fontWeight: 'bold', color: 'black', width: '30%' }}> Email:</td>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 44px', borderCollapse: "collapse", width: '70%', color: 'black' }} >khushbharati2426@gmail.com</td>
    </tr>
    <tr>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 32px', borderCollapse: "collapse", fontWeight: 'bold', color: 'black', width: '30%' }}>Subject :</td>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 44px', borderCollapse: "collapse", width: '70%', color: 'black' }} >service</td>
    </tr>
    <tr>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 32px', borderCollapse: "collapse", fontWeight: 'bold', color: 'black', width: '30%' }}>Message :</td>
        <td style={{  border: "1px solid rgb(28 28 39 / 20%)", padding: '20px 44px', borderCollapse: "collapse", width: '70%', color: 'black' }}> Please feel free to get in touch using the form below. We'd love to hear for you.</td>
    </tr>

</table>

</div>
   </>
  )
}

export default EmailerContact