import React from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import Aboutstory from "../assets/images/about-story.jpeg";
import Aboutstory1 from "../assets/images/about-img/about1.jpg";
import Aboutstory2 from "../assets/images/about-img/about2.jpg";
import Aboutstory3 from "../assets/images/about-img/about3.jpg";
import Aboutstory4 from "../assets/images/about-img/about4.jpg";
import Aboutstory5 from "../assets/images/about-img/about5.jpg";
import Aboutstory6 from "../assets/images/about-img/about6.jpg";
import Aboutstory7 from "../assets/images/about-img/about7.jpg";
import Aboutstory8 from "../assets/images/about-img/about8.jpg";
import Aboutnew1 from "../assets/images/about/new-img.jpeg";
import Aboutnew2 from "../assets/images/about/new-img2.jpeg";
import Aboutnew3 from "../assets/images/about/new-img3.jpeg";
import Aboutnew4 from "../assets/images/about/new-img4.jpeg";

import OverviewBanner from "./OverviewBanner";
import OverviewAboutImage from "./OverviewAbout";
import { Slide } from "react-reveal";

const OverviewAbout = () => {
  const aboutusSlider = {
    fade: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="about-style2-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-style2__content">
                <div class="sec-title text-center">
                  <div class="sec-titlenew sub-titleabout sub-title">
                    <Fade bottom>
                      <h2>
                        <span class="border-left"></span>
                        <span class="one-text">About Us </span>
                        <span class="two-text"></span>
                        <span class="border-right"></span>
                      </h2>
                    </Fade>
                  </div>
                </div>

                <div className="inner-content">
                  <Fade bottom>
                    <div className="text">
                      <p>
                        Mr Tarsem Chand and Mrs Kanta Devi Mittal, founders of
                        Mount Meru group, started journey in 1979, driven by a
                        relentless commitment to innovation and creating a
                        lasting impact. As a distinguished business conglomerate
                        headquartered in Dubai (UAE), we have established a
                        formidable presence across Africa. Our operations span
                        the Downstream Petroleum Business, Manufacturing of
                        Edible Oils, LPG, Lubricants, Storage and Logistics, and
                        Infrastructure (Real Estate).{" "}
                      </p>
                      <p className="mt-3">
                        With over 40 years of experience and a footprint in more
                        than 15 countries, we have cultivated an invaluable
                        wealth of knowledge, unparalleled expertise, and a
                        profound understanding of diverse markets. Our
                        remarkable growth is attributed to the dedicated efforts
                        of our diversified workforce, which exceeds 6000 strong.
                        We recognize our employees as the pillars of our
                        organization, propelling our transformation from a
                        humble trading company to a prominent enterprise in
                        Sub-Saharan Africa.{" "}
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <Fade bottom>
                <div className="about-style2__image-box">
                  <div className="img-box2 img-box2-no-anim">
                    <Slider {...aboutusSlider}>
                      <div className="inner">
                        <img srcSet={Aboutnew1} alt="Aboutstory" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutnew2} alt="Aboutstory" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutnew3} alt="Aboutstory" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutnew4} alt="Aboutstory" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory} alt="Aboutstory" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory1} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory2} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory3} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory4} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory5} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory6} alt="Aboutnew" />
                      </div>

                      <div className="inner">
                        <img srcSet={Aboutstory7} alt="Aboutnew" />
                      </div>
                      <div className="inner">
                        <img srcSet={Aboutstory8} alt="Aboutnew" />
                      </div>
                    </Slider>
                  </div>

                  <div className="icon-box">
                    <span className="flaticon-house-with-wooden-roof"></span>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-xl-12">
              <div className="inner-content margin-mobile-text">
                <Fade bottom>
                  <div className="text">
                    <p>
                      Meru is deeply committed to fostering growth and enhancing
                      the well-being of communities. We embrace environmentally
                      friendly business solutions, aligning our practices with
                      sustainable development. As we embark on this journey, we
                      aim to ignite progress, empower communities, and create a
                      future that resonates with prosperity and harmony.
                    </p>
                    <p className="m-0">
                      As we continue to forge ahead, our unwavering dedication
                      to excellence, coupled with our passion for empowering
                      progress, propels us to transform lives and create a
                      brighter future for all.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewAbout;
