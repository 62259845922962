import React from 'react';
import Fade from 'react-reveal/Fade';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import Header from './Header';
import Footer from './Footer';

const africaGeoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/africa.json";

const contactBg = {
    backgroundImage: 'url(assets/images/pattern/thm-pattern-3.png)',
};

const StaionDetails = () => {

    const locations = [
        { name: 'Botswana', coordinates: [22, -20], stations: 1 },
        { name: 'Democratic Republic of the Congo', coordinates: [21.7587, -0], stations: 13 },
        { name: 'Democratic Republic of the Congo Kinshasa', coordinates: [15.2663, -4.4419], stations: 0 },
        { name: 'Ivory Coast', coordinates: [-5.5471, 7.5400], stations: 8 },
        { name: 'Kenya', coordinates: [40, -1], stations: 0 },
        { name: 'Malawi', coordinates: [34.3015, -14], stations: 67 },
        { name: 'Mozambique', coordinates: [34, -24.5], stations: 20 },
        { name: 'Namibia', coordinates: [15, -23], stations: 11 },
        { name: 'Rwanda', coordinates: [29.8739, -1.9403], stations: 23 },
        { name: 'Tanzania', coordinates: [34.8888, -6.3690], stations: 62 },
        { name: 'Uganda', coordinates: [31, 3], stations: 23 },
        { name: 'Zambia', coordinates: [25, -12], stations: 77 },
        { name: 'Zimbabwe', coordinates: [31, -19.0154], stations: 3 },
    ];

    return (
        <>
            <Header />
            <section className="breadcrumb-area style2">
                <div className="breadcrumb-area-bg" >

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Our Stations</h2>


                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Countries</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Our Stations</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-info-area">
                <div className="container">
                  
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ backgroundColor: 'rgb(187,30,16)' }}>
                            <ComposableMap projection="geoMercator" projectionConfig={{ scale: 400, center: [15, 0] }}>
                                <ZoomableGroup>
                                    <Geographies geography={africaGeoUrl}>
                                        {({ geographies }) =>
                                            geographies.map((geo) => (
                                                <Geography key={geo.rsmKey} geography={geo} fill="#222222" stroke="#555555" />
                                            ))
                                        }
                                    </Geographies>
                                    {locations.map((location, index) => (
                                        <Marker key={index} coordinates={location.coordinates}>
                                            <g fill="none" stroke="#FF5533" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" transform="translate(-6, -12)">
                                                <circle cx="6" cy="5" r="1.5" />
                                                <path d="M6 10.85C8.65 8.5 10 6.5 10 5a4 4 0 1 0-8 0c0 1.5 1.35 3.35 4 5.85z" />
                                            </g>
                                            <text
                                                textAnchor="middle"
                                                y="10"
                                                style={{
                                                    fontFamily: 'system-ui',
                                                    fill: '#FFF',
                                                    fontWeight: 'bold',
                                                    fontSize: '9px',
                                                }}
                                            >
                                                {location.name}
                                            </text>
                                            <text
                                                textAnchor="middle"
                                                y="20"
                                                style={{
                                                    fontFamily: 'system-ui',
                                                    fill: '#FFF',
                                                    fontWeight: 'bold',
                                                    fontSize: '8px',
                                                }}
                                            >
                                                Total Stations: {location.stations}
                                            </text>
                                        </Marker>
                                    ))}
                                </ZoomableGroup>
                            </ComposableMap>
                            {/* <div className="order-summary-box">
                                <ul>
                                    <li>
                                        <h4>"Station Code"</h4>
                                        <div className="value">
                                            <div className="left">ZP11</div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Station Name</h4>
                                        <div className="value">
                                            <div className="left">Emmasdale
                                            </div>
                                        </div>
                                    </li>


                                    <li>
                                        <h4>Cluster Name</h4>
                                        <div className="value">
                                            <div className="left">Natasha
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>RH Name</h4>
                                        <div className="value">
                                            <div className="left">Dhruv

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Territory</h4>
                                        <div className="value">
                                            <div className="left">Lusaka_4</div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>District</h4>
                                        <div className="value">
                                            <div className="left">LUSAKA

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Provience</h4>
                                        <div className="value">
                                            <div className="left">LUSAKA
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>OTP Date

                                        </h4>
                                        <div className="value">
                                            <div className="left">03/03/11

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>RO Type</h4>
                                        <div className="value">
                                            <div className="left">DOCO</div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>ROM Name

                                        </h4>
                                        <div className="value">
                                            <div className="left">Maiwase Tembo

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>"No. Of Staff



                                        </h4>
                                        <div className="value">
                                            <div className="left">15


                                            </div>
                                        </div>
                                    </li>
                                </ul>



                            </div> */}
                        </div>

                    </div>

                </div>

            </section>

         
            <Footer />

        </>
    )
}

export default StaionDetails;