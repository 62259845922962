import React, { useState, useEffect } from 'react';
import Careermodal from './Careermodal';
import axios from 'axios';
import Swal from 'sweetalert2';
import point from '../assets/images/point.png'

const Footer = () => {
    const [uniqueId, setUniqueId] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [postal, setPostal] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [IPv4, setIPv4] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [count, setCount] = useState(0);
    const [isVoted, setIsVoted] = useState(false);

    useEffect(() => {
        fetchData();
        fetchVotingCount();
        const storedUniqueId = localStorage.getItem('uniqueId');
        if (!storedUniqueId) {
            const generatedId = generateUniqueId();
            localStorage.setItem('uniqueId', generatedId);
            setUniqueId(generatedId);
        } else {
            setUniqueId(storedUniqueId);
        }
    }, []);

    const generateUniqueId = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    };

    const handleVote = async () => {
        if (!isVoted) {
            try {
                const requestData = {
                    country_code: countryCode || 'NA',
                    country_name: countryName || 'NA',
                    postal: postal || 'NA',
                    state: state || 'NA',
                    city: city || 'NA',
                    IPv4: `${IPv4}-${uniqueId}`,
                    latitude: latitude || 'NA',
                    longitude: longitude || 'NA',
                };

                const response = await axios.post(
                    'https://meru.growthmetaverse.in/public/api/voting',
                    requestData
                );

                const countResponse = await axios.get(
                    'https://meru.growthmetaverse.in/public/api/votingcount'
                );
                const latestCount = countResponse.data.data;
                setCount(latestCount);
                setIsVoted(true);
                Swal.fire('Success', 'Your Pledge has been recorded!', 'success');
            } catch (error) {
                console.error(error);
                Swal.fire('Error', 'You have already Pledged!', 'error');
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            const data = response.data;
            const {
                country_code,
                country_name,
                postal,
                state,
                city,
                IPv4,
                latitude,
                longitude,
            } = data;
            setCountryCode(country_code);
            setCountryName(country_name);
            setPostal(postal);
            setState(state);
            setCity(city);
            setIPv4(IPv4);
            setLatitude(latitude);
            setLongitude(longitude);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchVotingCount = async () => {
        try {
            const countResponse = await axios.get(
                'https://meru.growthmetaverse.in/public/api/votingcount'
            );
            const latestCount = countResponse.data.data;
            setCount(latestCount);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {/* <Careermodal /> */}
            <footer className="footer-area">


                {/* Start Footer */}
                <div className="footer">
                    <div className="container">
                        <div className="row text-right-rtl">

                            {/* Start single footer widget */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 wow animated fadeInUp" data-wow-delay="0.1s">
                                <div className="single-footer-widget marbtm50">
                                    <div className="title">
                                        <h3>About Service</h3>
                                    </div>
                                    <div className="our-company-info">
                                        <div className="text-box">
                                            <p>Meru is a business conglomerate in Africa, headquartered in Dubai, UAE, with operations in the Downstream Petroleum Business, Manufacturing of Edible Oils, LPG, Lubricants, Infrastructure (Real Estate), and Logistics.</p>
                                        </div>
                                        <div className='foot-btn-contain'>
                                            <button className='btn-foot' onClick={handleVote} disabled={isVoted}>
                                                <span className="txt">
                                                    {isVoted ? `Pledged (${count})` : `Pledge to Plant 1 Tree Every Year(${count})`}
                                                </span>
                                            </button>
                                            <img src={point} alt="point" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End single footer widget */}

                            {/* Start single footer widget */}
                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 wow animated fadeInUp" data-wow-delay="0.3s">
                                <div className="single-footer-widget marbtm50 footer-widget-contact-info">
                                    <div className="title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <div className="footer-widget-links">
                                        <ul className="pull-left left left-cards">
                                            <h6>Business</h6>
                                            <li><a href="/AboutEnergy">Energy </a></li>
                                            <li><a href="/AboutAgri">Agri</a></li>
                                            <li><a href="/Logistics">Supply chain, Logistics and Hospitality</a></li>
                                            <li><a href="/InfrastructureDesign">Engineering, Procurement, and Construction</a></li>
                                        </ul>
                                        <ul className="pull-left marleft-60 right-cards">
                                            <h6>Our Products</h6>
                                            <li><a href="/EnergyProduct">Energy </a></li>
                                            <li><a href="/AgriProduct">Agri</a></li>
                                            <li><a href="/SupplyChain">Supply Chain and Logistics </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End single footer widget */}

                            {/* Start single footer widget */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow animated fadeInUp" data-wow-delay="0.5s">
                                <div className="single-footer-widget">
                                    <div className="title">
                                        <h3>Make Contact</h3>
                                    </div>
                                    <div className="footer-widget-contact-info">
                                        <ul>
                                            <li>
                                                <h6>General Queries</h6>
                                                <p>Phone: <a href="tel:123456789">+971 48747731</a></p>
                                                <p>Email: <a href="mailto:info@mountmerugroup.com">info@mountmerugroup.com</a></p>
                                            </li>
                                            <li>
                                                <h6>Office Location</h6>
                                                <p>
                                                    Office # 608 & 609, Jumeirah Business <br /> Centre 1, Cluster - G, JLT, Dubai, UAE.</p>
                                            </li>
                                        </ul>
                                        <div className="footer-social-link">
                                            <ul className="clearfix">

                                                <li><a href="https://www.linkedin.com/company/mount-meru-group/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End single footer widget */}


                        </div>
                    </div>
                </div>
                {/* End Footer */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="bottom-inner">
                            <div className="copyright">
                                <p>&copy; 2023 Meru.All Rights Reserved By <a href="https://growthmetaverse.in/">Growth Metaverse</a></p>
                            </div>
                            <div className="footer-menu">
                                <ul className="footer-nav">
                                    <li><a href="/MMG-term-condition.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a></li>
                                    <li><a href="/MMG_Data_Privacy%20Policy_2024.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
}

export default Footer;
