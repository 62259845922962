import React from 'react'
import Fade from 'react-reveal/Fade';
import Header from './Header';
import Footer from './Footer';

const contactBg = {
    backgroungImage: 'url(assets/images/pattern/thm-pattern-3.png)'
}
const ZambiaStationList = () => {
    return (
        <>
            <Header />
            <section className="breadcrumb-area style2">
                <div className="breadcrumb-area-bg" >

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Zambia Station List</h2>


                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Countries</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Zambia Station List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-info-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                            <div className="row">

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>  <div className="single-contact-info-box">

                                        <div className="text-holder">
                                            <div className="pattern-bg"
                                            >

                                            </div>
                                            <div className="top">
                                                <h3> Station
                                                    Code  :ZP11</h3>

                                            </div>
                                            <p><b>Station Name: </b>Emmasdale
                                            </p>
                                            <p><b>District: </b>LUSAKA
                                            </p>
                                            <div className="country-more">
                                                <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                            </div>
                                        </div>

                                    </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP12
                                                    </h3>

                                                </div>
                                                <p><b>Station Name: </b>SOS

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Sos"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP13
                                                    </h3>

                                                </div>
                                                <p><b>Station Name: </b>Lumumba

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Lumumba"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>


                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg">

                                                </div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP14</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mumbwa

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Mumbwa"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP15</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mungwi

                                                </p>
                                                <p><b>District: </b>CHILANGA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Mungwi"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP16</h3>

                                                </div>
                                                <p><b>Station Name: </b>Farmers

                                                </p>
                                                <p><b>District: </b>SIBIIYUNZI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Farmers"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP17</h3>

                                                </div>
                                                <p><b>Station Name: </b>Libala

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Libala"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP18</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kafue Road

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-KafueRoad"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP19</h3>

                                                </div>
                                                <p><b>Station Name: </b>Makeni

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Makeni"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP20</h3>

                                                </div>
                                                <p><b>Station Name: </b>Lilayi

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription-Lilayi"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP21</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chilanga

                                                </p>
                                                <p><b>District: </b>CHILANGA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP22</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kafue Town

                                                </p>
                                                <p><b>District: </b>KAFUE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP23</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kasangula

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP24</h3>

                                                </div>
                                                <p><b>Station Name: </b>Airport

                                                </p>
                                                <p><b>District: </b>CHONGWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP25</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chongwe

                                                </p>
                                                <p><b>District: </b>CHONGWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP26</h3>

                                                </div>
                                                <p><b>Station Name: </b>Nyimba

                                                </p>
                                                <p><b>District: </b>NYIMBA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP27</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chipata

                                                </p>
                                                <p><b>District: </b>CHIPATA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>


                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP28</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chibombo



                                                </p>
                                                <p><b>District: </b>KABWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP29</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kabwe

                                                </p>
                                                <p><b>District: </b>CHIBOMBO

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP30</h3>

                                                </div>
                                                <p><b>Station Name: </b>Ndola

                                                </p>
                                                <p><b>District: </b>NDOLA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP31</h3>

                                                </div>
                                                <p><b>Station Name: </b>Freedom

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP32</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chimwemwe

                                                </p>
                                                <p><b>District: </b>KITWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP33</h3>

                                                </div>
                                                <p><b>Station Name: </b>Garneton

                                                </p>
                                                <p><b>District: </b>KITWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP34</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chambishi

                                                </p>
                                                <p><b>District: </b>KITWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP35</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mufulira

                                                </p>
                                                <p><b>District: </b>MUFULIRA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP36</h3>

                                                </div>
                                                <p><b>Station Name: </b>Solwezi

                                                </p>
                                                <p><b>District: </b>SOLWEZI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP37</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mpika

                                                </p>
                                                <p><b>District: </b>MPIKA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP38</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kawambwa

                                                </p>
                                                <p><b>District: </b>KAWAMBWA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP39</h3>

                                                </div>
                                                <p><b>Station Name: </b>Choma

                                                </p>
                                                <p><b>District: </b>CHOMA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP40</h3>

                                                </div>
                                                <p><b>Station Name: </b>Namwala

                                                </p>
                                                <p><b>District: </b>NAMWALA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP41</h3>

                                                </div>
                                                <p><b>Station Name: </b>LivingStone

                                                </p>
                                                <p><b>District: </b>LIVINGSTONE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP44</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mukushi Fc

                                                </p>
                                                <p><b>District: </b>KAPIRI MPOSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP45</h3>

                                                </div>
                                                <p><b>Station Name: </b>Katuba

                                                </p>
                                                <p><b>District: </b>CHISAMBA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP46</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kapiri

                                                </p>
                                                <p><b>District: </b>KAPIRI MPOSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP47</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mkushi Town

                                                </p>
                                                <p><b>District: </b>MKUSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP48</h3>

                                                </div>
                                                <p><b>Station Name: </b>Roma

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP50</h3>

                                                </div>
                                                <p><b>Station Name: </b>LivingStone 2

                                                </p>
                                                <p><b>District: </b>LIVINGSTONE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP51</h3>

                                                </div>
                                                <p><b>Station Name: </b>Solwezi 2

                                                </p>
                                                <p><b>District: </b>SOLWEZI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP52</h3>

                                                </div>
                                                <p><b>Station Name: </b>Serenje

                                                </p>
                                                <p><b>District: </b>SERENJE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP53</h3>

                                                </div>
                                                <p><b>Station Name: </b>Luanshya

                                                </p>
                                                <p><b>District: </b>LUANSHYA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP54</h3>

                                                </div>
                                                <p><b>Station Name: </b>Leopard Hills

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP55</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kasama

                                                </p>
                                                <p><b>District: </b>KASAMA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP56</h3>

                                                </div>
                                                <p><b>Station Name: </b>Itezhi Itezhi

                                                </p>
                                                <p><b>District: </b>ITEZHI TEZHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP57</h3>

                                                </div>
                                                <p><b>Station Name: </b>MUNALI

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP58</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chingola

                                                </p>
                                                <p><b>District: </b>CHINGOLA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP59</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kaoma

                                                </p>
                                                <p><b>District: </b>KAOMA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP60</h3>

                                                </div>
                                                <p><b>Station Name: </b>KASEMPA

                                                </p>
                                                <p><b>District: </b>KABOMPO

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP61</h3>

                                                </div>
                                                <p><b>Station Name: </b>Sinazenze

                                                </p>
                                                <p><b>District: </b>SINAZONGWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP62</h3>

                                                </div>
                                                <p><b>Station Name: </b>CHIRUNDU

                                                </p>
                                                <p><b>District: </b>KAFUE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP63</h3>

                                                </div>
                                                <p><b>Station Name: </b>KALULISHI

                                                </p>
                                                <p><b>District: </b>KALULUSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP64</h3>

                                                </div>
                                                <p><b>Station Name: </b>NDOLA 2

                                                </p>
                                                <p><b>District: </b>NDOLA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP65</h3>

                                                </div>
                                                <p><b>Station Name: </b>SOLWEZI-3

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP66</h3>

                                                </div>
                                                <p><b>Station Name: </b>LOS ANGELOS

                                                </p>
                                                <p><b>District: </b>SOLWEZI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP67</h3>

                                                </div>
                                                <p><b>Station Name: </b>MPONGWE

                                                </p>
                                                <p><b>District: </b>MPONGWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP68</h3>

                                                </div>
                                                <p><b>Station Name: </b>LUMWANA

                                                </p>
                                                <p><b>District: </b>MWINILUNGA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP69</h3>

                                                </div>
                                                <p><b>Station Name: </b>Rufunsa

                                                </p>
                                                <p><b>District: </b>CHONGWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP70</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mongu

                                                </p>
                                                <p><b>District: </b>MONGU

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP71</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kamwala

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP72</h3>

                                                </div>
                                                <p><b>Station Name: </b>Monze

                                                </p>
                                                <p><b>District: </b>MONZE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP73</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kapiri 2

                                                </p>
                                                <p><b>District: </b>KAPIRI MPOSHI


                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP74</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kapiri 3

                                                </p>
                                                <p><b>District: </b>KAPIRI MPOSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP75</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mufumbwe


                                                </p>
                                                <p><b>District: </b>MUFUMBWE

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP76</h3>

                                                </div>
                                                <p><b>Station Name: </b>Long Acres

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP77</h3>

                                                </div>
                                                <p><b>Station Name: </b>Ring Road

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP78</h3>

                                                </div>
                                                <p><b>Station Name: </b>BURMA ROAD

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP79</h3>

                                                </div>
                                                <p><b>Station Name: </b>New Mungwi

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP80</h3>

                                                </div>
                                                <p><b>Station Name: </b>Katima Mulilo

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP81</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kalulushi-2

                                                </p>
                                                <p><b>District: </b>KALULUSHI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP82</h3>

                                                </div>
                                                <p><b>Station Name: </b>Great East Road

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP83</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mumbwa Town

                                                </p>
                                                <p><b>District: </b>MUMBWA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP84</h3>

                                                </div>
                                                <p><b>Station Name: </b>Nkeyama

                                                </p>
                                                <p><b>District: </b>KAOMA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP85</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chipata-2

                                                </p>
                                                <p><b>District: </b>CHIPATA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP86</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kalumbila Truck Park

                                                </p>

                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP89</h3>

                                                </div>
                                                <p><b>Station Name: </b>Mansa

                                                </p>
                                                <p><b>District: </b>MANSA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>

                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP90</h3>

                                                </div>
                                                <p><b>Station Name: </b>Siavonga

                                                </p>
                                                <p><b>District: </b>SIAVONGA

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP91</h3>

                                                </div>
                                                <p><b>Station Name: </b>Commonwealth

                                                </p>
                                                <p><b>District: </b>LUSAKA
                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP92</h3>

                                                </div>
                                                <p><b>Station Name: </b>Chinasli

                                                </p>
                                                <p><b>District: </b>CHINSALI

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP93</h3>

                                                </div>
                                                <p><b>Station Name: </b>Katate

                                                </p>
                                                <p><b>District: </b>Katete

                                                </p>
                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">

                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP94</h3>

                                                </div>
                                                <p><b>Station Name: </b>Kasama 2

                                                </p>

                                                <div className="country-more">
                                                    <a class="btn-one" href="/StationDescription"><span class="txt">Read More</span></a>
                                                </div>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>





                            </div>
                        </div>
                    </div>


                </div>

            </section>
            <Footer />



        </>
    )
}

export default ZambiaStationList;