import React from 'react'
import Header from './Header';
import Footer from './Footer';
const Applyimg = {
  backgroundImage: "url(&quot;images/background/cross-line2.png&quot;)",
  backgroundPosition: "-20607px 0px;"
}
const FindDescription = () => {
  return (
 
        <>
          <Header />
          <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >

                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Job Openings</h2>

                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="index-2.html">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="index-2.html">About</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Sr.Associate Site Merchandiser, Amazon Private Brands</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <div className="searchcard-section1">
            <div className="container-flex">
              <div className="row height156">
                <div className="maindescriptions-flex">
                  <h4>
                    Sr.Associate Site Merchandiser, Amazon Private Brands
                  </h4>
                  <p>Job ID: 2375729 | ASSPL - Karnataka
                  </p>
                </div>
                <div className="button-description">
                <a class="btn-one" href="#"><span class="txt">Apply Now</span></a>
                </div>
              </div>
            </div>
          </div>
          <div className="all-description-carreer">
            <div className="container">
              <div className="row">
                <div className="col-9">
                  <div className="paragraph-career">
                    <h3>
                      DESCRIPTION
                    </h3>
                    <p>Are you customer obsessed, flexible, smart and analytical, strategic yet execution focused, hungry and passionate about e-commerce, experienced, and entrepreneurial leader with a strong work ethic? Want to work in one of the fastest growing and most innovative businesses at Amazon? Interested in working in a high impact role? If yes, this opportunity will appeal to you. Come join our site merchandising team and help define the course of the Amazon business.
                    </p>
                    <p>
                      Amazon's vision is to enable customers globally to find, discover, and buy anything online.
                      We are looking for a smart, results-oriented Site Merchandiser to grow our business. The successful candidate will be responsible for planning and executing best in class site and email merchandising strategies. This role is based in Bangalore.
                    </p>
                    <p>
                      The Sr. Associate Site Merchandiser will ensure that accurate, comprehensive and high-quality product information is available on all products we carry, and that customer expectations are set and met to a high standard. He or she will write and schedule content on emails, home pages, category pages, and pages across the site, compile and analyze traffic and other web metrics, and report on performance to our management teams. He or she will propose, plan and execute new features, stores, and products with minimal oversight, partnering with business and technical teams to achieve project goals. This person will set goals that all functions may be involved in achieving and will liaise with tech teams and global category teams to execute. He or she will help to drive feature launches and site improvements to completion by coordinating with project managers, developers, the marketing team, designers, and other editors in similar roles across the business.
                    </p>
                    <p>
                      Technical requirements include HTML, XML and Excel, plus the ability to learn our in-house tools quickly. The successful candidate will be detail-oriented, capable of handling multiple projects at once, able to take direction, and work well with multiple teams. In addition to having first-class written and verbal communication skills in English, this Site Merchandiser will be able to prioritize to meet deadlines in a fast-paced environment. The ideal candidate will be innovative and internally motivated and have strong interest in and some experience with online merchandising.
                    </p>
                    <h4 className='desc-careerh4'>
                      Ongoing responsibilities include:
    
                    </h4>
    
                    <ul className='ul-career'>
                      <li>
    
                        Coordinating and managing resources to provide thorough and authoritative detail page content
                      </li>
                      <li>
                        Broadly managing the quality of our catalogue and the resulting customer experience; managing related projects such as improving product data quality, attribute and image collection, browse cleanups, photo studio usage, etc.
                      </li>
                      <li>
                        Tracking and analyzing customer needs based on customer behaviors including searching, browsing and purchasing, identifying problems and opportunities and responding accordingly, engaging the cross-functional team where necessary
                      </li>
                      <li>
                        Planning and executing the site merchandising calendar. This includes email programs, new product launches, cross-category placements, developing and executing Amazon site-wide events, browse tree design and search results
                      </li>
                      <li>
                        Develop merchandising for large storewide promotions, and work closely with buyers and marketing to schedule promotions
                      </li>
                      <li>
                        Create emails, category pages, brand stores, campaigns, and develop and manage free search improvement projects
                      </li>
                      <li>
                        Synthesize and analyze relevant content metrics
                      </li>
                      <li>
                        Propose new traffic-driving content strategies
                      </li>
                      <li>
                        Regularly develop, test and analyze new merchandising tactics that sell products across all categories and increase conversion rates, documenting the results and sharing best practices with the team
                      </li>
                    </ul>
                    <h4 className='desc-careerh4'>
                      The Site Merchandiser must be able to think and act both strategically and tactically. The ideal candidate will demonstrate the following:
                    </h4>
                    <ul className='ul-career'>
                      <li>Experience designing and executing online customer experiences</li>
                      <li>Strong communication skills; experience in coordinating teams and communicating to senior management</li>
                      <li>Strong bias for action and ability to prioritize</li>
                      <li>Ability to use hard data and metrics to back up feature and customer segment recommendations</li>
                      <li>Aptitude for organization, flexibility and producing results in a fast paced environment</li>
                      <li>Capability to meet our technical requirements, which include HTML, XML and Excel, plus the ability to learn our in-house tools quickly</li>
                      <li>Post Graduate preferred</li>
                    </ul>
    
    
                    <p>
                      The ideal candidate will be innovative, team oriented, an effective communicator, have a desire to participate in change and appreciate a dynamic environment with rapidly changing priorities. We are seeking someone with demonstrated history of successful project ownership and using customer data to identify and prioritize opportunities. An understanding of, and passion for, e-commerce is highly desired.
                    </p>
                    <p>
                      We are looking for a smart, results-oriented Site Merchandiser to grow our business. The successful candidate will be responsible for planning and executing best in class site and email merchandising strategies. This role is based in Bangalore.
                    </p>
                    <p>
                      The Site Merchandiser will ensure that accurate, comprehensive and high-quality product information is available on all products we carry, and that customer expectations are set and met to a high standard. He or she will write and schedule content on emails, home pages, category pages, and pages across the site, compile and analyze traffic and other web metrics, and report on performance to our management teams. He or she will propose, plan and execute new features, stores, and products with minimal oversight, partnering with business and technical teams to achieve project goals. This person will set goals that all functions may be involved in achieving and will liaise with tech teams and global category teams to execute. He or she will help to drive feature launches and site improvements to completion by coordinating with project managers, developers, the marketing team, designers, and other editors in similar roles across the business.
                    </p>
                    <p>
                      Technical requirements include HTML, XML and Excel, plus the ability to learn our in-house tools quickly. The successful candidate will be detail-oriented, capable of handling multiple projects at once, able to take direction, and work well with multiple teams. In addition to having first-class written and verbal communication skills in English, this Site Merchandiser will be able to prioritize to meet deadlines in a fast-paced environment. The ideal candidate will be innovative and internally motivated and have strong interest in and some experience with online merchandising.
                    </p>
                    <h4 className='desc-careerh4'>
                      Ongoing responsibilities include:
                    </h4>
                    <ul className='ul-career'>
                      <li>Coordinating and managing resources to provide thorough and authoritative detail page content</li>
                      <li>Broadly managing the quality of our catalogue and the resulting customer experience; managing related projects such as improving product data quality, attribute and image collection, browse cleanups, photo studio usage, etc.</li>
                      <li>Tracking and analyzing customer needs based on customer behaviors including searching, browsing and purchasing, identifying problems and opportunities and responding accordingly, engaging the cross-functional team where necessary</li>
                      <li>Planning and executing the site merchandising calendar. This includes email programs, new product launches, cross-category placements, developing and executing Amazon site-wide events, browse tree design and search results</li>
                      <li>Develop merchandising for large store-wide promotions, and work closely with buyers and marketing to schedule promotions</li>
                      <li>Create emails, category pages, brand stores, campaigns, and develop and manage free search improvement projects</li>
                      <li>Synthesize and analyze relevant content metrics</li>
                      <li>Propose new traffic-driving content strategies</li>
                      <li>Regularly develop, test and analyze new merchandising tactics that sell products across all categories and increase conversion rates, documenting the results and sharing best practices with the team</li>
                    </ul>
                    <h4 className='desc-careerh4'>
                      The Site Merchandiser must be able to think and act both strategically and tactically. The ideal candidate will demonstrate the following:
                    </h4>
                    <ul className='ul-career'>
                      <li>Experience designing and executing online customer experiences</li>
                      <li>Strong communication skills; experience in coordinating teams and communicating to senior management</li>
                      <li>Strong bias for action and ability to prioritize</li>
                      <li>Ability to use hard data and metrics to back up feature and customer segment recommendations</li>
                      <li>Aptitude for organization, flexibility and producing results in a fast paced environment</li>
                      <li>Capability to meet our technical requirements, which include HTML, XML and Excel, plus the ability to learn our in-house tools quickly</li>
                      <li>Post Graduate preferred</li>
                    </ul>
                    <h4 className='desc-careerh4'>
                      BASIC QUALIFICATIONS
                    </h4>
                    <ul className='ul-career'>
                      <li>
                        Bachelor's degree
                      </li>
                    </ul>
                    <h4 className='desc-careerh4'>
                      PREFERRED QUALIFICATIONS
                    </h4>
                    <ul className='ul-career'>
                      <li>
                        2+ years of sales experience
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  <div className="paragraph-career">
                    <h3 className='mainborder' >
                      Job details
                    </h3>
                    <ul className='jobdeatils-list jobdeatils-listnew'>
                      <li>
                        <span>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </span>
                        <span>
                          IN, KA, Bengaluru
                        </span>
                      </li>
                 
                      <li>
                        <span>
                        <i class="fa fa-suitcase" aria-hidden="true"></i>
                        </span>
                        <span>
                          Marketing
                        </span>
                      </li>
                    </ul>
    
                  </div>
                  <div className="paragraph-career">
                    <h3 className='mainborder mt-20' >
                      Related jobs
                    </h3>
                    <div className="related-cards">
                    <p className='para-relate'>
                        <a href="">Marketing Manager, Prime Member Growth</a>
                      </p>
                      <ul className='jobdeatils-list jobdeatils-text'>
                        <li>
                          <span>
                            <b>Location :</b>
                          </span>
                          <span>IND, KA, Bengaluru</span>
                        </li>
                        <li>
                          <span>
                            <b>Posted :</b>
                          </span>
                          <span>May 25, 2023</span>
                        </li>
    
                        <li className='job-text-li'> (Updated about 23 hours ago)</li>
                      </ul>
                    </div>
    
    
                    <div className="related-cards">
                    <p className='para-relate'>
                        <a href="">Marketing Manager, Performance Marketing</a>
                      </p>
                      <ul className='jobdeatils-list jobdeatils-text'>
                        <li>
                          <span>
                            <b>Location :</b>
                          </span>
                          <span>IND, KA, Bengaluru</span>
                        </li>
                        <li>
                          <span>
                            <b>Posted :</b>
                          </span>
                          <span>May 25, 2023</span>
                        </li>
    
                        <li className='job-text-li'> (Updated about 23 hours ago)</li>
                      </ul>
                    </div>
                    <div className="related-cards">
                      <p className='para-relate'>
                        <a href="">Sr Manager - Marketing, Mother Brand</a>
                      </p>
                      <ul className='jobdeatils-list jobdeatils-text'>
                        <li>
                          <span>
                            <b>Location :</b>
                          </span>
                          <span>IND, KA, Bengaluru</span>
                        </li>
                        <li>
                          <span>
                            <b>Posted :</b>
                          </span>
                          <span>May 25, 2023</span>
                        </li>
    
                        <li className='job-text-li'> (Updated about 23 hours ago)</li>
                      </ul>
                    </div>
                    <div className="related-cards">
                      <p className='para-relate'>
                        <a href="">Sr Manager - Marketing, Prime</a>
                      </p>
                      <ul className='jobdeatils-list jobdeatils-text'>
                        <li>
                          <span>
                            <b>Location :</b>
                          </span>
                          <span>IND, KA, Bengaluru</span>
                        </li>
                        <li>
                          <span>
                            <b>Posted :</b>
                          </span>
                          <span>May 25, 2023</span>
                        </li>
    
                        <li className='job-text-li'> (Updated about 23 hours ago)</li>
                      </ul>
                    </div>
                    <div className="related-cards">
                      <p className='para-relate'>
                        <a href="">Associate Site Merchandiser</a>
                      </p>
                      <ul className='jobdeatils-list jobdeatils-text'>
                        <li>
                          <span>
                            <b>Location :</b>
                          </span>
                          <span>IND, KA, Bengaluru</span>
                        </li>
                        <li>
                          <span>
                            <b>Posted :</b>
                          </span>
                          <span>May 25, 2023</span>
                        </li>
    
                        <li className='job-text-li'> (Updated about 23 hours ago)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <Footer />
        </>
      )

}

export default FindDescription