import { NavLink } from "react-router-dom";

function AdminSidebar() {
    return (
        <>
            <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <NavLink className='nav-link' to='/Dashboard'>
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>

                    <li class="nav-item">
                        <NavLink className='nav-link' to='/Blogs'>
                            <i class="bi bi-envelope"></i>
                            <span>Blogs</span>
                        </NavLink>
                    </li>

                    <li class="nav-item">
                        <NavLink className='nav-link' to='/Jobs'>
                            <i class="bi bi-envelope"></i>
                            <span>Jobs</span>
                        </NavLink>
                    </li>

                    <li class="nav-item">
                        <NavLink className='nav-link' to='/CaseStudy'>
                            <i class="bi bi-envelope"></i>
                            <span>CaseStudy</span>
                        </NavLink>
                    </li>

                </ul>

            </aside>
        </>
    )
}

export default AdminSidebar;