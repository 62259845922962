import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import Home from './components/Home'

import AdminLogin from "./admin/AdminLogin";
import Dashboard from "./admin/Dashboard";
import Blogs from "./admin/Blogs";
import Jobs from "./admin/Jobs";
import CaseStudy from "./admin/CaseStudy";
import Overview from './components/Overview';
import History from './components/History';
import Leadership from './components/Leadership';
import OurBlog from './components/OurBlog';
import Product from './components/Product';
import ProductDetails from './components/ProductDetails';
import EncouragingGoodHealth from './components/EncouragingGoodHealth';
import OurBlogEcoDes from './components/OurBlogEcoDes';
import DeepCondolense from './components/DeepCondolense';
import OilPlants from './components/OilPlants';
import Careerjob from './components/Careerjob';
import FindDescription from './components/FindDescription';
import EcofriendlyBlogsDetails from './components/EcofriendlyBlogsDetails'
import MountMeruEdibleOil from './components/MountMeruEdibleOil'
import AboutAgri from './components/AboutAgri';
import AboutEnergy from './components/AboutEnergy';
import MountMeruPetrolium from './components/MountMeruPetrolium';
import AcerPetroleumLimited from './components/AcerPetroleumLimited';
import MountMeruGas from './components/MountMeruGas';
import Mountmerulogistic from './components/Mountmerulogistic';
import AcerInfrastructure from './components/AcerInfrastructure';
import MountMerumillier from './components/MountMerumillier';
import Mountmerusoyco from './components/Mountmerusoyco';
import LifeofMeru from './components/LifeofMeru';
import AgriProduct from './components/AgriProduct';
import EnergyProduct from './components/EnergyProduct';
import SupplyChain from './components/SupplyChain';
import FindJob from './components/FindJob';
import IndustryProfessional from './components/IndustryProfessional';
import PostGraduates from './components/PostGraduates';
import Interns from './components/Interns';
import WorkMountMeru from './components/WorkMountMeru';
import WhatWeOffer from './components/WhatWeOffer';
import HowWeWork from './components/HowWeWork';
import HowToApply from './components/HowToApply';
import StaionDetails from './components/StaionDetails';
import AllStation from './components/StationDescription';
import ContactUs from './components/ContactUs';
import EmailerApply from './components/EmailerApply';
import EmailerProduct from './components/EmailerProduct';
import EmailerContact from './components/EmailerContact';

import InfrastructureDesign from './components/InfrastructureDesign';
import Logistics from './components/Logistics';
import HomeCounter from './components/HomeCounter';


import ZambiaStationList from './components/ZambiaStationList';
import StationDescription from './components/StationDescription';
import StationDescriptionSos from './components/StationDescriptionSos';
import StationDescriptionLumumba from './components/StationDescriptionLumumba';
import StationDescriptionMumbwa from './components/StationDescriptionMumbwa';
import StationDescriptionMungwi from './components/StationDescriptionMungwi';
import StationDescriptionFarmers from './components/StationDescriptionFarmers';
import StationDescriptionLibala from './components/StationDescriptionLibala';
import StationDescriptionKafueRoad from './components/StationDescriptionKafueRoad';
import StationDescriptionMakeni from './components/StationDescriptionMakeni';
import HomeCounterTime from './components/HomeCounterTime';
import MeruStation from './components/MeruStation';
import MountMeruLubricant from './components/MountMeruLubricant';

import Esg from './components/Esg';
import EsgMenu from './components/EsgMenu';
import Csr from './components/Csr';
import Timeline from './components/Timeline';
import FeedTheChild from './components/FeedTheChild';
import Nfr from './components/Nfr';
import StationNewData from './components/StationNewData';
import ZambiaDescription from './components/ZambiaDescription';
import BotswanaDescription from './components/BootswanaDescription';
import GabonDescription from './components/GabonDescription';
import KenyaDescription from './components/KenyaDescription';
import DrDescription from './components/DrDescription';
import CoteDescription from './components/CoteDescription';
import MalawiDescription from './components/MalawiDescription';
import MozambiqueDescription from './components/MozambiqueDescription';
import RwandaDescription from './components/RwandaDescription';
import SwazilandDescription from './components/SwazilandDescription';
import TanzaniaDescription from './components/TanzaniaDescription';
import UgandaDescription from './components/UgandaDescription';
import ZimbabweDescription from './components/ZimbabweDescription';
import NamibiaDescription from './components/NamibiaDescription';
import Mining from './components/Mining';
import SolarEnergy from './components/SolarEnergy';
import Epc from './components/Epc';


import './assets/css/color/theme-color.css'
import './assets/css/module-css/about-section.css'
import './assets/css/module-css/banner-section.css'
import './assets/css/module-css/blog-section.css'
import './assets/css/module-css/breadcrumb-section.css'
import './assets/css/module-css/contact-page.css'
import './assets/css/module-css/fact-counter-section.css'
import './assets/css/module-css/faq-section.css'
import './assets/css/module-css/footer-section.css'
import './assets/css/module-css/header-section.css'
import './assets/css/module-css/partner-section.css'
import './assets/css/module-css/services-section.css'
import './assets/css/module-css/team-section.css'
import './assets/css/module-css/testimonial-section.css'
import './assets/css/animate.css'
import './assets/css/aos.css'
import './assets/css/bootstrap-select.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/bxslider.css'
import './assets/css/color-1.css'
import './assets/css/color-2.css'
import './assets/css/custom-animate.css'
import './assets/css/fancybox.min.css'
import './assets/css/flaticon.css'
import './assets/css/font-awesome.min.css'
import './assets/css/icomoon.css'
import './assets/css/imp.css'
import './assets/css/jquery-ui.css'
import './assets/css/jquery.bootstrap-touchspin.css'
import './assets/css/magnific-popup.css'
import './assets/css/nice-select.css'
import './assets/css/owl.css'
import './assets/css/rtl.css'
import './assets/css/scrollbar.css'
import './assets/css/style.css'
import './assets/css/kstyle.css'
import './assets/css/a.css'
import './assets/css/responsive.css'
import 'slick-carousel/slick/slick.css';
import 'sweetalert2/dist/sweetalert2.min.css';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

const App = () => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUser(user);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Overview' element={<Overview />} />
        <Route path='/History' element={<History />} />
        <Route path='/Leadership' element={<Leadership />} />
        <Route path='/Careerjob' element={<Careerjob />} />
        <Route path='/Product' element={<Product />} />
        <Route path='/ProductDetails' element={<ProductDetails />} />
        <Route path='/OurBlog' element={<OurBlog />} />
        <Route path='/EncouragingGoodHealth' element={<EncouragingGoodHealth />} />
        <Route path='/OurBlogEcoDes' element={<OurBlogEcoDes />} />
        <Route path='/DeepCondolense' element={<DeepCondolense />} />
        <Route path='/OilPlants' element={<OilPlants />} />
        <Route path='/FindDescription' element={<FindDescription />} />
        <Route path='/EcofriendlyBlogsDetails' element={<EcofriendlyBlogsDetails />} />
        <Route path='/MountMeruEdibleOil' element={<MountMeruEdibleOil />} />
        <Route path='/FindJob' element={<FindJob />} />


        <Route path='/AboutAgri' element={<AboutAgri />} />
        <Route path='/AboutEnergy' element={<AboutEnergy />} />
        <Route path='/MountMeruPetrolium' element={<MountMeruPetrolium />} />

        <Route path='/AcerPetroleumLimited' element={<AcerPetroleumLimited />} />

        <Route path='/MountMeruGas' element={<MountMeruGas />} />
        <Route path='/Mountmerulogistic' element={<Mountmerulogistic />} />
        <Route path='/AcerInfrastructure' element={<AcerInfrastructure />} />
        <Route path='/MountMerumillier' element={<MountMerumillier />} />

        <Route path='/Mountmerusoyco' element={<Mountmerusoyco />} />

        <Route path='/LifeofMeru' element={<LifeofMeru />} />
        <Route path='/AgriProduct' element={<AgriProduct />} />
        <Route path='/EnergyProduct' element={<EnergyProduct />} />
        <Route path='/SupplyChain' element={<SupplyChain />} />
        <Route path='/IndustryProfessional' element={<IndustryProfessional />} />
        <Route path='/PostGraduates' element={<PostGraduates />} />
        <Route path='/Interns' element={<Interns />} />
        <Route path='/WorkMountMeru' element={<WorkMountMeru />} />

        <Route path='/WhatWeOffer' element={<WhatWeOffer />} />
        <Route path='/HowWeWork' element={<HowWeWork />} />
        <Route path='/HowToApply' element={<HowToApply />} />

        <Route path='/StationDetails' element={<StaionDetails />} />

        <Route path='/AllStation' element={<AllStation />} />
        <Route path='/ContactUs' element={<ContactUs />} />
        <Route path='/EmailerApply' element={<EmailerApply />} />
        <Route path='/EmailerProduct' element={<EmailerProduct />} />
        <Route path='/EmailerContact' element={<EmailerContact />} />
        <Route path='/InfrastructureDesign' element={<InfrastructureDesign />} />
        <Route path='/Logistics' element={<Logistics />} />
        <Route path='/ZambiaStationList' element={<ZambiaStationList />} />
        <Route path='/StationDescription' element={<StationDescription />} />
        <Route path='/StationNewData' element={<StationNewData />} />
        <Route path='/StationDescription-Sos' element={<StationDescriptionSos />} />


        <Route path='/ZambiaDescription' element={<ZambiaDescription />} />
        <Route path='/DrDescription' element={<DrDescription />} />
        
        
        <Route path='/BotswanaDescription' element={<BotswanaDescription />} />
        <Route path='/GabonDescription' element={<GabonDescription />} />
        <Route path='/KenyaDescription' element={<KenyaDescription />} />
        <Route path='/CoteDescription' element={<CoteDescription />} />
        <Route path='/MalawiDescription' element={<MalawiDescription />} />
        <Route path='/MozambiqueDescription' element={<MozambiqueDescription />} />
        <Route path='/RwandaDescription' element={<RwandaDescription />} />
        <Route path='/SwazilandDescription' element={<SwazilandDescription />} />
        <Route path='/TanzaniaDescription' element={<TanzaniaDescription />} />
        <Route path='/UgandaDescription' element={<UgandaDescription />} />
        <Route path='/ZimbabweDescription' element={<ZimbabweDescription />} />
        <Route path='/NamibiaDescription' element={<NamibiaDescription />} />


        <Route path='/StationDescription-Lumumba' element={<StationDescriptionLumumba />} />
        <Route path='/StationDescription-Mumbwa' element={<StationDescriptionMumbwa />} />
        <Route path='/StationDescription-Mungwi' element={<StationDescriptionMungwi />} />
        <Route path='/StationDescription-Farmers' element={<StationDescriptionFarmers />} />
        <Route path='/StationDescription-Libala' element={<StationDescriptionLibala />} />
        <Route path='/StationDescription-KafueRoad' element={<StationDescriptionKafueRoad />} />
        <Route path='/StationDescription-Makeni' element={<StationDescriptionMakeni />} />
        <Route path='/HomeCounter' element={<HomeCounter />} />
        <Route path='/HomeCounterTime' element={<HomeCounterTime />} />
        <Route path='/MeruStation' element={<MeruStation />} />
        <Route path='/MountMeruLubricant' element={<MountMeruLubricant />} />
        <Route path='/Esg' element={<Esg />} />
        <Route path='/EsgMenu' element={<EsgMenu />} />
        <Route path='/Csr' element={<Csr />} />
        <Route path='/Timeline' element={<Timeline />} />
        <Route path='/FeedTheChild' element={<FeedTheChild />} />
        <Route path='/Nfr' element={<Nfr />} />
        <Route path='/Mining' element={<Mining />} />
        <Route path='/SolarEnergy' element={<SolarEnergy />} />
        <Route path='/Epc' element={<Epc />} />










        {user !== null ? (
          <>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/Jobs" element={<Jobs />} />
            <Route path="/CaseStudy" element={<CaseStudy />} />
          </>
        ) : (
          <Route path="/admin" element={<AdminLogin />} />
        )}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
      <ToastContainer />
    </>
  )

}

export default App;
