import React, { useState, useEffect } from 'react';
import Header from './Header';
import MeruStationCard from './MeruStationCard';
import Footer from './Footer';
import axios from 'axios';

import { Button, MenuItem } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import country1 from '../assets/images/icon/botswana.png';
import country2 from '../assets/images/icon/malawi.png';
import country4 from '../assets/images/icon/mozambique.jpg';
import country5 from '../assets/images/icon/namibia.jpg';
import country6 from '../assets/images/icon/rwanda.png';
import country7 from '../assets/images/icon/tanzania.png';
import country8 from '../assets/images/icon/uganda.png';
import country9 from '../assets/images/icon/zambia.png';
import country10 from '../assets/images/icon/zimbabwe.png';
import country11 from '../assets/images/icon/kenya.jpg';
import country12 from '../assets/images/icon/dubai.png';
import country13 from '../assets/images/icon/india.png';
import country14 from '../assets/images/icon/drv.png';
import country15 from '../assets/images/icon/ivory.png';
import country16 from '../assets/images/icon/gabon.png';

const MeruStation = () => {
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectState, setSelectState] = useState([]);
    const [userCountry, setUserCountry] = useState("");
    const [submittedCountry, setSubmittedCountry] = useState("");
    const [submittedCity, setSubmittedCity] = useState("");

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await axios.get('https://meru.growthmetaverse.in/public/api/locations');
                const locations = response.data;

                const uniqueCountries = [...new Set(locations.map(location => location.country))];

                const countryOptions = uniqueCountries.map(country => ({ value: country, label: country }));
                setCountryOptions(countryOptions);
            } catch (error) {
                console.log('Error fetching locations:', error);
            }
        };

        // const fetchUserCountry = async () => {
        //     try {
        //         const response = await axios.get('https://geolocation-db.com/json/');
        //         const data = response.data;
        //         setUserCountry(data.country_name);
        //     } catch (error) {
        //         console.log('Error fetching user country:', error);
        //     }
        // };

        fetchLocations();
        // fetchUserCountry();
    }, []);

    // useEffect(() => {
    //     if (userCountry) {
    //         setSelectedCountry(userCountry);
    //         handleChange(userCountry);
    //     }
    // }, [userCountry]);

    // const handleChange = async (selectedCountry) => {
    //     setSelectedCity("");
    //     try {
    //         const response = await axios.get(
    //             `https://meru.growthmetaverse.in/public/api/locationsbycountry/${selectedCountry}`
    //         );
    //         const districts = response.data.map((location) => location.district);
    //         const uniqueDistricts = [...new Set(districts)];
    //         setSelectState(uniqueDistricts);
    //     } catch (error) {
    //         console.log('Error fetching districts:', error);
    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedCountry) {
            setSubmittedCountry(selectedCountry);
        }
        // if (selectedCountry && selectedCity) {
        //     setSubmittedCountry(selectedCountry);
        //     setSubmittedCity(selectedCity);
        // }
    };

    return (
        <>
            <Header />
            <section className=" style2 bg-form-black find-meru-station">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-12 col-sm-12 col-md-6 padding-right-0px">
                            <div className='form-station'>
                                <div className="head-content-h2">
                                    <h2>
                                        Mount Meru
                                    </h2>
                                </div>
                                <div className='para-content-p'>
                                    <p>
                                        Locate the nearest fuel station(s)
                                    </p>
                                </div>
                                <form className='' onSubmit={handleSubmit} id='meru-station-id'>
                                    <div className='d-flexing'>
                                        <div className='input-group margin-right'>
                                            <select
                                                name="country"
                                                id="country"
                                                className="form-control"
                                                value={selectedCountry}
                                                onChange={(e) => {
                                                    setSelectedCountry(e.target.value);
                                                    // handleChange(e.target.value);
                                                }}
                                            >
                                                <option value="" disabled>
                                                    Select your country
                                                </option>
                                                {countryOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* <div className='input-group select-city'>
                                            <select
                                                name="city"
                                                id="city"
                                                className="form-control form-control-city"
                                                value={selectedCity}
                                                onChange={(e) => setSelectedCity(e.target.value)}
                                            >
                                                <option value="" disabled>
                                                    Select your city
                                                </option>
                                                {selectState.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}


                                    </div>
                                    <div className="submit-btns-form">
                                        <button type="submit" className="btn-onenew button-100">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="col-xl-8 col-lg-8 col-12 col-sm-12 col-md-6">
                            <div className='form-stationnews'>
                                <div className="head-content-img">
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country1} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country2} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country4} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country5} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country6} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country7} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country8} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country9} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country10} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country11} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country12} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country13} alt="" />
                                    </div>

                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country15} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country14} alt="" />
                                    </div>
                                    <div class="flag-icons-news">
                                        <img decoding="async" src={country16} alt="" />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='container'>
                <div className='row'>
                    {
                        StationData.map((post) => {
                            const { id, name, degree } = post;

                            return (

                                <div className="card col-xl-4" key={id}>

                                    <div className="title">
                                        {post.name}hh
                                    </div>
                                    <div className="body">
                                        {degree}
                                    </div>
                                </div>


                            )

                        })
                    }

                    <div className="col-xl-4">

                    </div>
                </div>
            </div> */}
            <div className="section-parent" style={{ position: 'relative' }}>
                <MeruStationCard country={submittedCountry} city={submittedCity} />
            </div>
            <Footer />
        </>
    )
}

export default MeruStation