import React from 'react';
import Fade from 'react-reveal/Fade';

import Header from './Header';
import Footer from './Footer';
import OurBlogBanner from './OurBlogBanner';
import OurBlogCard from './OurBlogCard';


import blog1 from '../assets/images/blog/blog-1.jpeg'
import blog2 from '../assets/images/blog/blog-2.jpeg'
import blog3 from '../assets/images/blog/blog-3.jpeg'
import blog4 from '../assets/images/blog/blog-4.jpeg'

const Newsortpg = {
    backgroundImage: 'url(&quot;images/background/cross-line2.png&quot;)'
}

const OurBlog = () => {
    return (
        <>
            <Header />

            <OurBlogBanner />
            <div className="section-parent">
                <OurBlogCard />
            </div>

            <Footer />

        </>
    );
}

export default OurBlog;
