import React from 'react'
import Fade from 'react-reveal/Fade';

const WorkMountMeruDetail = () => {
    return (
        <>

            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <Fade bottom>
                                <h2><span class="border-left"></span>
                                    <span class="one-text">Working at </span>
                                    <span class="two-text"> Mount Meru Group</span>
                                    <span class="border-right"></span>
                                </h2>
                            </Fade>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Maximise your potential
                                                </h3>
                                            </div>
                                            <p>
                                                Mount Meru Group, offers an unrivaled growth opportunities with a freedom to do more!
                                            </p>
                                            <p>

                                                Working with Mount Meru Group,  you have the opportunity to be part of a legacy as well as build a new one being a member of two iconic brands in India and African continent.  Innovative approach, integrity, ability to understand the market and it's needs  and a positive mindset to evolve continuously is what we expect from our new entrants.                                     </p>



                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Work with Passion and Zeal!
                                                </h3>
                                            </div>
                                            <p>
                                                Every role in the company paves a way towards doing more for the communities we live in. We recognize & encourage the ability & aspiration of toda's talent and offer tremendous opportunities to learn & grow with us.
                                            </p>
                                            <p>
                                                At Mount Meru Group, we work with panache towards delivering added value through our products and services to our stakeholders and the community at large.

                                            </p>


                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Performance without Compromise!
                                                </h3>
                                            </div>
                                            <p>
                                                In a continent like Africa with dynamic climatic and infrastructural variation, vast socio-economic spectrum, what matters the most? Addressing the market economy challenges, addressing aspirations through products at all price-points and offering more value for our customers is your area of opportunity to excel.
                                            </p>


                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Global Team Footprint can be yours..
                                                </h3>
                                            </div>
                                            <p>
                                                Sense the vibrant culture with the young as well as experienced professionals  from India and African continent collaborating to deliver the growth for communities we live in.                                            </p>
                                            <p>
                                                Leadership at Mount Meru Group is not just a word, its a way of living. We are motivated by the single purpose of doing more for the society in which we thrive. We thus constantly look out for talent who ring in ideas par excellence and build thriving careers.

                                            </p>


                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default WorkMountMeruDetail