import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory45 from '../assets/images/shop/miller2.png'
import Aboutstory46 from '../assets/images/shop/new-pic-17.png';
import Gasimg from '../assets/images/gas1.png'
import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const MountMeruGas = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>LPG</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Energy</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">LPG</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us</span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Mount Meru Gas was established keeping in mind a well-balanced supply of LPG (Cooking Gas) in the households of African nations. The increasing costs of Electricity (power) made it very difficult to cut through the electric induction cooking facilities for a common man which needed an alternative source of cooking through LPG cylinders. Meru Gas also believes into efficient usage and saving natural resources of Energy considering mother nature.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory45} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Gasimg} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Why Choose Us</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                    The Meru Gas division is highly motivated to continuous innovation in this segment and to distribute LPG Cooking Gas through various sizes of cylinders making it customer centric.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>
            </div>



            <Footer />
        </>
    );
}

export default MountMeruGas;
