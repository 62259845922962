import React from 'react'

import Imgban from "../assets/images/breadcrumb/breadcrumb-1.jpg";

const HistoryBanner = () => {
    return (
        <>
            <section class="breadcrumb-area style2"><div class="breadcrumb-area-bg"></div><div class="container"><div class="row"><div class="col-xl-12"><div class="inner-content"><div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"><h2>History</h2></div><div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"><ul><li><a href="index-2.html">Home</a></li><li><i class="fa fa-angle-right" aria-hidden="true"></i></li><li><a href="index-2.html">About</a></li><li><i class="fa fa-angle-right" aria-hidden="true"></i></li><li class="active">History</li></ul></div></div></div></div></div></section>





        </>
    )
}

export default HistoryBanner