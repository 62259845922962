import axios from 'axios';
// Import all action types
import * as ActionTypes from './ActionType';

// Create
export const createBlogRequest = () => ({
    type: ActionTypes.CREATE_BLOG_REQUEST,
});

export const createBlogSuccess = (blog) => ({
    type: ActionTypes.CREATE_BLOG_SUCCESS,
    payload: blog,
});

export const createBlogFailure = (error) => ({
    type: ActionTypes.CREATE_BLOG_FAILURE,
    payload: error,
});

export const createBlog = (blog) => {
    return (dispatch) => {
        dispatch(createBlogRequest());

        axios.post('https://meru.saamaancart.com/public/api/blogs_view', blog, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OTI2MmYzNC1jMzRlLTQ2NTEtYWYwNS1iNmI0MTE5ZDYzMTAiLCJqdGkiOiJmMTllY2M1YTM4M2EzMTIzMzFmNzJhMDkwZjY0MDdmZjFlODRlYjEzMjM1YTdiOTI0ODAzZDIwMWNkZTEyYWY2NDExODgwYzk2MmQ5NTAzMSIsImlhdCI6MTY4Mzg5MzkxOC41NTQ3OTA5NzM2NjMzMzAwNzgxMjUsIm5iZiI6MTY4Mzg5MzkxOC41NTQ3OTI4ODEwMTE5NjI4OTA2MjUsImV4cCI6MTcxNTUxNjMxOC41NDk2NjQwMjA1MzgzMzAwNzgxMjUsInN1YiI6IjE3Iiwic2NvcGVzIjpbXX0.v-QCmlu1k89yitIlV7OlYzDP1ErcN1MjXG0dtOfnuwcxtPjc7GTMDx_q9JV-0gHtV1-OPjx9J_Vjmw5eCb8kaGHaPyMR1LVDpkzyDD0C3pWK_l-ahpbzMbddZqBKS_B90Gex1b0P0h-MPy7ODOH4xZRIZL6tsI-qEhd1wKf64R7FKIO2ZsqWVv61FS57Bl-BJOPqkLCAK0DBd9uL6pyqC-xyvLTaziOnUb-7OElyWXDYUALSC7uQuBaFXsDcvjo35FTDoa4QjEn8jUe9vHwtDR5Rs9J24Vc5EMCsAmbW26I84j8jKCBgtMDh-IlUBaXusLvN1Oo7OM29VCKquJHZqgA440NNMW3cKMK62rzavQ-IGhoBHJWJR7Q6A4CCR58bi8WgWfLAj2ggLC1QxplsJVb6ccnmI5zvw8Qul-xJZb-E3cHfGcM7H7xNcT6CbmZEnqOk7ypdasMOdgO7GmMyEgFhIH1Ejgbs3OneskAOyW6izP_OkxR1ZIKNZm1wkUc9C0Drgoiwm4tJfqomYFdX4tIixYyCarGjU-HcXJEx17iFLP4m0Xzhc5aBMPyBxYCKLmhRazaOww0lLQ2DmTy8y6ryzzegTyzwLccSt0A6-s3n2d50k9XWgloMa81j4KQuvRpzsLFHEb2jKPGSJxmPotG64ibIgZQcSyduqBKLnrk",
            }
        })
            .then((response) => {
                dispatch(createBlogSuccess(response.data));
            })
            .catch((error) => {
                dispatch(createBlogFailure(error.message));
            });
    };
};

// Read
export const getBlogRequest = () => ({
    type: ActionTypes.GET_BLOG_REQUEST,
});

export const getBlogSuccess = (blog) => ({
    type: ActionTypes.GET_BLOG_SUCCESS,
    payload: blog,
});

export const getBlogFailure = (error) => ({
    type: ActionTypes.GET_BLOG_FAILURE,
    payload: error,
});

export const getBlog = (id) => {
    return (dispatch) => {
        dispatch(getBlogRequest());

        axios.get(`/api/blogs/${id}`)
            .then((response) => {
                dispatch(getBlogSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getBlogFailure(error.message));
            });
    };
};

export const getAllBlogsRequest = () => ({
    type: ActionTypes.GET_ALL_BLOGS_REQUEST,
});

export const getAllBlogsSuccess = (blogs) => ({
    type: ActionTypes.GET_ALL_BLOGS_SUCCESS,
    payload: blogs,
});

export const getAllBlogsFailure = (error) => ({
    type: ActionTypes.GET_ALL_BLOGS_FAILURE,
    payload: error,
});

export const getAllBlogs = () => {
    return (dispatch) => {
        dispatch(getAllBlogsRequest());

        axios.get('/api/blogs')
            .then((response) => {
                dispatch(getAllBlogsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getAllBlogsFailure(error.message));
            });
    };
};

// Update
export const updateBlogRequest = () => ({
    type: ActionTypes.UPDATE_BLOG_REQUEST,
});

export const updateBlogSuccess = (blog) => ({
    type: ActionTypes.UPDATE_BLOG_SUCCESS,
    payload: blog,
});

export const updateBlogFailure = (error) => ({
    type: ActionTypes.UPDATE_BLOG_FAILURE,
    payload: error,
});

export const updateBlog = (id, blog) => {
    return (dispatch) => {
        dispatch(updateBlogRequest());

        axios.put(`/api/blogs/${id}`, blog)
            .then((response) => {
                dispatch(updateBlogSuccess(response.data));
            })
            .catch((error) => {
                dispatch(updateBlogFailure(error.message));
            });
    };
};

// Delete
export const deleteBlogRequest = () => ({
    type: ActionTypes.DELETE_BLOG_REQUEST,
});

export const deleteBlogSuccess = (id) => ({
    type: ActionTypes.DELETE_BLOG_SUCCESS,
    payload: id,
});

export const deleteBlogFailure = (error) => ({
    type: ActionTypes.DELETE_BLOG_FAILURE,
    payload: error,
});

export const deleteBlog = (id) => {
    return (dispatch) => {
        dispatch(deleteBlogRequest());

        axios.delete(`/api/blogs/${id}`)
            .then(() => {
                dispatch(deleteBlogSuccess(id));
            })
            .catch((error) => {
                dispatch(deleteBlogFailure(error.message));
            });
    };
};

const initialState = {
    blogs: [],
    loading: false,
    error: null,
};

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_BLOG_REQUEST:
        case ActionTypes.GET_BLOG_REQUEST:
        case ActionTypes.GET_ALL_BLOGS_REQUEST:
        case ActionTypes.UPDATE_BLOG_REQUEST:
        case ActionTypes.DELETE_BLOG_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ActionTypes.CREATE_BLOG_SUCCESS:
            return {
                ...state,
                blogs: [...state.blogs, action.payload],
                loading: false,
            };
        case ActionTypes.GET_BLOG_SUCCESS:
            return {
                ...state,
                blogs: [action.payload],
                loading: false,
            };
        case ActionTypes.GET_ALL_BLOGS_SUCCESS:
            return {
                ...state,
                blogs: action.payload,
                loading: false,
            };
        case ActionTypes.UPDATE_BLOG_SUCCESS:
            const updatedBlogIndex = state.blogs.findIndex((blog) => blog._id === action.payload._id);
            const updatedBlogs = [...state.blogs];
            updatedBlogs[updatedBlogIndex] = action.payload;
            return {
                ...state,
                blogs: updatedBlogs,
                loading: false,
            };
        case ActionTypes.DELETE_BLOG_SUCCESS:
            const remainingBlogs = state.blogs.filter((blog) => blog._id !== action.payload);
            return {
                ...state,
                blogs: remainingBlogs,
                loading: false,
            };
        case ActionTypes.CREATE_BLOG_FAILURE:
        case ActionTypes.GET_BLOG_FAILURE:
        case ActionTypes.GET_ALL_BLOGS_FAILURE:
        case ActionTypes.UPDATE_BLOG_FAILURE:
        case ActionTypes.DELETE_BLOG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default blogReducer;