import React from 'react'
import Fade from 'react-reveal/Fade';

const HowToApplyDetail = () => {
    return (
        <>

            <section class="p-120 blog-page-one">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <Fade bottom>
                                <h2><span class="border-left"></span>
                                    <span class="one-textwhite">How To   </span>
                                    <span class="two-text"> Apply</span>
                                    <span class="border-right"></span>
                                </h2>
                            </Fade>
                        </div>
                    </div>

                    <form method='get' >


                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mx-auto apply-white">
                            <div class="row">
                                <div className='col-12 col-sm-12  col-md-6 col-lg-6 col-xl-6 mb-4'>
                                    <div className='Input-label-group'>
                                        <label for="Name">
                                            Name
                                        </label>

                                    </div>
                                    <div className='Inputtype-field'>
                                        <input type='text' className='form-control' placeholder='Name' />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12  col-md-6 col-lg-6 col-xl-6 mb-4'>
                                    <div className='Input-label-group'>
                                        <label for="Name">
                                            Phone No
                                        </label>

                                    </div>
                                    <div className='Inputtype-field'>
                                        <input type='text' className='form-control' placeholder=' Phone No' />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12  col-md-6 col-lg-6 col-xl-6 mb-4'>
                                    <div className='Input-label-group'>
                                        <label for="Name">
                                            Email
                                        </label>

                                    </div>
                                    <div className='Inputtype-field'>
                                        <input type='email' className='form-control' placeholder='Email' />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12  col-md-6 col-lg-6 col-xl-6 mb-4'>
                                    <div className='Input-label-group'>
                                        <label for="Name">
                                            Upload Your Resume
                                        </label>

                                    </div>
                                    <div className='Inputtype-field'>
                                        <input type='file' className='form-control' placeholder='Upload Your Resume' />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12  col-md-6 col-lg-4 col-xl-4 mt-4 mx-auto'>
                                <button type='submit' class="btn-one" href="/about"><span class="txt">Submit</span></button>
                                </div>

                            </div>


                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default HowToApplyDetail