import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";

function Dashboard() {
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Dashboard</h1>
                </div>

            </main>
            <AdminFooter />
        </>
    )
}

export default Dashboard;