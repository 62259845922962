import React from 'react'
import Header from './Header';
import Footer from './Footer';
import InternsBanner from './InternsBanner';
import InternsDetail from './InternsDetail';
const Interns = () => {
    return (
        <>
            <Header />
            <InternsBanner />
            <InternsDetail />
            <Footer />
        </>
    )
}

export default Interns