import React from 'react'
import Fade from 'react-reveal/Fade';
import Header from './Header';
import Footer from './Footer';

const contactBg = {
    backgroungImage: 'url(assets/images/pattern/thm-pattern-3.png)'
}
const StationDescriptionFarmers = () => {
    return (
       <>
            <Header />
            <section className="breadcrumb-area style2">
                <div className="breadcrumb-area-bg" >

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Farmers
                                    </h2>


                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Countries</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Farmers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-info-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                            <div className="row">

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-pin-1"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP16</h3>

                                                </div>
                                                <p>Farmers


                                                </p>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-phone-call-3"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                    style={contactBg}></div>
                                                <div className="top">
                                                    <h3>ROM
                                                        Number"

                                                    </h3>

                                                </div>
                                                <p className="text1">Sales: <a href="tel:967738363">967738363




                                                </a> <br />


                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-email-1"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3>Send Email</h3>

                                                </div>
                                                <p className="text1">
                                                    Sales: <a href="mailto:farmers@mountmerupetro.com">farmers@mountmerupetro.com



                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="order-summary-box">
                                <Fade bottom>
                                    <ul>
                                        <li>
                                            <h4>"Station Code"</h4>
                                            <div className="value">
                                                <div className="left">ZP16</div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Station Name</h4>
                                            <div className="value">
                                                <div className="left">Farmers

                                                </div>
                                            </div>
                                        </li>


                                        <li>
                                            <h4>Cluster Name</h4>
                                            <div className="value">
                                                <div className="left">Aman



                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>RH Name</h4>
                                            <div className="value">
                                                <div className="left">Dhruv


                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Territory</h4>
                                            <div className="value">
                                                <div className="left">Lusaka_2
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>District</h4>
                                            <div className="value">
                                                <div className="left">SIBIIYUNZI


                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Provience</h4>
                                            <div className="value">
                                                <div className="left">LUSAKA
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>OTP Date

                                            </h4>
                                            <div className="value">
                                                <div className="left">01-01-08




                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>RO Type</h4>
                                            <div className="value">
                                                <div className="left">DOCO


                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>ROM Name

                                            </h4>
                                            <div className="value">
                                                <div className="left">Annie



                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>"No. Of Staff



                                            </h4>
                                            <div className="value">
                                                <div className="left">7




                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Fade>



                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <section className="main-contact-form-area">
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Fade bottom>
                                <div className="contact-page-map-outer">

                                    <div className="station-mapp">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.3582711286504!2d28.0532558!3d-15.411202699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f960009bc7ef%3A0x1a50d5c0d0d6882b!2sMeru%20Filling%20Station%20Farmers!5e0!3m2!1sen!2sin!4v1686815882174!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                                    </div>
                                </div>
                            </Fade>

                        </div>

                    </div>
                </div>
            </section>
            <Footer />

        </> 
    )
}

export default StationDescriptionFarmers