import React from 'react'
import Fade from 'react-reveal/Fade';

const PostGraduatesDetails = () => {
    return (
            <>
                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">Freshers & Graduates</span>
                                        <span class="two-text"> / Post Graduates</span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Expand your horizons of knowledge and industry exposure
                                                    </h3>
                                                </div>
                                                <p>
                                                    At Mount Meru Group, you would have a chance to build path-breaking careers in various sectors & geographies across.
                                                </p>
                                                <p>

                                                    Give your career a kickstart by working to make a difference through multiple projects as an employee with the global conglomerate
                                                </p>
                                                <p>
                                                    Open yourself to a galore of endless opportinities and infinite learning possibilities through SPANDAN - our inhouse program for entry level hires.
                                                </p>


                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </>
    )
}

export default PostGraduatesDetails