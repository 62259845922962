import React from 'react'
import Fade from 'react-reveal/Fade';
import Mount1 from '../assets/images/mount1.png';
import Mount2 from '../assets/images/mount2.png';
import Mount3 from '../assets/images/mount3.png';

import Header from './Header';
import Footer from './Footer';
const Esg = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Esg</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li>
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Agri</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Esg</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-style2-area">
                <div className="container">
                    <div className="row align-items-center m100-b">

                        <div className="col-xl-6">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text"> </span>
                                                <span class="two-text">Introduction</span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                Meru, recognizes the critical importance of Environmental, Social, and Governance (ESG) principles in achieving long-term success and sustainability.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <Fade bottom>
                                <div className="about-style2__image-box">

                                    <div className="img-box2">

                                        <div className="inner img-esg">
                                            <img srcSet={Mount1} alt="Aboutstory" />
                                        </div>

                                    </div>



                                    <div className="icon-box">
                                        <span className="flaticon-house-with-wooden-roof"></span>
                                    </div>

                                </div>
                            </Fade>
                        </div>


                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center order-flexing m100-b">
                        <div className="col-xl-6">
                            <Fade bottom>
                                <div className="about-style2__image-box">

                                    <div className="img-box2">

                                        <div className="inner img-esg">
                                            <img srcSet={Mount2} alt="Aboutstory" />
                                        </div>

                                    </div>



                                    <div className="icon-box">
                                        <span className="flaticon-house-with-wooden-roof"></span>
                                    </div>

                                </div>
                            </Fade>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">

                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Environmental</span>
                                                <span class="two-text">Stewardship</span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                Meru places great emphasis on environmental sustainability. The company implements strategies to reduce its ecological footprint by optimizing waste management, minimizing energy consumption, and promoting the use of renewable resources.
                                            </p>
                                            <p className='mt-2'>
                                                Meru engages into eco-friendly sources of energy by generating Solar powered energy at its fuel stations and manufacturing facilities. The group plans to actively engage into more environment friendly innovations within the organization and to its collaborators.
                                            </p>
                                            <p className='mt-2'>
                                                By actively embracing eco-friendly practices, Meru demonstrates its commitment to mitigating the negative impact on the environment.
                                            </p>

                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">

                        <div className="col-xl-6">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Social  </span>
                                                <span class="two-text">Responsibility</span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                The company understands the significance of social responsibility and prioritizes the well-being and safety of its employees. Meru ensures fair labour practices, upholds employee rights, and fosters a diverse and inclusive work environment. By promoting a culture of respect and equality, the company nurtures a motivated workforce that drives innovation and success.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <Fade bottom>
                                <div className="about-style2__image-box">

                                    <div className="img-box2">

                                        <div className="inner img-esg">
                                            <img srcSet={Mount3} alt="Aboutstory" />
                                        </div>

                                    </div>



                                    <div className="icon-box">
                                        <span className="flaticon-house-with-wooden-roof"></span>
                                    </div>

                                </div>
                            </Fade>
                        </div>


                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center order-flexing">

                        <div className="col-xl-12">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">

                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Supply</span>
                                                <span class="two-text">Chain</span>
                                                <span class="two-text"> Management</span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                Meru recognizes that responsible supply chain management is essential to ESG implementation. The company collaborates with partners who adhere to ethical standards, including environmental sustainability, fair trade, and human rights compliance. By selecting suppliers who share its values, Meru contributes to a responsible and sustainable value chain.
                                            </p>

                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">

                        <div className="col-xl-12">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Community</span>
                                                <span class="two-text"> Engagement</span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                Meru actively engages with the communities in which it operates. The company recognizes the importance of supporting local stakeholders and participates in community development initiatives. Through investments in education under the name of “Avinash Scholarship Fund” and Children Education and healthcare programs under “Feed the Child initiative “, the company strives to uplift the communities it serves and promote sustainable livelihoods.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">

                        <div className="col-xl-12">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Governance </span>
                                                <span class="two-text">and Ethics </span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                Strong corporate governance and ethical practices are at the core of Meru's operations. The company maintains transparency, accountability, and integrity in its decision-making processes. By upholding high ethical standards and adhering to recognized governance practices, Meru instils trust and confidence in its stakeholders.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">

                        <div className="col-xl-12">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Innovation  </span>
                                                <span class="two-text">and Research </span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                                The company recognizes that innovation is vital for sustainable growth. Meru invests in research and development to develop environmentally friendly products and services. By embracing innovation, the company strives to meet evolving market demands while minimizing environmental impact and contributing to a more sustainable future.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">

                        <div className="col-xl-12">
                            <div className="about-style2__content">


                                <div class="sec-title text-center">
                                    <div class="sec-titlenew sub-titleabout sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Continuous </span>
                                                <span class="two-text">Improvement   </span>
                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="inner-content">
                                    <Fade bottom>
                                        <div className="text">
                                            <p>
                                            Meru views ESG implementation as an ongoing journey. The company continuously assesses its ESG performance, sets ambitious goals, and works towards enhancing its practices. By fostering a culture of continuous improvement, Meru remains adaptable and responsive to emerging environmental and social challenges.
                                            </p>
                                            <p className='mt-2'>

                                            Meru exemplifies a strong commitment to implementing ESG principles in its businesses. Through environmental stewardship, social responsibility, strong governance, and continuous improvement.
                                            </p>
                                        </div>
                                    </Fade>



                                </div>
                            </div>
                        </div>




                    </div>
                </div>
 
            </section>

        


            <Footer />
        </>
    )
}

export default Esg