import React from 'react'
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
const OverviewChooseUs = () => {

    const settings = {
        fade: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    autoplay: true
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                },
            },
        ],
    };

    return (
        <>
            <section class="team-style1-area team-style1-area--style2 about-page choose-team why-choose">
                {/* <div class="pattern-bg"></div> */}
                <div class="container">


                    <div class="row massage-card-row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="sec-title text-center">
                                <div class="sec-titlenew sub-titleabout sub-title">
                                    <Fade bottom>
                                        <h2 className='justifyleft'>
                                            {/* <span class="border-left"></span> */}
                                            <span class="one-text"> Why Choose Us </span>
                                            {/* <span class="two-text"> </span> */}
                                            <span class="border-right"></span>
                                        </h2>
                                    </Fade>
                                </div>
                            </div>
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">

                                <div class="img-holder">

                                    <div class="inner ">
                                        <p>
                                            At Meru, we are driven by a passion for innovation and a relentless pursuit of excellence. Our comprehensive range of services encompasses diverse sectors, enabling us to cater to the evolving needs of our clients and communities. Our Downstream Petroleum Business ensures a seamless supply of high-quality fuels, lubricants, and LPG, backed by a robust distribution network and pioneering technology. In the Manufacturing of Edible Oils, we utilize modern facilities and adhere to stringent quality standards, offering a wide array of nutritious oils under renowned brands. Our Logistics and Storage services provide efficient and reliable transportation and warehousing solutions, connecting businesses and customers across Africa. In the realm of Infrastructure (Real Estate), we design and develop sustainable properties that redefine urban living and commercial spaces, creating vibrant environments for communities to thrive. With each service we offer, we strive to exceed expectations, delivering exceptional value, and forging long-term partnerships.
                                        </p>
                                        <div class="overlay-bg"></div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="team-style1-area team-style1-area--style2 about-page choose-team">
                {/* <div class="pattern-bg"></div> */}
                <div class="container">


                    <div class="row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="sec-title text-center">
                                <div class="sec-titlenew sub-title ">
                                    <Fade bottom>
                                        <h2>
                                            {/* <span class="border-left"></span> */}
                                            <span class="one-text">Our Values</span>

                                            <span class="border-right"></span>
                                        </h2>
                                    </Fade>
                                </div>
                            </div>


                            <div class="country-grid  value-smalviews">

                                <Slider {...settings}>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>HUMILITY</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>INTEGRITY</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>HARD WORK</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>COMPASSION</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>VEGETARIANISM</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>FAMILY CULTURE</p>
                                        </div>
                                    </div>
                                    <div class="country-presence px-2">
                                        <div class="country-images country-images-again">
                                            <p>SHARING AND GIVING</p>
                                        </div>
                                    </div>
                                </Slider>

                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OverviewChooseUs