import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';

const FindJobDetail = () => {
    const [fullName, setFullName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [resume, setResume] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('fullName', fullName);
            formData.append('mobileNumber', mobileNumber);
            formData.append('email', email);
            formData.append('role', role);
            formData.append('resume', resume);
            formData.append('subject', "Enquiry for Job Request");

            await axios.post('https://meru.growthmetaverse.in/public/api/jobrequests', formData);

            Swal.fire({
                icon: 'success',
                title: 'Form submitted successfully!',
            }).then(() => {
                setFullName('');
                setMobileNumber('');
                setEmail('');
                setRole('');
                setResume(null);
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'An error occurred while submitting the form.',
            });
        }
    };

    return (
        <>
            <div className="section-parent" style={{ background: "#f5f5f5" }}>
                <section className="normaol-padding-section">
                    <div class="blog-page blog-page-one">
                        <div class="container">

                            <div class="sec-title text-center">
                                <div class="sec-titlenew sub-title">
                                    <h2 className='header-flextion-h2'><span class="border-left"></span>
                                        <div className='header-flextion '>
                                            <span class="one-text">Working at Meru</span>
                                            <span class="two-text"> </span>
                                        </div>
                                        <span class="border-right"></span>
                                    </h2>
                                </div>

                            </div>
                        </div>
                        <div className="container">
                            <div class="row">

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div class="img-holder">
                                            <Fade bottom>
                                                <div class="inner inner-message">
                                                    <div className='h3-head-job'>
                                                        <h3>
                                                            Maximise your potential
                                                        </h3>
                                                    </div>
                                                    <p>
                                                        Meru, offers an unrivaled growth opportunities with a freedom to do more!
                                                    </p>
                                                    <p>

                                                    Working with Meru, you can be part of a legacy and build a new one by being a member of two iconic brands in India and Africa. An innovative approach, integrity, the ability to understand the market and its needs and a positive mindset to evolve continuously is what we expect from our new entrants.
                                                    </p>



                                                </div>

                                            </Fade>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div class="img-holder">
                                            <Fade bottom>
                                                <div class="inner inner-message">
                                                    <div className='h3-head-job'>
                                                        <h3>
                                                            Work with Passion and Zeal!
                                                        </h3>
                                                    </div>
                                                    <p>
                                                        Every role in the company paves a way towards doing more for the communities we live in. We recognize & encourage the ability & aspiration of today's talent and offer tremendous opportunities to learn & grow with us.
                                                    </p>
                                                    <p>
                                                        At Meru, we work with panache towards delivering added value through our products and services to our stakeholders and the community at large.

                                                    </p>


                                                </div>
                                            </Fade>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div class="img-holder">
                                            <Fade bottom>
                                                <div class="inner inner-message">
                                                    <div className='h3-head-job'>
                                                        <h3>
                                                            Performance without Compromise!
                                                        </h3>
                                                    </div>
                                                    <p>
                                                        In a continent like Africa with dynamic climatic and infrastructural variation, vast socio-economic spectrum, what matters the most? Addressing the market economy challenges, addressing aspirations through products at all price-points and offering more value for our customers is your area of opportunity to excel.
                                                    </p>


                                                </div>
                                            </Fade>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div class="img-holder">
                                            <Fade bottom>
                                                <div class="inner inner-message">
                                                    <div className='h3-head-job'>
                                                        <h3>
                                                            Global Team Footprint can be yours..
                                                        </h3>
                                                    </div>
                                                    <p>
                                                    Since the vibrant culture with the young and experienced professionals from India and the African continent collaborating to deliver growth for the communities, we live in.                                            </p>
                                                    <p>
                                                        Leadership at Meru is not just a word, its a way of living. We are motivated by the single purpose of doing more for the society in which we thrive. We thus constantly look out for talent who ring in ideas par excellence and build thriving careers.

                                                    </p>


                                                </div>
                                            </Fade>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">What we offer </span>
                                        <span class="two-text"> </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div class="container">

                        <div class="row">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Career development and learning
                                                    </h3>
                                                    {/* <h3>
                            Can you add your bit to our knowledge..
                        </h3> */}
                                                </div>
                                                <p>
                                                    At Meru, we have defined the pillars of our business. The first being you all..! We have cultivated an environment that involves a genuine intent to foster the long-term learning & development of our employees.
                                                </p>
                                                <p>

                                                    You can explore wealth of knowledge through our initiative 'Rise with Meru' and continue your knowledge quest through  gamut of programs that would aid you to grow your career across geographies.
                                                </p>
                                                <p>
                                                Our programs include up-scaling the employees to suit different functionalities and support the dynamic synchronisation of processes and systems.
                                                </p>
                                                <p>
                                                Our employees enjoy support, career development and opportunities to grow. We strive to ensure they get everything they need to realise their full potential and grow with us globally.



                                                </p>


                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>

            

                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">How we Work </span>
                                        <span class="two-text"> </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Our guiding principles
                                                    </h3>

                                                </div>
                                                <p>
                                                Remaining faithful to the principle, "The basis of management is people," we are committed to developing human resources with outstanding & rewarding opportunities and a challenging spirit, as well as to developing our abilities through a depth of employee education and training.
                                                </p>
                                                <p>

                                                We set challenging goals and ensure that all of us, irrespective of hierarchies and positions, are aligned towards fulfilling them, making the best use of collective & collaborative wisdom. We have a well-defined roadmap for our growth that is dynamic as the markets we operate in. We respect each individual's individuality while at the same time working towards maintaining and improving the system continuously, which enables us to develop diverse qualities in employees.
                                                </p>



                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Our Core Values
                                                    </h3>

                                                </div>
                                                <p>
                                                    Our purpose, values and guiding principles help us determine our objectives, our approach to business activities, and the general direction of our company. Together these guidelines serve as a compass, helping us set and maintain the right direction for our business.
                                                </p>
                                                <p>

                                                    Our business primarily comprises of the integrated philosophies and processes. First, we are blessed by society with valuable resources, including human resources, materials, funds and information. Next, we use these resources to create value-added products and services. Finally, we make these products and services available throughout the global presence. Thus we aim at contributing to economic, social and environmental progress with a view to achieving sustainable development.
                                                </p>



                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <section class="blog-page blog-page-one">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text"> Industry Professional</span>
                                        <span class="two-text"> </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="img-holder">
                                        <Fade bottom>
                                            <div class="inner inner-message">
                                                <div className='h3-head-job'>
                                                    <h3>
                                                        Build a new legacy with conviction
                                                    </h3>
                                                </div>
                                                <p>
                                                    Over the last four decades, we have built our presence in different industry segments globally and continuously strive to expand our footprints in different geographies.                                            </p>
                                                <p>

                                                So, if you are amongst one who aspires to be a change that will leave an imprint in the industry, come and join the team of 6000+ employees to energise the lives of millions around us.
                                                </p>


                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="blog-page blog-page-one d-none">
                    <div className="container">

                        <div className="row text-right-rtl justify-content-center">
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card border-right'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>

                                        <h3>
                                            Industry Professionals
                                        </h3>
                                        <p>
                                            Build a new legacy with conviction
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/IndustryProfessional" className="btn-one"><span className="txt">Read More</span></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card border-right'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>
                                        <h3>
                                            Freshers and Graduates / Post Graduates
                                        </h3>
                                        <p>
                                            Expand your horizons of knowledge and industry exposure
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/PostGraduates" className="btn-one"><span className="txt">Read More</span></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>

                                        <h3>
                                            Interns
                                        </h3>
                                        <p>
                                            Imagine all possibilities
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/Interns" className="btn-one"><span className="txt">Read More</span></NavLink>

                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card border-right'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>

                                        <h3>
                                            Working at Meru
                                        </h3>
                                        <p>
                                            Why Meru
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/WorkMountMeru" className="btn-one"><span className="txt">Read More</span></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card border-right'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>
                                        <h3>
                                            What we offer
                                        </h3>
                                        <p>
                                            Career development and learning
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/WhatWeOffer" className="btn-one"><span className="txt">Read More</span></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 main-bg-card'>
                                <div className='find-job-card'>
                                    <div className='find-job-content'>

                                        <h3>
                                            How we work
                                        </h3>
                                        <p>
                                            Our guiding principles
                                        </p>
                                    </div>
                                    <div className='button-findjob'>
                                        <NavLink to="/HowWeWork" className="btn-one"><span className="txt">Read More</span></NavLink>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="main-contact-form-area mt-80">
                    <div className="container">
                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">How To Apply </span>
                                        <span class="two-text"> </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>


                        <div className='row'>


                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="contact-style1_form text-center">
                                    <div className="top-title text-center">
                                        <h2>Drop Your Message</h2>
                                        <p>Please feel free to get in touch using the form below. We'd love
                                            to hear for you.</p>
                                    </div>
                                    <div className="contact-form">
                                        <form onSubmit={handleSubmit} id="contact-form" name="contact_form" className="default-form2">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6">
                                                    <div className="input-box input-text">
                                                        <label>Enter Full Name</label>
                                                        <input type="text" name="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name:" required />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6">
                                                    <div className="input-box input-text">
                                                        <label>Enter Mobile Number</label>
                                                        <input type="text" name="mobileNumber" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Enter Mobile Number" required />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6">
                                                    <div className="input-box input-text">
                                                        <label>Enter Email</label>
                                                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email:" required />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="input-box input-text">
                                                        <label>Role</label>
                                                        <div className="select-box select-boxes">
                                                            <select value={role} onChange={(e) => setRole(e.target.value)} required>
                                                                <option value="Service">Service</option>
                                                                <option value="Management Trainee/Interns">Management Trainee/Interns</option>
                                                                <option value="Finance and Accounts">Finance and Accounts</option>
                                                                <option value="Logistics and Supply Chain">Logistics and Supply Chain</option>
                                                                <option value="Agri Operations">Agri Operations</option>
                                                                <option value="Sales and Marketing">Sales and Marketing</option>
                                                                <option value="Human Resources">Human Resources</option>
                                                                <option value="IT and Automation">IT and Automation</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                                    <div className="input-box input-text">
                                                        <label>Please Upload Your CV</label>
                                                        <div className="Inputtype-field">
                                                            <input type="file" className="form-control" onChange={(e) => setResume(e.target.files[0])} required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 send-resume-btn">
                                                    <div className="button-box">
                                                        <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                                        <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                            <span className="txt">Send Message</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default FindJobDetail