import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ProductModel = (props) => {
    const [fullName, setfullName] = useState('');
    const [email, setemail] = useState('');
    const [mobileNumber, setmobileNumber] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('email', email);
        formData.append('mobileNumber', mobileNumber);
        formData.append('subject', `Contacted for ${props.productName}`);
        formData.append('messagebody', `Contacted for ${props.productName}`);

        axios
            .post('https://meru.growthmetaverse.in/public/api/contactus', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire('Success', 'Form submitted successfully!', 'success');
            })
            .catch((error) => {
                console.error(error);
                Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
            });

        setfullName('');
        setemail('');
        setmobileNumber('');

        props.onHide();
    };

    return (
        <>
            <Modal
                className="planModal"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <a class="closed-btns" onClick={props.onHide}>
                        <div className="icons-modal icons-modal-again ">
                            <i class="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </a>
                    <div class="sec-titleagain">
                        <h2>Please Fill The Form</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="contact-style1_form text-center contact-style1_form_again">
                                <div className="contact-form">
                                    <form
                                        id="contact-form"
                                        name="contact_form"
                                        className="default-form2"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="input-box input-text">
                                                    <label>Enter Full Name</label>
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        placeholder="Full Name:"
                                                        required
                                                        value={fullName}
                                                        onChange={(e) => setfullName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="input-box input-text">
                                                    <label>Enter Mobile Number</label>
                                                    <input
                                                        type="text"
                                                        name="number"
                                                        placeholder="Mobile Number:"
                                                        required
                                                        value={mobileNumber}
                                                        onChange={(e) => setmobileNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="input-box input-text">
                                                    <label>Enter email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="email:"
                                                        required
                                                        value={email}
                                                        onChange={(e) => setemail(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 send-resume-btn send-resume-btn-product">
                                                <div className="button-box">
                                                    <input
                                                        id="form_botcheck"
                                                        name="form_botcheck"
                                                        className="form-control"
                                                        type="hidden"
                                                        value=""
                                                    />
                                                    <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                        <span className="txt">Send</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductModel;
