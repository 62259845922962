import axios from 'axios';

// action types
const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

// initial state
const initialState = {
    token: null,
    loading: false,
    error: null,
};

// action creators
const getTokenRequest = () => ({
    type: GET_TOKEN_REQUEST,
});

const getTokenSuccess = (token) => ({
    type: GET_TOKEN_SUCCESS,
    payload: token,
});

const getTokenFailure = (error) => ({
    type: GET_TOKEN_FAILURE,
    payload: error,
});

// thunk action creator
const getToken = () => {
    return async (dispatch) => {
        dispatch(getTokenRequest());

        try {
            const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OTI2MmYzNC1jMzRlLTQ2NTEtYWYwNS1iNmI0MTE5ZDYzMTAiLCJqdGkiOiI5OGYxZjdkOTg3ZTNmNmRkYzgxMmVjMmI0YTlmZjNmMmE2NzliNjE2NWYyODJkNjFhMGRkMGVlZDUzZjk3OGMzMjhmOTkxOTY5MGU4M2ZiYyIsImlhdCI6MTY4NDE1NjA2MC4wMjY4NTk5OTg3MDMwMDI5Mjk2ODc1LCJuYmYiOjE2ODQxNTYwNjAuMDI2ODYxOTA2MDUxNjM1NzQyMTg3NSwiZXhwIjoxNzE1Nzc4NDYwLjAxNTA0NzA3MzM2NDI1NzgxMjUsInN1YiI6IjE5Iiwic2NvcGVzIjpbXX0.in3tiueuqzO5Y57V4u8tpuFGpmHvnSq9pjcw5Y9PrpDfxeREcqV1mLRO2myLhPl4lxNgzQANpAOfr-yzjyrLhTWeHFDI5uLTjv6EVm9gXsxuLa8mCa9_JMOSsNbWvcYqKB2c3CuLrzc0KhsrGUtaZCcnc8Pp_2YyuzKzjXs6fkd9ioyclxzihEF2NK_6eZE4hP4a9hZybxB2pjvo7xNShdORDQL2pXFiP7qtR6IeDWTsZTOa6qg-2aCTZQKquXejzdjg6nGFkceY60Q1HCWAUVsuQwiyGI_rRyYOxb_-V0SxcAgh1RTQzYQjxUNDW8vBT5_ZM9F5EAONX6Am8dYeWvAwU7qnpKkwIXdYLOEES5yBJG2ESh0Nx3hQ8Jqnel2LY611PddRCtZHRopn-gI1nARMAKOhGDRtZ6qpUmjGQN7CItggix6e4IgHdqhlN6xIbrSEZszCyHHA9dtQPvLiK0_xTym5aV4hogHjyBU2jTHRDbkX14x2NOdoENE90CWtQwADxBY5O4CPOF_2DAxO4Zl1ymBt6A8_osobHtyUHgAkK2CN81whnb4taAz_G7oyNtNMe-4671wEkRlqEFW5Jhz1W7p_1EulsRp4vmCVmfdUs9aSjVYCIX7tvLIGnEDiO1gyS5n_1jywq19XzdX-rAn-4iAWYl5ZbsDFgmoPZbw';

            const response = await axios.post('https://meru.saamaancart.com/public/api/csrf_token', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const tokenValue = response.data.csrf_token;

            dispatch(getTokenSuccess(tokenValue));
        } catch (error) {
            dispatch(getTokenFailure(error.message));
        }
    };
};

// reducer function
const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload,
            };
        case GET_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export { getToken }; // export the thunk action creator
export default tokenReducer;