import React from 'react';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import pattern4 from '../assets/images/pattern/thm-pattern-4.png'
import pattern5 from '../assets/images/pattern/thm-pattern-5.png'
import meru1 from '../assets/images/lifemeru/1.JPG'
import meru2 from '../assets/images/lifemeru/2.JPG'
import meru3 from '../assets/images/lifemeru/3.JPG'
import meru4 from '../assets/images/lifemeru/4.JPG'
import meru5 from '../assets/images/lifemeru/5.JPG'
import meru6 from '../assets/images/lifemeru/6.JPG'
import meru7 from '../assets/images/lifemeru/7.JPG'

import meru8 from '../assets/images/lifemeru/8.jpeg'
import meru9 from '../assets/images/lifemeru/9.jpeg'
import meru10 from '../assets/images/lifemeru/10.jpeg'
import meru11 from '../assets/images/lifemeru/11.jpeg'
import meru12 from '../assets/images/lifemeru/12.jpeg'
import impact1 from '../assets/images/our-impact/1.png'
import impact2 from '../assets/images/our-impact/2.png'
import impact3 from '../assets/images/our-impact/3.png'
import impact4 from '../assets/images/our-impact/4.png'
import impact5 from '../assets/images/our-impact/5.png'



import Aboutstory from '../assets/images/gallery/employee.jpeg'
import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const LifeofMeru = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,

        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        lazyLoad: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <>
            <Header />

            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Empowering Employees</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Careers</a></li>
                                        <li>
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Life at Meru</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">
                                            Empowering Employees</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">


                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Meru as an Employee  </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                    “We at Meru believe that great ideas come from great people. Our people are the greatest asset we are proud to be associated with. We ensure that the Happiness of our staff is kept on a top priority at all times.
                                                    The open-door policy that we practice in the Group allows any employee to freely share their ideas and contribute to the growth of our company.
                                                    We work very positively towards the skill development of our team members,
                                                    giving them all the options to grow and elevate themselves in the group.

                                                </p>
                                                <p>
                                                    We keep the Family Culture intact by helping our employees grow in areas they wish to professionally and personally.”


                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>



                        </div>
                    </div>




                </section>

                <section class="choose-style2-area about-page ">
                    <div class="container">



                        <div className="sll">
                            <Slider className='theme_carousel testimonials-carousel_1 owl-dot-style1 owl-theme' {...settings}>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru3} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru5} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru6} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru5} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru11} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={meru12} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={impact1} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={impact2} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={impact3} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={impact4} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="marucard-img">
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={impact5} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </div>



                            </Slider>
                        </div>
                        <div className="content-para">
                            <p>
                                If you are a highly enthusiastic individual looking out for well established carrier platforms, please click below and send your resume.                    </p>
                            <div class="blog-style1__button apply-btn">
                                <a class="btn-one" href="/FindJob">
                                    <span class="txt">APPLY HERE </span>
                                </a>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

            <Footer />
        </>
    );
}

export default LifeofMeru;
