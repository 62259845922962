import React from 'react'
import Header from './Header';
import Footer from './Footer';
import WorkMountMeruBanner from './WorkMountMeruBanner';
import WorkMountMeruDetail from './WorkMountMeruDetail';
const WorkMountMeru = () => {
  return (
   <>
   <Header />
   <WorkMountMeruBanner />
   <WorkMountMeruDetail />
   <Footer />
   </>
  )
}

export default WorkMountMeru