import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import Header from './Header';
import Footer from './Footer';

import Imgban from "../assets/images/breadcrumb/breadcrumb-1.jpg";
import related1 from '../assets/images/shop/shop-1.jpg';
import shopImage1 from "../assets/images/shop/pro-big-img-1.jpg";
import shopImage2 from "../assets/images/shop/pro-big-img-2.jpg";
import shopImage3 from "../assets/images/shop/pro-big-img-3.jpg";
import thumbImage1 from "../assets/images/shop/pro-big-img-1-thumb-1.jpg";
import thumbImage2 from "../assets/images/shop/pro-big-img-2-thumb-2.jpg";
import thumbImage3 from "../assets/images/shop/pro-big-img-3-thumb-3.jpg";

function ProductDetails() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
        centerPadding: '10px'
    };

    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >
                    {/* <img srcSet={Imgban} alt='Imgban' /> */}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Product Detail</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="index-2.html">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Product Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="product-details-image-box">
                                <div className="product-details-main-image">
                                    <Slider
                                        {...settingsMain}
                                        asNavFor={nav2}
                                        ref={slider => (setSlider1(slider))}
                                        className='bxslider'
                                    >
                                        <li>
                                            <div className="single-box clearfix">
                                                <div className="img-holder">
                                                    <img src={shopImage1} alt="Awesome Image" />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-box clearfix">
                                                <div className="img-holder">
                                                    <img src={shopImage2} alt="Awesome Image" />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-box clearfix">
                                                <div className="img-holder">
                                                    <img src={shopImage3} alt="Awesome Image" />
                                                </div>
                                            </div>
                                        </li>
                                    </Slider>
                                </div>
                                <div className="slider-pager">
                                    <Slider
                                        {...settingsThumbs}
                                        asNavFor={nav1}
                                        ref={slider => (setSlider2(slider))}
                                        className='thumb-box'
                                    >
                                        <li>
                                            <a className="active" data-slide-index="0" href="#">
                                                <div className="thumb-image">
                                                    <img src={thumbImage1} alt="Awesome Thumb Image" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a data-slide-index="1" href="#">
                                                <div className="thumb-image">
                                                    <img src={thumbImage2} alt="Awesome Thumb Image" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a data-slide-index="2" href="#">
                                                <div className="thumb-image">
                                                    <img src={thumbImage3} alt="Awesome Thumb Image" />
                                                </div>
                                            </a>
                                        </li>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="product-details-content-box">
                                <div className="top">
                                    <div className="review-box">
                                        <ul>
                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                            <li><i className="flaticon-star-of-favorites-outline"></i></li>
                                        </ul>
                                    </div>
                                    <div className="product-title">
                                        <h2>Rubber Glove</h2>
                                    </div>
                                    <div className="rate-box">
                                        <h3>$23.99</h3>
                                    </div>
                                </div>

                                <div className="product-description">
                                    <p>Beguiled and demoralized by the charms of pleasure of the moment blinded
                                        by desire that they cannot foresee the pain and trouble that are bound to and
                                        equal blame belongs to those who fail in their duty.</p>
                                </div>

                                <div className="product-info">
                                    <ul>
                                        <li>
                                            <div className="inner">
                                                <div className="title">
                                                    <h5>Technology</h5>
                                                    <span>:</span>
                                                </div>
                                                <div className="text">
                                                    <p>Mon Perc Technology</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="inner">
                                                <div className="title">
                                                    <h5>Output Power</h5>
                                                    <span>:</span>
                                                </div>
                                                <div className="text">
                                                    <p>50 Watt</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="inner">
                                                <div className="title">
                                                    <h5>Services</h5>
                                                    <span>:</span>
                                                </div>
                                                <div className="text">
                                                    <p>Free Shipping, 7 Days Replacement, Cash On<br /> Delivery Available</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="product-quantity-box-outer">
                                    <div className="top-title">
                                        <h5>Quantity</h5>
                                    </div>
                                    <div className="product-quantity-box">
                                        <div className="input-box">
                                            <input className="quantity-spinner" type="text" value="1" name="quantity" />
                                        </div>
                                        <div className="right">
                                            <div className="cart-box">
                                                <button className="btn-one" type="submit">
                                                    <span className="txt">Add to Cart</span>
                                                </button>
                                            </div>
                                            <div className="button">
                                                <a href="#"><span className="fa fa-heart"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="product-details-social-share">
                                    <div className="title">
                                        <h5>Share with others</h5>
                                    </div>
                                    <div className="social-link">
                                        <ul className="clearfix">
                                            <li>
                                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-tab-box tabs-box">
                                <ul className="tab-btns tab-buttons clearfix text-center">
                                    <li data-tab="#desc" className="tab-btn active-btn"><span>Description</span></li>
                                </ul>
                                <div className="tabs-content">
                                    <div className="tab active-tab" id="desc">
                                        <div className="product-details-tab-content">
                                            <div className="product-description-content">
                                                <div className="text">
                                                    <p>Nor again is there anyone who loves or pursues or desires to pain of
                                                        itself, because it is pain, but because occasionally circumstances
                                                        occur in which toil and paincan procure him some great pleasure. To
                                                        take a trivial example, which of us ever undertakes laborious except
                                                        to obtain some advantage from it? But who has any right to find
                                                        fault with a man who chooses.</p>
                                                    <p>Must explain to you how all this mistaken idea of denouncing pleasure
                                                        and praising pain was born and I will give you a completed great of
                                                        the great explorer of the truth, the master-builder of human
                                                        happiness except to obtain some advantage.</p>
                                                </div>
                                            </div>
                                            <div className="review-form text-right-rtl">
                                                <div className="title-box">
                                                    <h2>Add Your Comments</h2>
                                                </div>
                                                <form id="review-form" action="#">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-box">
                                                                <div className="field-label">Comments</div>
                                                                <textarea name="fcomments" placeholder=""
                                                                    required=""></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="input-box">
                                                                <div className="field-label">Name*</div>
                                                                <input type="text" name="fname" placeholder="" required="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-box">
                                                                <div className="field-label">Email*</div>
                                                                <input type="email" name="femail" placeholder="" required="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-box">
                                                                <div className="field-label">Website</div>
                                                                <input type="text" name="fwebsite" placeholder="" required="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="add-rating-box">
                                                                <div className="add-rating-title">
                                                                    <p>Your Rating</p>
                                                                </div>
                                                                <div className="review-box">
                                                                    <ul>
                                                                        <li><i className="fa fa-star"></i></li>
                                                                        <li><i className="fa fa-star"></i></li>
                                                                        <li><i className="fa fa-star"></i></li>
                                                                        <li><i className="fa fa-star"></i></li>
                                                                        <li><i className="fa fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="button-box">
                                                                <div className="left">
                                                                    <button className="btn-one" type="submit">
                                                                        <span className="round"></span>
                                                                        <span className="txt">Submit</span>
                                                                    </button>
                                                                </div>

                                                                <div className="right">
                                                                    <div className="checked-box2">
                                                                        <input type="checkbox" name="skipper1" id="skipper"
                                                                            checked="" />
                                                                        <label htmlFor="skipper"><span></span>
                                                                            Save my name, email, and website in this browser
                                                                            for the next time I comment.
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="related-product-box">
                        <div className="shop-page-title">
                            <h2>Related Products</h2>
                        </div>
                        <div className="row">
                            {/* Start Single Shop Item */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="single-shop-item">
                                    <div className="single-shop-item_inner">
                                        <div className="img-holder">
                                            <img src={related1} alt="" />
                                            <div className="overlay">
                                                <ul>
                                                    <li>
                                                        <a href="#shop-details">
                                                            <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#shop">
                                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="title-holder">
                                            <h3>
                                                <a href="#product-details">Single Roof Gray</a>
                                            </h3>
                                            <div className="review-box">
                                                <ul>
                                                    <li>
                                                        <i className="flaticon-star-of-favorites-outline"></i>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-star-of-favorites-outline"></i>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-star-of-favorites-outline"></i>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-star-of-favorites-outline"></i>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-star-of-favorites-outline"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h6>$23.99</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Shop Item */}

                            {/* Repeat the above structure for the remaining shop items */}

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ProductDetails;