import React from 'react'
import Fade from 'react-reveal/Fade';
import Header from './Header';
import Footer from './Footer';

const contactBg = {
    backgroungImage: 'url(assets/images/pattern/thm-pattern-3.png)'
}
const StationDescription = () => {
    return (
        <>
            <Header />
            <section className="breadcrumb-area style2">
                <div className="breadcrumb-area-bg" >

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content">
                                <div className="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Zambia</h2>


                                </div>
                                <div className="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="#">Countries</a></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li className="active">Zambia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-info-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                            <div className="row">

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-pin-1"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3> Station
                                                        Code  :ZP11</h3>

                                                </div>
                                                <p>Emmasdale

                                                </p>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-phone-call-3"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                    style={contactBg}></div>
                                                <div className="top">
                                                    <h3>ROM
                                                        Number"

                                                    </h3>

                                                </div>
                                                <p className="text1">Sales: <a href="tel:979748433">979748433
                                                </a> <br />


                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-12 col-sm-12  col-xl-4 col-lg-4">
                                    <Fade bottom>
                                        <div className="single-contact-info-box">
                                            <div className="icon">
                                                <span className="flaticon-email-1"></span>
                                            </div>
                                            <div className="text-holder">
                                                <div className="pattern-bg"
                                                ></div>
                                                <div className="top">
                                                    <h3>Send Email</h3>

                                                </div>
                                                <p className="text1">
                                                    Sales: <a href="mailto:emmassdale@mountmerupetro.com">emmassdale@mountmerupetro.com
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="order-summary-box">
                                <Fade bottom>
                                    <ul>
                                        <li>
                                            <h4>"Station Code"</h4>
                                            <div className="value">
                                                <div className="left">ZP11</div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Station Name</h4>
                                            <div className="value">
                                                <div className="left">Emmasdale
                                                </div>
                                            </div>
                                        </li>


                                        <li>
                                            <h4>Cluster Name</h4>
                                            <div className="value">
                                                <div className="left">Natasha
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>RH Name</h4>
                                            <div className="value">
                                                <div className="left">Dhruv

                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Territory</h4>
                                            <div className="value">
                                                <div className="left">Lusaka_4</div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>District</h4>
                                            <div className="value">
                                                <div className="left">LUSAKA

                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>Provience</h4>
                                            <div className="value">
                                                <div className="left">LUSAKA
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>OTP Date

                                            </h4>
                                            <div className="value">
                                                <div className="left">03/03/11

                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>RO Type</h4>
                                            <div className="value">
                                                <div className="left">DOCO</div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>ROM Name

                                            </h4>
                                            <div className="value">
                                                <div className="left">Maiwase Tembo

                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h4>"No. Of Staff



                                            </h4>
                                            <div className="value">
                                                <div className="left">15


                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Fade>



                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <section className="main-contact-form-area">
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Fade bottom>
                                <div className="contact-page-map-outer">

                                    <div className="station-mapp">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1923.3766426092568!2d28.2792562!3d-15.3898422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f5dcf2992ca7%3A0xbd26438654e999b3!2sMount%20Meru%20Petroleum%20Z%20Ltd!5e0!3m2!1sen!2sin!4v1686027736362!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </Fade>

                        </div>

                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}

export default StationDescription;
