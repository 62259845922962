import React from 'react'
import Fade from 'react-reveal/Fade';


const LeadershipMessage = () => {
    return (
        <>
        

            <section className="about-style1-area leadership-top">
                <div className="container">
                    <div className="row align-items-center massage-card-row">
                        <div className="col-xl-12">
                            <div className="about-style1__content">
                                <Fade bottom>
                                    <div className="sec-title">

                                        <h2>
                                        MESSAGE FROM THE SHAREHOLDERS
                                        </h2>
                                    </div>
                                </Fade>

                            </div>
                        </div>
                        <div className="col-xl-12 mt-4">
                            <div className="inner  massage-card">
                                <p>
                                We started Meru with a handful of people who we consider the most crucial members of our family. Today this family has expanded to more than 6000 & we thank them for the tremendous hard work and dedication they have blessed us with. We welcome everyone to associate with the prestigious Meru of companies & assure the experience will be worth It.
                                </p>
                                <p>

                                    For the last four decades, we have valued relationships and kept the family culture intact, which has helped us add value to our employees, associates, millions of farmers, and their family members. We truly believe in doing good for the people & giving back to the nation through our Feed the Child & Scholarship Fund programs, which are currently running in more than 15 countries. We thank you all for being a part of this wonderful journey so far & will continue to add value to our business associates, employees & customers.                                        </p>
                                <p>
                                    Meru Family
                                </p>
                            
                                <div className="overlay-bg"></div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default LeadershipMessage