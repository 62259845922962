import axios from 'axios';
// Import all action types
import * as ActionTypes from './ActionType';

// Action creators
export const loginRequest = () => ({
    type: ActionTypes.LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = (error) => ({
    type: ActionTypes.LOGIN_FAILURE,
    payload: error,
});

// Async action to perform the login
export const login = (email, password) => {
    return async (dispatch) => {
        dispatch(loginRequest());

        try {
            // Make an API request to verify the credentials
            const response = await axios.post('http://meru.saamaancart.com/public/api/login', { email, password });

            // Assuming the response contains the user object upon successful login
            const user = response.data;

            // Dispatch the success action with the user object
            user[1] === "You are successfully logged in." ? dispatch(loginSuccess(user)) : dispatch(loginFailure(user));

        } catch (error) {
            // Dispatch the failure action with the error message
            dispatch(loginFailure(error.message));
        }
    };
};

export const logout = () => ({
    type: ActionTypes.LOGOUT,
});

// Initial state
const initialState = {
    user: null,
    loading: false,
    error: null,
};

// Reducer function
const adminLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null,
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                user: null,
                loading: false,
                error: action.payload,
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                user: null,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};

export default adminLoginReducer;