import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import BlogReducer from "./BlogRedux";
import TokenReducer from "./Token";
import AdminLoginReducer from "./LoginRedux";

const rootReducer = combineReducers({
    blogs: BlogReducer,
    token: TokenReducer,
    adminLogin: AdminLoginReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, logger],
});

export default store;
