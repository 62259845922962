import React from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';
import impact1 from '../assets/images/our-impact/1.png'
import impact2 from '../assets/images/our-impact/2.png'
import impact3 from '../assets/images/our-impact/3.png'
import impact4 from '../assets/images/our-impact/4.png'
import impact5 from '../assets/images/our-impact/5.png'




import Slider from 'react-slick';
function PrevIcons(khushboo) {
    const { onClick } = khushboo;
    return (
        <>
            <div className="prev-icons-main" onClick={onClick}>
                <i class="fa fa-arrow-left " aria-hidden="true"></i>
            </div>
        </>
    )

}


function NextIcons(hamid) {
    const { onClick } = hamid;
    return (
        <>
            <div className='next-icon-main' onClick={onClick}>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
        </>
    )
}
const Newsortpg = {
    backgroundImage: 'url(&quot;images/background/cross-line2.png&quot;)'
}
const Impact = () => {
    const slidingproduct = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        autoplay: true,

        prevArrow: <PrevIcons />,


        nextArrow: <NextIcons />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    autoplay: true
                }
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 3,
                    autoplay: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true
                }
            }


        ]
    };


    return (
        <>
            <section className="service-style1-area">
                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-2.png)" }}></div>
                <div className="container">
                    <div className="sec-title center text-center">

                        <Fade bottom>

                            <h2>Our Impact</h2>
                        </Fade>
                    </div>

                    <div className="impact-slider">
                        <Slider {...slidingproduct}>
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="impact-card">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={impact1} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="impact-card">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={impact2} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="impact-card">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={impact3} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="impact-card">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={impact4} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="impact-card">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={impact5} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </Fade>
                            </div>
                         
                            {/* End Single Service Style1 */}
                        </Slider>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="service-style1__bottom-text">


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Impact;
