import React from 'react'
import Fade from 'react-reveal/Fade';
import Mount1 from '../assets/images/mount1.png';
import Mount2 from '../assets/images/mount2.png';
import FeedImage from '../assets/images/csr-img/child.jpg'
import impowerImage from '../assets/images/impower.png'
import Mount3 from '../assets/images/mount3.png';
import Aboutstory22 from '../assets/images/shop/acer-petroleum.jpg';
import Aboutstory21 from '../assets/images/gallery/5.jpg';
import Aboutcsr from '../assets/images/shop/new-pic-17.png';
import Aboutcsr1 from '../assets/images/shop/new-pic-2.JPG';
import Aboutcsr2 from '../assets/images/lifemeru/7.JPG';
import Empower from '../assets/images/csr-img/empower.png';
import Farm from '../assets/images/csr-img/farm.jpg';
import Wisdom from '../assets/images/csr-img/wisdom.jpg';
import Back from '../assets/images/csr-img/back.jpg'

import Header from './Header';
import Footer from './Footer';
const Csr = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>CSR</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Csr</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">

                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">


                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={FeedImage} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6">
                                <div className="about-style2__content">

                                    <Fade bottom>
                                        <div className="sec-title child-contents">

                                            <h2>
                                                FEED THE CHILD
                                            </h2>
                                        </div>
                                    </Fade>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Help us fight the battle against Hunger.
                                                </p>
                                                <p>
                                                    At Meru, we are committed to making a positive impact on the lives of children. Through our dedicated 'Feed the Child' initiative, we strive to combat childhood hunger by providing nutritious meals to underprivileged children. Join us in our mission to nourish and empower the next generation.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row  order-flexing">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">

                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text text-left">Empowering Communities</span>

                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">

                                                <p className='mb-3'>
                                                    We strongly believe that one can only prosper if they co-exist happily. Giving back to our community
                                                    is not only our duty but also our social responsibility.
                                                </p>
                                                <p className='mb-3'>
                                                    We at Mount Meru Group encourage and promote Education passionately.
                                                </p>
                                                <p className='mb-3'>
                                                    To extend wisdom to our youth, we volunteer in various campaigns and programs promoting education and continuous learning.
                                                    “Feed the Child” was one such initiative Meru launched in various African locations to promote education by serving free nutritious meals to all the students in government schools. Mount Meru has been actively involved in building School classrooms and basic infrastructure to encourage a healthy and happy environment for the students and their peers.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={impowerImage} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>




                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Farm} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text text-left">From Farms to Your Heart</span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p className='mb-3'>
                                                    <span className='color-orange'>“Farmer Out- Grower’s Scheme”</span> was another initiative we actively manage through our Agri Division.
                                                </p>
                                                <p className='mb-3'>
                                                    Farmers are the most hardworking, and we believe they should be treated with utmost respect and dignity. To overcome the hardships of an ordinary farmer, we implemented Outgrower’s scheme by helping them with training in the best agricultural methods and promising to purchase their crop at a price better than the market. This, as a result, not only helps the community but also strongly impacts their family to upgrade their living conditions and upscale their farming capacities.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text text-left">Wisdom is your Right </span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p className='mb-3'>
                                                    <span className='color-orange'>Avinash Scholarship Fund </span>is an initiative started by Meru to aid financial support towards primary and higher education for both employees and their children. The company has a policy where every local employee can apply for this scholarship towards higher education for themselves & primary education for their children, who we strongly believe are the nation's future.
                                                </p>


                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Wisdom} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>





                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Back} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text text-left">We have your back</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p className='mb-3'>
                                                    Meru calls itself a family & genuinely believes that all its employees and stakeholders are a part of this family. The group offers financial aid and fully paid treatments to its employees and family members who need critical medical assistance.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>


                </section>





                <section className="about-style1-area csr-end">
                    <div className="container">
                        <div className="row  massage-card-row mb-0">
                            <div className="col-xl-12">
                                <div className="about-style1__content">
                                    <Fade bottom>
                                        <div className="sec-title">

                                            <h2>
                                                Your blessings help us grow
                                            </h2>
                                        </div>
                                    </Fade>

                                </div>
                            </div>
                            <div className="col-xl-12 mt-4">
                                <div className="inner  massage-card mb-0">
                                    <p>
                                        Yesterday, my son underwent a cornea transplant for the second eye. The first cornea transplant on 28th December 2022 was very successful for the left eye, which was completely blind. We hope for the same success for the right eye. My son's confidence that had gone is now back and at an all-time high. His school performance has gotten much better. I want to thank the management of Meru for the sponsorship of this entire operation. My family will forever be grateful to Mount Meru. To you, our dear MD Mr Arvind, Sir, I only wish I had a better word in English to say thank you because the word "thank you" seems inadequate. GOD BLESS YOU.
                                    </p>
                                    <p>
                                        -Daniel - from Mount Meru Petroleum , Uganda
                                    </p>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div >

            <Footer />
        </>
    )
}

export default Csr