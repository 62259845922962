import React from 'react'
import Fade from 'react-reveal/Fade';

const HowWeWorkDetails = () => {
    return (
        <>

            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="sec-title text-center">
                        <div class="sec-titlenew sub-title">
                            <Fade bottom>
                                <h2><span class="border-left"></span>
                                    <span class="one-text">How we  </span>
                                    <span class="two-text"> Work</span>
                                    <span class="border-right"></span>
                                </h2>
                            </Fade>
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                    Our guiding principles
                                                </h3>

                                            </div>
                                            <p>
                                                Remaining faithful to the principle, "The basis of management is people," we are committed to developing human resources with outstanding & rewarding opportunities and a challenging spirit, as well as to developing our own abilities through a deapth of employee education and training.
                                            </p>
                                            <p>

                                                We set challening goals and ensure that all of us, irrespective of hierarchies and positions are aligned towards fulfilling them making best use of collective & collaborative wisdom. We have well-defined roadmap for our growth that is dynamic as the markets we operate in. We respect each individual's individuality, while at the same time work towards maintaining and improving the system continuously that enables us to develop the diverse qualities in employees.
                                            </p>



                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="single-team-style1 wow fadeInRight animated animated another2" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="img-holder">
                                    <Fade bottom>
                                        <div class="inner inner-message">
                                            <div className='h3-head-job'>
                                                <h3>
                                                Our Core Values
                                                </h3>

                                            </div>
                                            <p>
                                            Our purpose, values and guiding principles help us determine our objectives, our approach to business activities, and the general direction of our company. Together these guidelines serve as a compass, helping us set and maintain the right direction for our business. 
                                                                                        </p>
                                            <p>

                                            Our business primarily comprises of the integrated philosophies and processes. First, we are blessed by society with valuable resources, including human resources, materials, funds and information. Next, we use these resources to create value-added products and services. Finally, we make these products and services available throughout the global presence. Thus we aim at contributing to economic, social and environmental progress with a view to achieving sustainable development.
                                                                                        </p>



                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>


        </>
    )
}

export default HowWeWorkDetails