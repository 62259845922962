import React from 'react'
import Header from './Header';
import Footer from './Footer';
import FindJobBanner from './FindJobBanner';
import FindJobDetail from './FindJobDetail'
import HowToApply from './HowToApply';
const FindJob = () => {
  return (
    <>
      <Header />
      
        <FindJobBanner />
        <FindJobDetail />
    
      <Footer />
    </>
  )
}

export default FindJob