import React from 'react'
import Blogdet1 from '../assets/images/blog/blog-8.4e98e9de268416ab1ad6.jpg';
import Blogd2 from '../assets/images/blog/blog-9.133ce11555b7642c17b2.jpg';
import Blogde3 from '../assets/images/blog/blog-7.14a18adade4e0ebd3c31.jpg';

const Oilplantdes = () => {
    return (
        <>
            <section class="blog-page blog-page-one">
                <div class="container">

                    <div class="row text-right-rtl">
                        <div class="col-xl-8 col-lg-7">
                            <div class="blog-details-content height-auto-blog">

                                <div class="single-blog-style1 single-blog-style1--blog-large blog-details wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms" >
                                    <div class="img-holder">
                                        <div class="inner">
                                            <img srcSet={Blogdet1} alt="Blogdet1" className='imgnews-blog' />
                                        </div>
                                        <div class="categories-date-box">
                                            <div class="categories-box">
                                                <h6><span class="flaticon-open-archive"></span>4 June 2021</h6>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="text-holder">
                                        <div class="top-text">

                                            <div class="inner-text">
                                                <p>  ZIMBABWE’s friendly investment climate continues to attract fresh capital, with the latest addition being the US$20 million Mount Meru edible plant commissioned in Seke recently.
                                                </p> <p>
                                                    The commissioning of the plant with a capacity of processing 250 tonnes of soya edible oil daily is a direct result of the Ministry Industry and Commerce’s tenacity in selling the country’s vision.
                                                </p>

                                            </div>

                                            <div class="inner">
                                                <img srcSet={Blogd2} alt="Blogdet1" />
                                            </div>


                                            <div class="inner mt-5 mb-5">
                                                <p>
                                                    The refinery plant and the packaging complex are feeding directly into the objectives of the National Development Strategy 1 which is prioritising production and productivity, with the plant set to initially have more than 100 permanent employees while creating massive downstream and upstream economic linkages to the betterment of the economy.
                                                </p>
                                                <p>
                                                    The Ministry of Industry and Commerce has pledged to strengthen the country’s industrialisation drive through creating absolute value chains between the agriculture and manufacturing sector in line with vision 2030.
                                                </p>
                                                <p>
                                                    “As the Ministry responsible for the re Industrialisation of the country we are determined to make sure that we harness every resource available to spearhead this mission and the commissioning of this Mount Meru plant is a welcome development to everyone conscious about significance of investment,” said Never Katiyo, Director Legal Services Ministry of Industry and Commerce
                                                </p>
                                                <p>
                                                    Also, Mount Meru Group plans to incorporate and turn to Green energy in all its operations in coming few years.
                                                </p>

                                            </div>
                                            <div class="inner">
                                                <img srcSet={Blogde3} alt="Blogdet1" />
                                            </div>


                                            <div class="inner mt-5">
                                                <p>
                                                    he company is in the process of capacitating local farmers through outgrower schemes to increase raw material availability which will ultimately reduce the country’s import bill on soya bean while enhancing agricultural productivity.
                                                </p>
                                                <p>
                                                    “Besides this commissioned plant we are now in the process of expanding the already existing plant to include a crushing plant which will reduce the cost of the final product drastically,” said Satyavrat Katti, an Investor.
                                                </p>
                                                <p>
                                                    With such investments, economic observers are optimistic that Zimbabwe is well on course to achieve its intended economic targets.
                                                </p>
                                                <p>
                                                    The post US$20 million Mount Meru Edible Oil plant commissioned appeared first on ZBC NEWS (by Davison Vandira).                                                </p>



                                            </div>
                                        </div>


                                    </div>
                                </div>







                            </div>

                        </div>

                        <div class="col-xl-4 col-lg-5">
                            <div class="thm-sidebar-box">




                                <div class="single-sidebar-box">
                                    <div class="sidebar-title">
                                        <div class="pattern-bg">
                                            <img src="assets/images/pattern/thm-pattern-7.png" alt="" />
                                        </div>
                                        <h3><span class="border-left"></span>Recent Posts</h3>
                                    </div>

                                    <div class="sidebar-blog-post">
                                        <ul class="blog-post">
                                            <li>
                                                <div class="inner">
                                                    <div class="img-box">
                                                        <img srcSet={Blogdet1} alt="Awesome Image" />
                                                        <div class="overlay-content">
                                                            <a href="#"><i class="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="title-box">
                                                        <div class="date">20, Jul 2023</div>
                                                        <h4><a href="#">Encouraging Good Health Among Youth.</a></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="inner">
                                                    <div class="img-box">
                                                        <img srcSet={Blogdet1} alt="Awesome Image" />                                                            <div class="overlay-content">
                                                            <a href="#"><i class="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="title-box">
                                                        <div class="date">15, Jun 2023</div>
                                                        <h4><a href="#">MOUNT MERU GROUP TURNING ECO-FRIENDLY!</a></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="inner">
                                                    <div class="img-box">
                                                        <img srcSet={Blogdet1} alt="Awesome Image" />                                                            <div class="overlay-content">
                                                            <a href="#"><i class="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="title-box">
                                                        <div class="date">30, Jun 2023</div>
                                                        <h4><a href="#">Our deepest condolences on the passing away of Dr.John Magufuli</a></h4>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>



                            </div>
                        </div>


                    </div>

                </div>
            </section>
        </>
    )
}

export default Oilplantdes