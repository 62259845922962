import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory from '../assets/images/about-story.jpeg'
import Aboutenergy1 from '../assets/images/shop/truck-6.png'
import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const Logistics = () => {
    return (
        <>
            <Header />

            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Supply chain, Logistics and Hospitality</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Supply chain, Logistics and Hospitality</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">


                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                Mount Meru Logistics's fleet of more than 900 trucks contributes to the seamless supply chain for both our petroleum operations and edible oil manufacturing divisions. These vehicles currently run across more than 14 African countries, ensuring safe and efficient deliveries of our products to all stakeholders. The fleet is responsible for transporting Fuel, Lpg, Edible Oil, and other products. Mount Meru puts much effort into using Technology towards its fleet, ensuring real-time GPS monitoring, Driver safety and automated ERP solutions for efficient supply chain operations. Mount Meru Logistics aspires to grow its operations to a fleet size of 3000 trucks by 2030.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutenergy1} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>

                </section>

            </div>
            <Footer />
        </>
    );
}

export default Logistics;
