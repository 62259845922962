import React from 'react'
import Header from './Header';
import Footer from './Footer';
import EcoFriendlyBanner from './EcoFriendlyBanner';
import TurningEcoFriendlyBlog from './TurningEcoFriendlyBlog'
const OurBlogEcoDes = () => {
    return (
        <>
        <Header />
        <EcoFriendlyBanner />
        <TurningEcoFriendlyBlog />
           <Footer />

   </>
    )
}

export default OurBlogEcoDes