import React from 'react'
import Header from './Header';
import Footer from './Footer';
import DeepestBanner from './DeepestBanner';
import DeepCondolenseDetails from './DeepCondolenseDetails'
const DeepCondolense = () => {
  return (
    <>
      <Header />
      <DeepestBanner />
      <div className="section-parent">
        <DeepCondolenseDetails />
      </div>
      <Footer />

    </>
  )
}

export default DeepCondolense