import React from 'react'
import { Link } from 'react-router-dom';
import BlogMain1 from '../assets/images/blog/blogdet1.jpg';
import BlogMain2 from '../assets/images/blog/blog2.jpeg';
import BlogMain3 from '../assets/images/blog/blog3.jpeg';
import BlogMain4 from '../assets/images/blog/blog-57.jpg';


const BlogSidebar = () => {
    return (
        <>
            <div class="col-xl-4 col-lg-5">
                <div class="thm-sidebar-box">




                    <div class="single-sidebar-box">
                        <div class="sidebar-title">
                            <div class="pattern-bg">
                                <img src="assets/images/pattern/thm-pattern-7.png" alt="" />
                            </div>
                            <h3><span class="border-left"></span>Recent Posts</h3>
                        </div>

                        <div class="sidebar-blog-post">
                            <ul class="blog-post">
                                <li>
                                    <div class="inner">
                                        <div class="img-box">
                                            <img srcSet={BlogMain1} alt="Awesome Image" />
                                            <div class="overlay-content">
                                                <Link to='/EncouragingGoodHealth' ><i class="fa fa-link" aria-hidden="true"></i></Link>
                                            </div>
                                        </div>
                                        <div class="title-box">
                                            <div class="date"> June 2021</div>
                                            <h4><Link target='_blank' to='/EncouragingGoodHealth'>Encouraging Good Health Among Youth.</Link></h4>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="inner">
                                        <div class="img-box">
                                            <img srcSet={BlogMain2} alt="Awesome Image" />
                                            <div class="overlay-content">
                                                <Link to='/EcofriendlyBlogsDetails'><i class="fa fa-link" aria-hidden="true"></i></Link>
                                            </div>
                                        </div>
                                        <div class="title-box">
                                            <div class="date"> May 2021</div>
                                            <h4><Link target='_blank' to='/EcofriendlyBlogsDetails'>MOUNT MERU GROUP TURNING ECO-FRIENDLY!</Link></h4>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="inner">
                                        <div class="img-box">
                                            <img srcSet={BlogMain3} alt="Awesome Image" />                                                            <div class="overlay-content">
                                                <Link to='/DeepCondolense' target='_blank'><i class="fa fa-link" aria-hidden="true"></i></Link>

                                            </div>
                                        </div>
                                        <div class="title-box">
                                            <div class="date"> Mar 2021</div>
                                            <h4><Link target='_blank' to='/DeepCondolense'>Our deepest condolences on the passing away of Dr.John Magufuli</Link></h4>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="inner">
                                        <div class="img-box">
                                            <img srcSet={BlogMain4} alt="Awesome Image" />                                                            <div class="overlay-content">
                                                <Link target='_blank' to='/MountMeruEdibleOil'><i class="fa fa-link" aria-hidden="true"></i></Link>

                                            </div>
                                        </div>
                                        <div class="title-box">
                                            <div class="date"> Sept 2021</div>
                                            <h4><Link to='/MountMeruEdibleOil'>US$20 Million Mount Meru Edible Oil Plant Commissioned</Link></h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}

export default BlogSidebar;