import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory from '../assets/images/about/about-agri.jpg'
import Header from './Header';
import Footer from './Footer';
import FeedImage from '../assets/images/brand/feedthechild.png'
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const AboutAgri = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>FeedTheChild</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">FeedTheChild</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6  col-xl-6">
                                <div className="about-style2__content">

                                    <Fade bottom>
                                        <div className="sec-title fedcontent">

                                            <h2>
                                                FEED THE CHILD
                                            </h2>
                                        </div>
                                    </Fade>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Help us fight the battle against Hunger.
                                                </p>
                                                <p>
                                                    At Mount Meru Group, we are committed to making a positive impact on the lives of children. Through our dedicated 'Feed the Child' initiative, we strive to combat childhood hunger by providing nutritious meals to underprivileged children. Join us in our mission to nourish and empower the next generation.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={FeedImage} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                   
                    </div>
                </section>




            </div>

            <Footer />
        </>
    );
}

export default AboutAgri;
