import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { login } from './redux/LoginRedux';
import { toast } from 'react-toastify';


function AdminLogin(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    // Get the loading and error state from the redux store
    const { loading, error, user } = useSelector((state) => state.adminLogin);

    // Create a dispatch function to call the login action
    const dispatch = useDispatch();

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            toast.error('Please enter your email');
            return;
        }

        if (!password) {
            toast.error('Please enter your password');
            return;
        }

        // Dispatch the login action with the input values
        dispatch(login(email, password));
    };

    // Check if error is not null after login
    if (error !== null) {
        toast.error(error[0]);
    }

    if (user !== null) {
        localStorage.setItem('user', user);
        navigate('/Dashboard');
    }

    return (
        <>
            <main>
                <div className="container">

                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                    <div className="card mb-3">

                                        <div className="card-body">

                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                                <p className="text-center small">Enter your email & password to login</p>
                                            </div>

                                            <form className="row g-3 needs-validation" noValidate onSubmit={handleSubmit}>

                                                <div className="col-12 mb-4">
                                                    <label htmlFor="yourEmail" className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        id="yourEmail"
                                                        required
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12 mb-5">
                                                    <label htmlFor="yourPassword" className="form-label">Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        id="yourPassword"
                                                        required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit">Login</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </div>
            </main>
        </>
    )
}

const mapStateToProps = (state) => ({
    adminLogin: state.adminLogin,
});

const mapDispatchToProps = (dispatch) => ({
    login: (email, password) => dispatch(login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);