import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory145 from '../assets/images/shop/miller.png'
import Aboutstory146 from '../assets/images/shop/new-pic-13.jpg'

import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const MountMerumillier = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Mount Meru Millers</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li>
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Agri</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">
                                            Mount Meru Millers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Mount Meru Millers was among the first few companies established in the group. The first factory was set up in a place called as Mbauda, closer to Arusha Region in Tanzania. Mount Meru Millers is now recognised as one of Africa's largest Edible Oil Manufacturing companies. The company manufactures high-quality Sunflower, Soya, Cotton and other vegetable oils rich in protein and other nutrition benefits.
                                                </p>
                                                <p className='mt-3'>
                                                    The division currently engages with more than 100,000 farmers and
                                                    agri division promoters are creating more jobs and employment opportunities in Africa. One of the visions for Mount Meru Millers is to upscale the lifestyle of the farmers by helping them with long-term association programs such as out-growers schemes and training the farmers with the quality enhancement of their yields through correct farming techniques.
                                                </p>
                                                <p className='mt-3'>
                                                    Under Mount Meru Millers, there are several other operations, such as the production of Rice, manufacturing of DOC (Cattle Feed), production of packaging materials, and production of detergent soaps, which are also performed in the same factories. Mount Meru Millers uses state-of-the-art machinery in their production units, promising the best quality products at an economical price.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory145} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory146} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Why Choose Us</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                    The division is also highly focused on reducing carbon footprints by using proper Effluent Water Treatment plants, advanced waste disposal techniques and various other recycling methods.                                            </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    );
}

export default MountMerumillier;
