import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Fade from 'react-reveal/Fade';
import Aboutstory22 from '../assets/images/shop/acer-petroleum.jpg'
import Imgban from "../assets/images/breadcrumb/breadcrumb-1.jpg";
import truck2 from '../assets/images/shop/truck2.jpg'
import containerised from '../assets/images/shop/containerised.jpg';
import normalfuel from '../assets/images/shop/normal-fuel.jpg';
import mobilestation from '../assets/images/shop/mobile-station.jpg';
import Lub2 from '../assets/images/lub2.png';
import shopImage1 from '../assets/images/shop/lube10.png';
import shopImage2 from '../assets/images/shop/lube11.png';
import shopImage3 from '../assets/images/shop/lube12.png';
import shopImage4 from '../assets/images/shop/lube13.png';
import shopImage5 from '../assets/images/shop/lube14.png';
import shopImage6 from '../assets/images/shop/lube7.png';
import shopImage7 from '../assets/images/shop/lube16.png';
import shopImage8 from '../assets/images/shop/lube18.png';
import shopImage9 from '../assets/images/shop/lube19.png';
import shopImage10 from '../assets/images/shop/lube2.png';
import shopImage11 from '../assets/images/shop/lube6.jpg';
import shopImage12 from '../assets/images/shop/lube21.png';
import shopImage13 from '../assets/images/shop/lube4.png';


import sidebarImage1 from '../assets/images/sidebar/sidebar-product-thumb-1.jpg';
import sidebarImage2 from '../assets/images/sidebar/sidebar-product-thumb-2.jpg';
import sidebarImage3 from '../assets/images/sidebar/sidebar-product-thumb-3.jpg';
import ProductModel from './ProductModel';
import Fuel from '../assets/images/product-img/fuel.png';
import Lpg from '../assets/images/product-img/LPG.png';
<ProductModel />

function EnergyProduct() {
    const [productName, setProductName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [values, setValues] = useState([25, 75]);

    const handleChange = (e) => {
        setValues(e.target.value.split(','));
    };

    const productsData = [
        {
            name: 'Futuristic fuel station',
            imageSrc: normalfuel,
            class: 'images-fix'
        },
        {
            name: 'Containerised fuel station',
            imageSrc: containerised,
            class: 'images-fix'
        },
        {
            name: 'Mobile fuel station',
            imageSrc: mobilestation,
            class: 'images-fix'
        },
        {
            name: 'Automotive Lubricant - 80W90',
            imageSrc: shopImage1,
        },
        {
            name: 'Motor Engine oil - 5W-40',
            imageSrc: shopImage2,
        },
        {
            name: 'Lithium EP-3 Grease',
            imageSrc: shopImage3,
        },
        {
            name: 'Wheel Bearing Grease',
            imageSrc: shopImage6,
        },
        {
            name: 'Brake Fluid',
            imageSrc: shopImage7,
        },
        {
            name: 'Automotive Lubricant - 20W50',
            imageSrc: shopImage8,
        },
        {
            name: 'Automotive Lubricant - 5W-40',
            imageSrc: shopImage9,
        },
        {
            name: 'Multipurpose Grease',
            imageSrc: shopImage11,
        },
        {
            name: 'Automotive Lubricant - 10W-40',
            imageSrc: shopImage12,
        },
    ];

    const [filteredProducts, setFilteredProducts] = useState(productsData);

    const handleSearch = (e) => {
        e.preventDefault();
        const query = document.getElementById('searchInput').value?.toLowerCase() || '';

        if (query.trim() === '') {
            return;
        }

        const filtered = productsData.filter((product) =>
            product.name.toLowerCase().includes(query)
        );
        setFilteredProducts(filtered);

        if (filtered.length === 0) {
            Swal.fire('No products found', 'Please try a different search query', 'warning');
        } else {
            const productSection = document.getElementById('productSection');
            if (productSection) {
                productSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">

                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Energy Products</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="">Our Products</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Energy Products</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="shop-page-one">
                    <div className="shop-page-one_inner">

                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-12 col-sm-12 col-xl-3 col-lg-4 ">
                                    <div className="thm-sidebar-box">
                                        <div className="single-sidebar-box">
                                            <div className="sidebar-search-box style2">
                                                <form className="search-form" action="#" onSubmit={handleSearch}>
                                                    <input
                                                        id="searchInput"
                                                        placeholder="Search"
                                                        type="text"
                                                        onChange={handleSearch}
                                                    />
                                                    <button type="submit">
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shop-page-one">
                    <div className="shop-page-one_inner">

                        <div className="container">


                            <div className="row d-lg-none">
                                <div class="sec-title text-center w-100">
                                    <div class="sec-titlenew sub-title">
                                        <Fade bottom>
                                            <h2><span class="border-left"></span>
                                                <span class="one-text">Energy Products</span>

                                                <span class="border-right"></span>
                                            </h2>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-12">
                                    <div className="about-style2__content">


                                        <div class="sec-title text-center">
                                            <div class="sec-titlenew sub-titleabout sub-title">

                                                <Fade bottom>
                                                    <h2><span class="border-left"></span>
                                                        <span class="one-text">Fuel Stations  </span>

                                                        <span class="two-text"></span>
                                                        <span class="border-right"></span>
                                                    </h2>
                                                </Fade>
                                            </div>
                                        </div>

                                        <div className="inner-content">
                                            <Fade bottom>
                                                <div className="text">
                                                    <p className='mb-3'>
                                                        Our fuel stations offer premium petrol and diesel fuels that meet industry standards. With our commitment to quality, you can trust that every drop of fuel is refined to deliver optimal performance and efficiency for your vehicle.
                                                    </p>
                                                    <p className='mb-3'>
                                                        Meru Fuel stations are strategically located, making it convenient to fuel up wherever your journey takes you. We provide a reliable and hassle-free experience, ensuring quick and efficient service for your refuelling needs.
                                                    </p>
                                                    <p className='mb-3'>
                                                        Safety is our top priority; hence, we adhere to strict safety standards, ensuring proper handling and storage of fuels. We prioritise the well-being of our customers and maintain a safe environment for refuelling.
                                                    </p>
                                                    <p className='mb-3'>
                                                        Our friendly and knowledgeable staff are dedicated to providing a positive customer experience. We are here to assist you with any queries, offer fuelling tips, and ensure your refuelling process is smooth and efficient.
                                                    </p>
                                                    <p className='mb-3'>
                                                        Take advantage of our loyalty programs, special promotions, and discounts, allowing you to save on fuel costs and enjoy additional benefits.
                                                    </p>

                                                </div>
                                            </Fade>



                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-12">
                                    <Fade bottom>
                                        <div className="about-style2__image-box">

                                            <div className="img-box2">

                                                <div className="inner">
                                                    <img srcSet={Fuel} alt="Aboutstory" />
                                                </div>

                                            </div>



                                            <div className="icon-box">
                                                <span className="flaticon-house-with-wooden-roof"></span>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shop-page-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-12">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Lub2} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6 col-lg-12">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">

                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text"> Lubricants  </span>

                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p className='mb-3'>
                                                    We prioritise your engine's longevity and durability. Our lubricants create a protective barrier, preventing metal-to-metal contact, reducing wear and tear, and extending the life of critical engine components.
                                                </p>
                                                <p className='mb-3'>
                                                    Our automotive lubricants are designed to perform in various weather conditions.
                                                </p>
                                                <p className='mb-3'>
                                                    Whether it's extreme heat, cold, or fluctuating temperatures, our lubricants provide reliable performance and maintain their viscosity to ensure consistent protection.                                                </p>
                                                <p className='mb-3'>
                                                    Our products are formulated with the highest-quality base oils and advanced additives, ensuring optimal performance and protection for your vehicle's engine.                                                </p>
                                                <p className='mb-3'>
                                                    With our commitment to quality, you can trust that our lubricants will meet and exceed industry standards.
                                                </p>

                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shop-page-one">
                    <div className='container'>
                        <div className="row">
                            <div className="col-xl-6 col-lg-12">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">

                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text"> LPG  </span>

                                                    <span class="two-text">	</span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p className='mb-3'>
                                                    "Experience the Power and Efficiency of LPG - Your Clean and Convenient Energy Solution"
                                                </p>
                                                <p className='mb-3'>
                                                    LPG is a cost-effective, low-emission fuel that produces significantly fewer greenhouse gases than other fossil fuels.
                                                </p>
                                                <p className='mb-3'>
                                                    By choosing LPG, you contribute to a cleaner and greener environment.
                                                </p>
                                                <p className='mb-3'>
                                                    LPG offers excellent energy efficiency, providing more heat output per unit than traditional fuels / electric burners. This efficiency translates into cost savings for our customers, helping them reduce their energy bills.
                                                </p>


                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img src={Lpg} alt="" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="shop-page-one" id="productSection">
                    <div className="container  product-container">
                        <div className="station-card-list">
                            {filteredProducts.map((product, index) => (
                                <div className={product.class} key={index}>
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={product.imageSrc} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <h3>
                                                    <NavLink to="#">{product.name}</NavLink>
                                                </h3>
                                                <Button
                                                    className="btn-modals"
                                                    variant="primary"
                                                    onClick={() => {
                                                        setModalShow(true);
                                                        setProductName(product.name);
                                                    }}
                                                >
                                                    Contact Us
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
            <ProductModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                productName={productName}
            />


        </>
    );
};

export default EnergyProduct;
