import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Fade from 'react-reveal/Fade';
import Lub2 from '../assets/images/lub2.png';
import AgriproductImages1 from '../assets/images/gallery/agri-1.png'
import AgriproductImages2 from '../assets/images/gallery/agri-2.png'

import AgriproductImages7 from '../assets/images/gallery/agri-7.png'

import AgriproductImages11 from '../assets/images/gallery/agri-11.png'


import refinedsunflower from '../assets/images/shop/refined-sunflower.png'
import refinedsoyabean from '../assets/images/shop/refined-soyabean.png'
import laundrybar from '../assets/images/shop/laundrybar.png'
import soyadoc from '../assets/images/shop/soyadoc.jpeg'
import cottondoc from '../assets/images/shop/cottondoc.jpeg'
import cookingfat from '../assets/images/shop/cookingfat.png'
import sunflowerdoc from '../assets/images/shop/sunflower-doc.jpeg'
import rice from '../assets/images/shop/rice.png'
import ProductModel from './ProductModel';
import Edibleoil from '../assets/images/gallery/agri-main.jpeg';

<ProductModel />
function AgriProduct() {
    const [productName, setProductName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [values, setValues] = useState([25, 75]);

    const handleChange = (e) => {
        setValues(e.target.value.split(','));
    };

    const [productsData, setProductsData] = useState([
        {
            id: 1,
            name: 'Refined Sunflower Seed oil',
            image: AgriproductImages1,
        },
        {
            id: 2,
            name: 'Refined Sunflower Oil',
            image: AgriproductImages2,
        },
        {
            id: 3,
            name: 'Refined Cooking oil',
            image: AgriproductImages7,
        },
        {
            id: 4,
            name: 'Refined Soybean Oil',
            image: AgriproductImages11,
        },
        {
            id: 5,
            name: 'Refined Sunflower oil - 1 liter',
            image: refinedsunflower,
        },
        {
            id: 6,
            name: 'Refined SoyaBean oil - 1 liter',
            image: refinedsoyabean,
        },
        {
            id: 7,
            name: 'White Laundry Bar Soap',
            image: laundrybar,
        },
        {
            id: 8,
            name: 'Cooking Fat',
            image: cookingfat,
        },
        {
            id: 9,
            name: 'Rice',
            image: rice,
        },
        {
            id: 10,
            name: 'Soya DOC',
            image: soyadoc,
            class:'images-fix'
        },
        {
            id: 11,
            name: 'Cotton DOC',
            image: cottondoc,
            class:'images-fix'
        },
        {
            id: 12,
            name: 'Sunflower DOC',
            image: sunflowerdoc,
            class:'images-fix'
        },
    ]);

    const [filteredProducts, setFilteredProducts] = useState(productsData);

    const handleSearch = (e) => {
        e.preventDefault();
        const query = document.getElementById('searchInput').value?.toLowerCase() || '';

        if (query.trim() === '') {
            return;
        }

        const filtered = productsData.filter((product) =>
            product.name.toLowerCase().includes(query)
        );
        setFilteredProducts(filtered);

        if (filtered.length === 0) {
            Swal.fire('No products found', 'Please try a different search query', 'warning');
        } else {
            const productSection = document.getElementById('productSection');
            if (productSection) {
                productSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg" >
                    {/* <img srcSet={Imgban} alt='Imgban' /> */}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Agri Products</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="">Home</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><a href="">Our Products</a></li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Agri Products</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="shop-page-one">
                    <div className="shop-page-one_inner">

                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-xl-3 col-lg-4 ">
                                    <div className="thm-sidebar-box">
                                        <div className="single-sidebar-box">
                                            <div className="sidebar-search-box style2">
                                                <form className="search-form" action="#" onSubmit={handleSearch}>
                                                    <input
                                                        id="searchInput"
                                                        placeholder="Search"
                                                        type="text"
                                                        onChange={handleSearch}
                                                    />
                                                    <button type="submit">
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shop-page-one">
                    <div className="shop-page-one_inner">

                        <div className="container">

                            <div className="row ">

                                <div className="col-xl-6 col-lg-12">
                                    <Fade bottom>
                                        <div className="about-style2__image-box">

                                            <div className="img-box2">

                                                <div className="inner">
                                                    <img src={Edibleoil} alt="" />
                                                </div>

                                            </div>



                                            <div className="icon-box">
                                                <span className="flaticon-house-with-wooden-roof"></span>
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-xl-6 col-lg-12">
                                    <div className="about-style2__content">


                                        <div class="sec-title text-center">
                                            <div class="sec-titlenew sub-titleabout sub-title">

                                                <Fade bottom>
                                                    <h2><span class="border-left"></span>
                                                        <span class="one-text">Edible Oil </span>

                                                        <span class="two-text"></span>
                                                        <span class="border-right"></span>
                                                    </h2>
                                                </Fade>
                                            </div>
                                        </div>

                                        <div className="inner-content">
                                            <Fade bottom>
                                                <div className="text">
                                                    <p className='mb-3'>
                                                        We prioritize your well-being. Our refined edible oil undergoes advanced processing techniques that remove impurities and retain essential nutrients, making it a healthier option for you and your family.
                                                    </p>
                                                    <p className='mb-3'>
                                                        Our refined edible oil is crafted with utmost care and precision, ensuring the highest standards of quality. Each drop is refined to perfection and superior taste in every meal you prepare.

                                                    </p>


                                                </div>
                                            </Fade>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="shop-page-one" id="productSection">
                    <div className="container  product-container">
                        <div className="station-card-list">
                            {filteredProducts.map((product, index) => (
                                <div className={product.class} key={index}>
                                    <div className="single-shop-item">
                                        <div className="single-shop-item_inner">
                                            <div className="img-holder">
                                                <img src={product.image} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <h3>
                                                    <NavLink to="#">{product.name}</NavLink>
                                                </h3>
                                                <Button
                                                    className="btn-modals"
                                                    variant="primary"
                                                    onClick={() => {
                                                        setModalShow(true);
                                                        setProductName(product.name);
                                                    }}
                                                >
                                                    Contact Us
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
            <ProductModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                productName={productName}
            />
        </>
    );
};

export default AgriProduct;