import React from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';
import service1 from '../assets/images/energy-services.png'
import service3 from '../assets/images/agri-services.jpg'
import service2 from '../assets/images/mount-meru-services.jpg';
import Aboutenergy1 from '../assets/images/shop/truck-6.png'

import Slider from 'react-slick';
function PrevIcons(khushboo) {
    const { onClick } = khushboo;
    return (
        <>
            <div className="prev-icons-main" onClick={onClick}>
                <i class="fa fa-arrow-left " aria-hidden="true"></i>
            </div>
        </>
    )

}


function NextIcons(hamid) {
    const { onClick } = hamid;
    return (
        <>
            <div className='next-icon-main' onClick={onClick}>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
        </>
    )
}
const Newsortpg = {
    backgroundImage: 'url(&quot;images/background/cross-line2.png&quot;)'
}
const HomeService = () => {
    const slidingproduct = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        autoplay: true,

        prevArrow: <PrevIcons />,


        nextArrow: <NextIcons />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    autoplay: true
                }
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 3,
                    autoplay: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true
                }
            }
         
       
        ]
    };


    return (
        <>
            <section className="service-style1-area">
                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-2.png)" }}></div>
                <div className="container">
                    <div className="sec-title center text-center">

                        <Fade bottom>

                            <h2>Our Services</h2>
                        </Fade>
                    </div>

                    <div className="">
                        <Slider {...slidingproduct}>
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="single-service-style1">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={service1} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-1.png)" }}></div>
                                                <div className="inner-text">

                                                    <h3><NavLink to="/AboutEnergy">Energy</NavLink></h3>
                                                    <div className="text">
                                                        <p>In the dynamic sector of energy, Mount Meru Group.....</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="single-service-style1">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={service2} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-1.png)" }}></div>
                                                <div className="inner-text">

                                                    <h3><NavLink to="/EnergyProduct">Mount Meru Retail (NFR)</NavLink></h3>
                                                    <div className="text">
                                                        <p>Mount Meru Millers Ltd considers itself very fortunate...</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="single-service-style1">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={service3} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-1.png)" }}></div>
                                                <div className="inner-text">

                                                    <h3><NavLink href="/AboutAgri">Agri</NavLink></h3>
                                                    <div className="text">
                                                        <p>Mount Meru Millers Ltd considers itself very fortunate to be a part......</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                            {/* Start Single Service Style1 */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <Fade bottom>
                                    <div className="single-service-style1">
                                        <div className="inner-box">
                                            <div className="img-holder">
                                                <img src={Aboutenergy1} alt="" />
                                            </div>
                                            <div className="title-holder">
                                                <div className="pattern-bg" style={{ backgroundImage: "url(assets/images/pattern/thm-pattern-1.png)" }}></div>
                                                <div className="inner-text">

                                                    <h3><NavLink to="/Logistics">Logistics </NavLink></h3>
                                                    <div className="text">
                                                        <p>Mount Meru Logistics , having a fleet of more than 900 trucks  ......</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            {/* End Single Service Style1 */}
                        </Slider>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="service-style1__bottom-text">


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeService;
