import React from 'react';
import Fade from 'react-reveal/Fade';
import Aboutstory22 from '../assets/images/shop/acer-petroleum.jpg'
import Aboutstory23 from '../assets/images/shop/new-pic-9.jpg'

import Header from './Header';
import Footer from './Footer';
import OverviewBanner from './OverviewBanner';
import OverviewAbout from './OverviewAbout';
import OverviewChooseUs from './OverviewChooseUs';
import Careermodal from "./Careermodal";


const AcerPetroleumLimited = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Acer Petroleum Limited</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>
                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Business</a></li>
                                        <li>
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </li>
                                        <li><a href="#">Energy</a></li>

                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">
                                            Acer Petroleum Limited</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-parent">
                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">

                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">About Us </span>
                                                    <span class="two-text"></span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    Acer Petroleum Ltd currently operates in both mid-stream and downstream energy business operations. With strategic storage facilities for fuel storage depots available in Africa, Acer Petroleum also plays a pivotal role in the transportation of petroleum products across more than 10 African Countries.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory22} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>


                        </div>
                    </div>
                </section>
                <section className="about-style2-area">
                    <div className="container ">
                        <div className="row  order-flexing">
                            <div className="col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory23} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style2__content">


                                    <div class="sec-title text-center">
                                        <div class="sec-titlenew sub-titleabout sub-title">
                                            <Fade bottom>
                                                <h2><span class="border-left"></span>
                                                    <span class="one-text">Why Choose Us</span>
                                                    <span class="two-text"> </span>
                                                    <span class="border-right"></span>
                                                </h2>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>

                                                    Acer Petroleum has state of the art infrastructure making it a very important function in the group.                                            </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>

                {/* <section class="choose-style2-area about-page ">
                    <div class="container">

                        <div class="sec-title text-center">
                            <div class="sec-titlenew sub-title">
                                <Fade bottom>
                                    <h2><span class="border-left"></span>
                                        <span class="one-text">Presence In </span>
                                        <span class="two-text">  </span>
                                        <span class="border-right"></span>
                                    </h2>
                                </Fade>
                            </div>
                        </div>

                        <div class="country-grid">
                            <div class="country-presence row d-flex justify-content-center">
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="country-images">
                                        <p>BOTSWANA</p>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="country-images">
                                        <p>KENYA</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}

            </div>
            <Footer />
        </>
    );
}

export default AcerPetroleumLimited;
