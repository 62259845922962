import React from 'react'
import Fade from 'react-reveal/Fade';
import nfr from '../assets/images/csr-img/nfr.jpg'
import Aboutstory8 from '../assets/images/about-img/about8.jpg'


import Header from './Header';
import Footer from './Footer';
const Mining = () => {
    return (
        <>
            <Header />
            <section class="breadcrumb-area style2">
                <div class="breadcrumb-area-bg"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="inner-content">
                                <div class="title breadcumbheight blog-single aos-init aos-animate" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Mining</h2>
                                </div>
                                <div class="breadcrumb-menu aos-init" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                                    <ul>
                                        <li><a href="#">Home</a>
                                        </li>


                                        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li class="active">Mining</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-parent">

                <section className="about-style2-area">
                    <div className="container">
                        <div className="row ">


                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Fade bottom>
                                    <div className="about-style2__image-box">

                                        <div className="img-box2">

                                            <div className="inner">
                                                <img srcSet={Aboutstory8} alt="Aboutstory" />
                                            </div>

                                        </div>



                                        <div className="icon-box">
                                            <span className="flaticon-house-with-wooden-roof"></span>
                                        </div>

                                    </div>
                                </Fade>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6">
                                <div className="about-style2__content">

                                    <Fade bottom>
                                        <div className="sec-title child-contents">

                                            <h2>
                                                Mining
                                            </h2>
                                        </div>
                                    </Fade>


                                    <div className="inner-content">
                                        <Fade bottom>
                                            <div className="text">
                                                <p>
                                                    We supply fuel, lubricants, and other services to the mining industry, providing us with immense potential to cater to their fuel and petroleum product requirements. This also enables us to continuously enhance our quality standards and parameters. Our dedicated teams within the Mining department ensure round-the-clock availability, contributing significantly to our service volumes.
                                                </p>
                                            </div>
                                        </Fade>



                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </section>
            </div >

            <Footer />
        </>
    )
}

export default Mining;